import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type ScreenSlice = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  updateScreen: (screen: boolean[]) => void;
};

export const useScreenStore = create<ScreenSlice>()(
  devtools(
    set => ({
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      updateScreen: screen =>
        set(() => {
          if (screen[0])
            return { isMobile: true, isTablet: false, isDesktop: false };

          if (screen[1])
            return { isMobile: false, isTablet: true, isDesktop: false };

          return { isMobile: false, isTablet: false, isDesktop: true };
        }),
    }),
    { name: 'ScreenStore' },
  ),
);
