import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';

export function SettlementAddressField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();

  const addressFieldId = `${useId()}-address-field`;
  const detailAddressFieldId = `${useId()}-address-detail-field`;

  const {
    field: {
      name: addressName,
      value: addressValue,
      onChange: onChangeAddress,
      ref: addressRef,
    },
    fieldState: { invalid: addressInvalid, error: addressError },
  } = useController({
    control,
    name: 'address',
    rules: { required: true },
  });

  const {
    field: {
      name: detailAddressName,
      value: detailAddressValue,
      onChange: onChangeDetailAddress,
      ref: detailAddressRef,
    },
    fieldState: { invalid: detailAddressInvalid, error: detailAddressError },
  } = useController({
    control,
    name: 'detailAddress',
    rules: { required: true },
  });

  const openDaumPostcode = () => {
    new window.daum.Postcode({
      oncomplete: data => onChangeAddress(data.address),
    }).open();
  };

  return (
    <Flex className="flex-col gap-y-1">
      <FormControl isInvalid={addressInvalid}>
        <FormLabel>{t('input-modal.address-field.label')}</FormLabel>
        <Flex className="flex-row gap-2.5">
          <Input
            type="text"
            id={addressFieldId}
            rounded={'lg'}
            bgColor="grey.100"
            className="flex-1 leading-38px invalid:bg-red-50 placeholder:color-grey-900"
            placeholder={t('input-modal.address-field.placeholder')}
            _invalid={{
              bg: 'red.50',
              border: '1px solid',
              borderColor: 'red.500',
            }}
            name={addressName}
            value={addressValue ?? ''}
            onClick={openDaumPostcode}
            ref={addressRef}
            readOnly
          />
          <TDS.Button
            size="md"
            variant="outline"
            colorScheme="grey"
            onClick={openDaumPostcode}
          >
            {t('input-modal.address-field.address-search')}
          </TDS.Button>
        </Flex>
      </FormControl>

      <FormControl isInvalid={detailAddressInvalid}>
        <Input
          type="text"
          id={detailAddressFieldId}
          bg="white"
          rounded={'lg'}
          className="flex-1 leading-38px invalid:bg-red-50"
          placeholder={t('input-modal.detail-address-field.placeholder')}
          _invalid={{
            bg: 'red.50',
            border: '1px solid',
            borderColor: 'red.500',
          }}
          name={detailAddressName}
          value={detailAddressValue ?? ''}
          onChange={onChangeDetailAddress}
          ref={detailAddressRef}
        />
        <FormErrorMessage>
          <TDS.Typo color="error.500" size="xs" weight="medium">
            {addressError?.message || detailAddressError?.message}
          </TDS.Typo>
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
}
