import { dayjs } from '@/utils/time';

export const settleMonth = () => {
  const today = dayjs();
  const day = today.date();
  const month = today.month() + 1;
  const nextMonth = today.add(1, 'month').month() + 1;

  return day <= 14 ? month : nextMonth;
};

export const getMonthName = (month: number, language: string) => {
  const monthsKo = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];
  const monthsEn = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (language === 'ko' ? monthsKo : monthsEn)[month - 1];
};
