export const useLoading = (defaultLoading: boolean = false) => {
  const totalLoading = useRef<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(defaultLoading);

  const start = useCallback(() => {
    totalLoading.current = totalLoading.current + 1;
    if (totalLoading.current > 0) {
      setIsLoading(true);
    }
  }, [setIsLoading]);

  const end = useCallback(() => {
    totalLoading.current = totalLoading.current - 1;
    if (totalLoading.current <= 0) {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  return {
    isLoading,
    start,
    end,
  };
};
