import { Route, createRoutesFromElements, Outlet } from 'react-router-dom';

import CharacterFormPage from '@/pages/character-form-page/character-form-page';
import CharacterPage from '@/pages/character-page/character-page';
import NotFoundPage from '@/pages/not-found-page';

import { PageLayout, SimpleLayout } from './components/layouts';
import { NavigateHomeByRole } from './components/navigate-home-by-role';
import { AuthenticationGuard } from './contexts/providers/authentication-guard';
import { UnauthenticationGuard } from './contexts/providers/unauthentication-guard';
import SettlementPage from './pages/settle-page/settlement-page';
import SignInPage from './pages/sign-in-page/sign-in-page';

export const routes = createRoutesFromElements(
  <Route>
    <Route
      element={
        <PageLayout>
          <AuthenticationGuard />
        </PageLayout>
      }
    >
      <Route path="" element={<NavigateHomeByRole replace />} />
      <Route path="partners" index element={<CharacterPage />} />
      <Route path="partners/form" element={<CharacterFormPage />} />
      <Route path="partners/form/:formId" element={<CharacterFormPage />} />
      <Route path="partners/settle" element={<SettlementPage />} />
    </Route>

    <Route
      element={
        <SimpleLayout>
          <Outlet />
        </SimpleLayout>
      }
    >
      <Route
        path="signin"
        element={
          <UnauthenticationGuard>
            <SignInPage />
          </UnauthenticationGuard>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </Route>,
);
