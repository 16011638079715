import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  TDS,
} from '@/components/ui';
import { z } from '@/utils/validator';

import { ACCEPTED_AUDIO_TYPES, formSchema, voiceSchema } from '../schema';

type OriginalVoiceFieldProps = {
  preview?: string;
  disabled?: boolean;
};

export function OriginalVoiceField({
  disabled,
  preview = '',
  ...props
}: OriginalVoiceFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setError, clearErrors, setValue } =
    useFormContext<z.infer<typeof formSchema>>();
  const voiceFieldId = `${useId()}-voice-field`;
  const voiceRef = useRef<HTMLInputElement | null>(null);
  const {
    field: { value, ref: fieldRef },
    fieldState: { error, invalid },
  } = useController({
    control,
    name: 'voice',
    rules: { required: true },
    disabled,
  });

  const handleChangeFile = async (files: File[]) => {
    setValue('voice', files as z.infer<typeof voiceSchema>, {
      shouldDirty: true,
    });

    const result = await voiceSchema.safeParseAsync(files);

    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('voice', {
        message: error[0].message,
      });
    } else {
      clearErrors('voice');
    }
  };
  return (
    <FormControl isInvalid={invalid} {...props}>
      <FormLabel htmlFor={voiceFieldId}>
        {t('components.original-voice-field.label')}
      </FormLabel>
      <div className="flex flex-row gap-2.5">
        <Input
          type="text"
          placeholder={t('components.original-voice-field.placeholder')}
          value={value[0]?.name ?? preview}
          rounded={'lg'}
          bg={'grey.100'}
          className="flex-1 file:hidden leading-38px select-none cursor-default"
          tabIndex={-1}
          _invalid={{
            bg: 'red.50',
            border: '1px solid',
            borderColor: 'red.500',
          }}
          _focus={{
            border: '1px solid',
            borderColor: 'grey.200',
          }}
          _readOnly={{
            opacity: 40,
          }}
          isInvalid={invalid}
          alt={value[0]?.name ?? ''}
          ref={fieldRef}
          isReadOnly
          isDisabled={disabled}
        />
        <Input
          type="file"
          className="hidden"
          id={voiceFieldId}
          isDisabled={disabled}
          accept={ACCEPTED_AUDIO_TYPES.join(', ')}
          ref={voiceRef}
          onChange={e => {
            const files = e.target.files ?? [];
            if (files.length > 0) {
              handleChangeFile([...files]);
            }
          }}
        />
        <TDS.Button
          type="button"
          variant="outline"
          colorScheme="primary"
          size="md"
          isDisabled={disabled}
          onClick={() => {
            voiceRef.current?.click();
          }}
        >
          {t('components.original-voice-field.button')}
        </TDS.Button>
      </div>
      <FormErrorMessage mt={1}>
        <TDS.Typo color="error.500" size="xs" weight="medium">
          {error?.message}
        </TDS.Typo>
      </FormErrorMessage>
      <FormHelperText>
        <div>
          <TDS.Typo size="xs" weight="bold" color="grey.700">
            {t('components.original-voice-field.helper.recommend')}
          </TDS.Typo>
          <ul className="ms-1.5 text-grey-700">
            <li className="flex flex-row items-center gap-1.5">
              <span className="text-3.5 leading-100% select-none" aria-hidden>
                •
              </span>
              <TDS.Typo size="xs">
                {t('components.original-voice-field.helper.format')}
              </TDS.Typo>
            </li>
            <li className="flex flex-row items-center gap-1.5">
              <span className="text-3.5 leading-100% select-none" aria-hidden>
                •
              </span>
              <TDS.Typo size="xs">
                {t('components.original-voice-field.helper.length')}
              </TDS.Typo>
            </li>
            <li className="flex flex-row items-center gap-1.5">
              <span className="text-3.5 leading-100% select-none" aria-hidden>
                •
              </span>
              <TDS.Typo size="xs">
                {t('components.original-voice-field.helper.quality')}
              </TDS.Typo>
            </li>
          </ul>
        </div>
      </FormHelperText>
    </FormControl>
  );
}
