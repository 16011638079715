import { ComponentProps } from 'react';

import { TDS } from '@/components/ui';
import { cn } from '@/utils/cn';

export type ScreenLoadingProps = ComponentProps<'div'> & {
  show: boolean;
};
export function ScreenLoading({
  show = false,
  className,
  ...props
}: ScreenLoadingProps) {
  return (
    <div
      className={cn(
        show ? 'z-10000' : 'hidden -z-1',
        'fixed left-0 top-0',
        'w-100vw h-100vh bg-black/60 cursor-progress',
        'flex flex-row items-center justify-center',
        className,
      )}
      {...props}
    >
      <TDS.Spinner size="2xl" color="whiteAlpha" />
    </div>
  );
}
