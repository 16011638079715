import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
  requestGetCharacterList,
  requestGetOriginalCharacterList,
} from '@/api/voice-hub';
import { SubTitle } from '@/components/sub-title';
import { Title } from '@/components/title';
import { Divider, Skeleton, TDS, useToast } from '@/components/ui';
import { useRole } from '@/hooks/use-role';
import type { VoiceHubCharacter, OriginalCharacter } from '@/types/character';
import { isSuccess } from '@/utils/guard';

import NotFoundPage from '../not-found-page';
import {
  CharacterCardGroup,
  CharacterCardSkeleton,
} from './components/character-card';
import { OriginalCharacterCard } from './components/original-character-card';
import { PlusCard } from './components/plus-card';
import { VoiceHubCharacterCard } from './components/voice-hub-character-card';

const fetchCharacterData = ({
  isVoiceHubProvider = false,
  isOldProvider = false,
}: {
  isVoiceHubProvider?: boolean;
  isOldProvider?: boolean;
}) => {
  const voiceHubCharacterData = isVoiceHubProvider
    ? requestGetCharacterList().then(res => (isSuccess(res) ? res.data : []))
    : Promise.resolve([]);
  const originalCharacterData = isOldProvider
    ? requestGetOriginalCharacterList().then(res =>
        isSuccess(res) ? res.data : [],
      )
    : Promise.resolve([]);

  return {
    voiceHubCharacterData,
    originalCharacterData,
  };
};

export default function CharacterPage() {
  const { t } = useTranslation('characterPage');
  const toast = useToast();
  const { isVoiceHubProvider, isOldProvider, isNormalProvider } = useRole();
  const loader = fetchCharacterData({ isVoiceHubProvider, isOldProvider });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (Object.is(searchParams.get('release-request-complete'), 'true')) {
      setSearchParams({});
      toast({
        position: 'top',
        render: () => (
          <div className="flex flex-row gap-3 justify-start rounded-xl bg-grey-900 px-4 py-3 text-white w-400px">
            <i className="i-t-alert text-6" />
            <div className="">
              <TDS.Typo as="h5" color="white" size="md" weight="bold">
                {t('toast.release-complete.header')}
              </TDS.Typo>
              <TDS.Typo as="p">{t('toast.release-complete.body')}</TDS.Typo>
            </div>
          </div>
        ),
      });
    }
  }, [searchParams]);

  if (isNormalProvider) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Title>{t('title')}</Title>
      <Suspense fallback={<CharactersFallback />}>
        <Await
          resolve={Promise.all([
            loader.voiceHubCharacterData,
            loader.originalCharacterData,
          ])}
        >
          {([voiceHubCharacters, oriCharacters]: [
            VoiceHubCharacter[],
            OriginalCharacter[],
          ]) => (
            <>
              {isVoiceHubProvider && (
                <>
                  <VoicehubCharacterSection characters={voiceHubCharacters} />
                  <Divider className="my-6"></Divider>
                </>
              )}
              {isOldProvider && (
                <OriginalCharacterSection characters={oriCharacters} />
              )}
            </>
          )}
        </Await>
      </Suspense>
      <footer className="h-250px"></footer> {/* 공간 늘이기 용 */}
    </>
  );
}

function VoicehubCharacterSection({
  characters,
}: {
  characters: VoiceHubCharacter[];
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('characterPage');

  const handleClickCard = (character: VoiceHubCharacter) => {
    navigate(`/partners/form/${character.id}`);
  };

  return (
    <section>
      <SubTitle>{t('section.voicehub')}</SubTitle>
      <CharacterCardGroup>
        {characters.map(character => (
          <VoiceHubCharacterCard
            character={character}
            key={character.id}
            tabIndex={0}
            className="cursor-pointer rounded-10px"
            onClick={() => handleClickCard(character)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleClickCard(character);
              }
            }}
          />
        ))}
        <Link to="/partners/form" className="inline-block rounded-10px">
          <PlusCard />
        </Link>
      </CharacterCardGroup>
    </section>
  );
}

function OriginalCharacterSection({
  characters,
}: {
  characters: OriginalCharacter[];
}) {
  const { t } = useTranslation('characterPage');

  return (
    <section>
      <SubTitle>{t('section.original')}</SubTitle>
      <CharacterCardGroup>
        {characters.map(character => (
          <OriginalCharacterCard key={character.id} character={character} />
        ))}
      </CharacterCardGroup>
    </section>
  );
}

function CharactersFallback() {
  const { t } = useTranslation('characterPage');
  const { isVoiceHubProvider, isOldProvider } = useRole();

  return (
    <>
      {isVoiceHubProvider && (
        <>
          <section>
            <SubTitle>{t('section.voicehub')}</SubTitle>
            <CharacterCardGroup>
              <CharacterCardSkeleton />
              <Link to="/partners/form" className="inline-block rounded-10px">
                <PlusCard />
              </Link>
            </CharacterCardGroup>
          </section>
          <Divider className="my-6"></Divider>
        </>
      )}
      {isOldProvider && (
        <section>
          <SubTitle>{t('section.original')}</SubTitle>
          <div className="flex flex-row flex-wrap gap-x-15px gap-y-4">
            <Skeleton h={208} w={310} rounded={10} />
          </div>
        </section>
      )}
    </>
  );
}
