import {
  forwardRef,
  Button as _Button,
  type ButtonProps as _ButtonProps,
} from '@chakra-ui/react';
import { cva } from 'class-variance-authority';

import { cloneElement } from 'react';

import { cn } from '../../utils';
import { Spinner } from '../Spinner';

type StyleObject = {
  [x: string]: Style;
};
type Style = string | number | StyleObject;
export type Variant = 'solid' | 'outline' | 'ghost' | 'link';
export type ColorScheme = 'primary' | 'grey' | 'whiteAlpha' | 'error' | 'info';
export type Size = 'lg' | 'md' | 'sm' | 'xs';

export type ButtonProps = {
  variant?: Variant;
  colorScheme?: ColorScheme;
  size?: Size;
} & Omit<_ButtonProps, 'variant' | 'colorScheme' | 'size'>;

export const Button = forwardRef<ButtonProps, 'button'>(
  (
    {
      variant = 'solid',
      size = 'lg',
      colorScheme = 'primary',
      children,
      leftIcon,
      rightIcon,
      ...props
    },
    ref,
  ) => {
    const leftIconWithSize = leftIcon
      ? cloneElement(leftIcon, {
          className: cn(leftIcon.props.className, iconVariants({ size })),
        })
      : leftIcon;
    const rightIconWithSize = rightIcon
      ? cloneElement(rightIcon, {
          className: cn(rightIcon.props.className, iconVariants({ size })),
        })
      : rightIcon;

    const isSolid = variant === 'solid';

    return (
      <_Button
        variant={variant}
        fontWeight="semibold"
        colorScheme={colorScheme}
        boxSizing="border-box"
        lineHeight={1.5}
        leftIcon={leftIconWithSize}
        rightIcon={rightIconWithSize}
        fontSize={6}
        spinner={
          <Spinner size={size} color={isSolid ? 'whiteAlpha' : colorScheme} />
        }
        ref={ref}
        {...buttonStyle[variant][colorScheme]}
        {...sizeStyle[size]}
        {...props}
      >
        {children}
      </_Button>
    );
  },
);

Button.displayName = 'Button';

const sizeStyle: Record<Required<ButtonProps>['size'], StyleObject> = {
  lg: {
    h: 12,
    px: 6,
    fontSize: 'lg',
    rounded: 10,
    iconSpacing: 1.5,
  },
  md: {
    h: 10,
    px: 4,
    fontSize: 'md',
    rounded: 10,
    iconSpacing: 1.5,
  },
  sm: {
    h: 8,
    px: 3,
    fontSize: 'xs',
    rounded: 8,
    iconSpacing: 1,
  },
  xs: {
    h: 6,
    px: 2,
    fontSize: 'xs',
    rounded: 6,
    iconSpacing: 0.5,
  },
};

const buttonStyle: Record<Variant, Record<ColorScheme, StyleObject>> = {
  solid: {
    primary: {
      bg: 'orange.500',
      color: 'white',
      _hover: {
        bg: 'orange.600',
      },
      _active: {
        bg: 'orange.700',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'orange.500',
      },
    },
    grey: {
      bg: 'grey.100',
      color: 'grey.800',
      _hover: {
        bg: 'grey.200',
      },
      _active: {
        bg: 'grey.300',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'grey.100',
      },
    },
    whiteAlpha: {
      bg: 'whiteAlpha.500',
      color: 'white',
      _hover: {
        bg: 'whiteAlpha.400',
      },
      _active: {
        bg: 'whiteAlpha.300',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'whiteAlpha.500',
      },
    },
    error: {
      bg: 'red.500',
      color: 'white',
      _hover: {
        bg: 'red.600',
      },
      _active: {
        bg: 'red.700',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'red.500',
      },
    },
    info: {
      bg: 'blue.500',
      color: 'white',
      _hover: {
        bg: 'blue.600',
      },
      _active: {
        bg: 'blue.700',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'blue.500',
      },
    },
  },
  outline: {
    primary: {
      bg: 'transparent',
      color: 'orange.500',
      borderColor: 'orange.500',
      _hover: {
        bg: 'orange.50',
      },
      _active: {
        bg: 'orange.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    grey: {
      bg: 'transparent',
      color: 'grey.900',
      borderColor: 'grey.200',
      _hover: {
        bg: 'grey.50',
      },
      _active: {
        bg: 'grey.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    whiteAlpha: {
      bg: 'transparent',
      color: 'white',
      borderColor: 'whiteAlpha.500',
      _hover: {
        bg: 'whiteAlpha.400',
      },
      _active: {
        bg: 'whiteAlpha.300',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    error: {
      bg: 'transparent',
      color: 'red.500',
      borderColor: 'red.500',
      _hover: {
        bg: 'red.50',
      },
      _active: {
        bg: 'red.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    info: {
      bg: 'transparent',
      color: 'blue.500',
      borderColor: 'blue.500',
      _hover: {
        bg: 'blue.50',
      },
      _active: {
        bg: 'blue.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
  },
  ghost: {
    primary: {
      bg: 'transparent',
      color: 'orange.500',
      _hover: {
        bg: 'orange.50',
      },
      _active: {
        bg: 'orange.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    grey: {
      bg: 'transparent',
      color: 'grey.900',
      _hover: {
        bg: 'grey.100',
      },
      _active: {
        bg: 'grey.200',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    whiteAlpha: {
      bg: 'transparent',
      color: 'white',
      _hover: {
        bg: 'whiteAlpha.400',
      },
      _active: {
        bg: 'whiteAlpha.300',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    error: {
      bg: 'transparent',
      color: 'red',
      _hover: {
        bg: 'red.50',
      },
      _active: {
        bg: 'red.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
    info: {
      bg: 'transparent',
      color: 'blue',
      _hover: {
        bg: 'blue.50',
      },
      _active: {
        bg: 'blue.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        bg: 'transparent',
      },
    },
  },
  link: {
    primary: {
      bg: 'transparent',
      color: 'orange.500',
      px: 0,
    },
    grey: {
      bg: 'transparent',
      color: 'grey.900',
      px: 0,
    },
    whiteAlpha: {
      bg: 'transparent',
      color: 'white',
      px: 0,
    },
    error: {
      bg: 'transparent',
      color: 'red.500',
      px: 0,
    },
    info: {
      bg: 'transparent',
      color: 'blue.500',
      px: 0,
    },
  },
};

const iconVariants = cva([], {
  variants: {
    size: {
      lg: 'text-6',
      md: 'text-6',
      sm: 'text-4',
      xs: 'text-4',
    },
  },
});
