import { cva } from 'class-variance-authority';

import type {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AudioPlayerButton } from '@/components/audio-player-button/audio-player-button';
import { TDS, Spacer, Skeleton } from '@/components/ui';
import { cn } from '@/utils';
import { isNil } from '@/utils/guard';

export type CharacterCardProps = ComponentProps<'div'>;

export const CharacterCard = forwardRef<HTMLDivElement, CharacterCardProps>(
  function (props, ref) {
    const { children, className, ...rest } = props;

    return (
      <div
        ref={ref}
        className={cn(
          'w-310px h-208px rounded-10px overflow-hidden flex flex-col border border-grey-200',
          className,
        )}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

export type CharacterCardSkeleton = ComponentProps<
  typeof CharacterCardSkeleton
>;
export const CharacterCardSkeleton = forwardRef<
  ElementRef<typeof Skeleton>,
  ComponentPropsWithoutRef<typeof Skeleton>
>(function (props, ref) {
  const { ...rest } = props;

  return <Skeleton h={208} w={310} rounded={10} ref={ref} {...rest} />;
});

type CharacterCardHeaderProps = ComponentProps<'div'> & {
  status: 'error' | 'info' | 'grey' | 'black';
};

export const CharacterCardHeader = forwardRef<
  HTMLDivElement,
  CharacterCardHeaderProps
>(function (props, ref) {
  const { status, className, children, ...rest } = props;

  return (
    <div
      className={cn(headerVariant({ status }), className)}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

const headerVariant = cva(['h-12 p-2.5 flex flex-row items-center'], {
  variants: {
    status: {
      info: 'bg-info-500 color-white',
      error: 'bg-error-500 color-white',
      grey: 'bg-grey-200 color-black',
      black: 'bg-black color-white',
    },
  },
  defaultVariants: {
    status: 'info',
  },
});

type CharacterCardBodyProps = ComponentProps<'div'>;

export const CharacterCardBody = forwardRef<
  HTMLDivElement,
  CharacterCardBodyProps
>(function (props, ref) {
  const { className, children, ...rest } = props;

  return (
    <div
      className={cn('flex flex-row items-stretch flex-1', className)}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

type CharacterCardImageProps = Omit<ComponentProps<'div'>, 'children'> & {
  backgroundUrl?: string;
  characterUrl?: string;
  characterAlt?: string;
};

export const CharacterCardImage = forwardRef<
  HTMLDivElement,
  CharacterCardImageProps
>(function (props, ref) {
  const { backgroundUrl, characterUrl, characterAlt, className, ...rest } =
    props;

  return (
    <div
      className={cn('w-140px flex-none relative overflow-hidden', className)}
      ref={ref}
      {...rest}
    >
      {backgroundUrl && (
        <div
          style={{
            backgroundImage: `url(${backgroundUrl})`,
            backgroundColor: '#EDE4F1',
          }}
          className="absolute left-0 top-0 bg-white w-full h-full"
        />
      )}
      <img
        src={characterUrl}
        alt={characterAlt}
        className="absolute left-0 top-0 w-full h-auto"
      />
    </div>
  );
});

type CharacterCardInfoProps = Omit<ComponentProps<'div'>, 'children'> & {
  name: string;
  language: string;
  content?: string;
  sampleUrl?: string | null;
};

export const CharacterCardInfo = forwardRef<
  HTMLDivElement,
  CharacterCardInfoProps
>(function (props, ref) {
  const { name, language, content, sampleUrl, className, ...rest } = props;
  const { t } = useTranslation('common');
  return (
    <div
      className={cn('flex flex-col py-3 px-2 flex-1 items-stretch', className)}
      ref={ref}
      {...rest}
    >
      <TDS.Typo as="h5" size="lg" weight="bold" textAlign={'start'}>
        {name}
      </TDS.Typo>
      <TDS.Typo size="xs" weight="bold" textAlign={'start'}>
        {t(`language.${language}`)}
      </TDS.Typo>
      <TDS.Typo size="xs" weight="normal" color="grey.600" textAlign={'start'}>
        {content ? `#${content}` : null}
      </TDS.Typo>
      <Spacer />
      <AudioPlayerButton
        src={sampleUrl ?? ''}
        onClick={e => e.stopPropagation()}
        showProgressBar
        disabled={isNil(sampleUrl)}
      />
    </div>
  );
});

export type CharacterCardGroupProps = ComponentProps<'div'>;
export function CharacterCardGroup({
  className,
  ...props
}: CharacterCardGroupProps) {
  return (
    <div
      className={cn('flex flex-row flex-wrap gap-x-15px gap-y-4', className)}
      {...props}
    />
  );
}
