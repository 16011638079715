import { MAX_INDEX } from '../components/settlement-pagination';

export const paginationRange = (
  index: number,
  quotient: number,
  totalPageNumber: number,
) => {
  return Array.from(
    { length: index >= quotient ? totalPageNumber - quotient : MAX_INDEX },
    (_, i) => i + (Math.ceil((index + 1) / MAX_INDEX) - 1) * MAX_INDEX + 1,
  );
};
