export function delay(ms?: number) {
  if (!ms) {
    return;
  }
  return new Promise(resolve => setTimeout(resolve, ms));
}

// NOTE: to-camel_case는 toCamelCase로 변환됩니다.
export function toCamelCase(str: string): string {
  return str.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

// NOTE: toSnakeCase는 to_snake_case로 변환됩니다.
export function toSnakeCase(str: string): string {
  return str
    .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    .replace(/^_/, '');
}

export function isAwsS3OrSignedUrl(url: string | undefined) {
  if (!url) {
    return false;
  }
  const s3UrlRegexp = /s3(.|-)?(.*)\.amazonaws\.com/;
  const XAmzAlgorithm = /X-Amz-Algorithm=/;
  return s3UrlRegexp.test(url) || XAmzAlgorithm.test(url);
}
