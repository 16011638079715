import { TFunction } from 'i18next';

import { isValidFileType } from '@/utils';
import { z } from '@/utils/validator';

export const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpeg'];
const nameRegex = /^[가-힣a-zA-Z]+$/;
const frontRegex = /^(?:[0-9]{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01])$/;
const backRegex = /^[1-4][0-9]{6}$/;
const phoneNumberRegex = /^[0][1][0][0-9]{8}$/;

const validateImageFile = (
  files: File[],
  ctx: z.RefinementCtx,
  t?: TFunction<'settlePage', undefined>,
) => {
  const file = files[0];

  if (!file) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t?.('error.image-upload'),
    });
    return;
  }

  if (!isValidFileType(ACCEPTED_IMAGE_TYPES, file)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t?.('error.image-invalid'),
    });
    return;
  }
};

export function settleSchema(t?: TFunction<'settlePage', undefined>) {
  return {
    accountFormSchema: z
      .object({
        accountOwner: z
          .string()
          .min(1, { message: t?.('error.account-name-require') })
          .regex(nameRegex, { message: t?.('error.account-name-invalid') }),
        bank: z.string().min(1, { message: t?.('error.bank-require') }),
        accountNumber: z
          .object({
            number: z
              .string()
              .min(1, { message: t?.('error.account-number-require') }),
            confirmNumber: z.string().min(1, ''),
          })
          .refine(
            data => {
              if (data.number === data.confirmNumber) {
                return true;
              }
              return false;
            },
            { message: t?.('error.account-number-invalid') },
          ),
        bankBook: z
          .custom<File[]>()
          .superRefine(async (files, ctx) => validateImageFile(files, ctx, t)),
      })
      .required({
        accountOwner: true,
        bank: true,
        accountNumber: true,
        bankBook: true,
      }),
    taxFormSchema: z
      .object({
        name: z
          .string()
          .min(1, { message: t?.('error.name-require') })
          .regex(nameRegex, { message: t?.('error.name-invalid') }),
        ssn: z
          .object({
            front: z.string().regex(frontRegex, {
              message: t?.('error.ssn-front-number-invalid'),
            }),
            back: z.string().regex(backRegex, {
              message: t?.('error.ssn-back-number-invalid'),
            }),
          })
          .refine(
            data => {
              if (data.front.length === 6 && data.back.length === 7) {
                return true;
              }
              return false;
            },
            { message: t?.('error.ssn-number-require') },
          ),
        address: z.string().min(1, { message: t?.('error.address-require') }),
        detailAddress: z
          .string()
          .min(1, { message: t?.('error.detailed-address-require') }),
        isKoreanResident: z.boolean(),
        phoneNumber: z
          .string()
          .length(11, { message: t?.('error.phone-number-require') })
          .regex(phoneNumberRegex, {
            message: t?.('error.phone-number-invalid'),
          }),
        email: z.string().email({ message: t?.('error.email-invalid') }),
        isUserResponsible: z.literal(true),
        residenceCard: z
          .custom<File[]>()
          .superRefine(async (files, ctx) => validateImageFile(files, ctx, t)),
      })
      .required({
        name: true,
        address: true,
        isKoreanResident: true,
        ssn: true,
        phoneNumber: true,
        email: true,
        isUserResponsible: true,
        residenceCard: true,
      }),
  };
}
