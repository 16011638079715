import { VoiceHubCharacter } from '@/types/character';

export const isRejectedCharacter = (character: VoiceHubCharacter) => {
  return character.status === 'rejected';
};

export const isFailedCharacter = (character: VoiceHubCharacter) => {
  return character.status === 'failed';
};

export const isExitedCharacter = (character: VoiceHubCharacter) => {
  return character.status === 'exited';
};

export const isExitingCharacter = (character: VoiceHubCharacter) => {
  return character.status === 'exiting';
};
