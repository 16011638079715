import type { ComponentProps } from 'react';

import { cn } from '@/utils';

import { HelpFloatingButton } from '../help-floating-button';
import { BaseLayout } from './base-layout';
import { SideNavigationBar } from './side-navigation-bar';

type PageLayoutProps = ComponentProps<'div'>;

export function PageLayout({ className, children, ...props }: PageLayoutProps) {
  return (
    <BaseLayout>
      <div
        className={cn(
          'h-dvh font-sans font-normal leading-normal flex flex-row bg-background text-grey-900',
          className,
        )}
        {...props}
      >
        <header className="h-full min-w-240px">
          <SideNavigationBar className="h-full"></SideNavigationBar>
        </header>
        <main className="flex-1 min-w-768px h-full scroll-smooth overflow-auto px-12 pt-12 bg-white">
          <div className="max-w-960px w-full pb-40">{children}</div>
          <HelpFloatingButton className="fixed right-8 bottom-8" />
        </main>
      </div>
    </BaseLayout>
  );
}
