import { Flex, FormLabel, Input } from '@chakra-ui/react';

import { DragEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';
import { openFileDialog } from '@/utils';

import { ACCEPTED_IMAGE_TYPES } from '../schema';

type UploadImageFileProps = {
  onChange: (file: File[]) => void;
  file?: File;
  label: string;
};

export function UploadImageFile({
  onChange,
  file: _file,
  label,
}: UploadImageFileProps) {
  const { t } = useTranslation('settlePage');
  const [file, setFile] = useState<File | undefined>();
  const fileUpload = (onChange: (file: File[]) => void) => {
    openFileDialog({ accepts: ACCEPTED_IMAGE_TYPES }).then(res => {
      onChange(res);
      setFile(res[0]);
    });
  };
  const handleDropFile: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();

    const newFile = e.dataTransfer.files[0];
    if (!newFile) {
      return;
    }

    onChange([newFile]);
    setFile(newFile);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
  };

  useEffect(() => {
    setFile(_file);
  }, [_file]);

  return (
    <>
      <FormLabel pointerEvents="none">{label}</FormLabel>
      <Flex
        w="full"
        py={12}
        flexDirection="column"
        textAlign="center"
        rowGap={1}
        position="relative"
        cursor="pointer"
        onClick={() => fileUpload(onChange)}
        onDragOver={handleDragOver}
        onDrop={handleDropFile}
      >
        {file ? (
          <>
            <TDS.Typo as="p">
              {t('input-modal.upload-file.preview')}: {file.name}
            </TDS.Typo>
            <TDS.Typo
              as="p"
              size="sm"
              color="grey.600"
              textDecorationLine="underline"
            >
              {t('input-modal.upload-file.file-change')}
            </TDS.Typo>
          </>
        ) : (
          <TDS.Typo as="p" textDecorationLine="underline">
            {t('input-modal.upload-file.placeholder')}
          </TDS.Typo>
        )}
        <Input
          w="full"
          h="full"
          position="absolute"
          top={0}
          borderRadius={10}
          pointerEvents={'none'}
          readOnly
        />
        {/* 에러 시 표시하기 위한 input */}
      </Flex>
    </>
  );
}
