import { BORDER_RADIUS } from './BORDER';
import { BREAK_POINTS } from './BREAK_POINTS';
import { COLORS } from './COLORS';
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from './FONT';
import { SCREENS } from './SCREENS';
import { SHADOWS } from './SHADOWS';

export const TOKEN = {
  screens: SCREENS,
  breakpoints: BREAK_POINTS,
  fontFamily: FONT_FAMILY,
  fontSize: FONT_SIZE,
  fontWeight: FONT_WEIGHT,
  colors: COLORS,
  borderRadius: BORDER_RADIUS,
  shadows: SHADOWS,
  animation: {
    keyframes: {
      beat: `{
          0% { transform: scale(1) }
          100% { transform: scale(1.5) }
        }`,
    },
  },
} as const;
