import { TDS } from '@/libs/chakra';
import { TypoProps } from '@/libs/chakra/components/Typo';
import { formatToKRW } from '@/utils/format-currency';

export function SettlementRevenue({
  revenue,
  onClickDetail,
  ...props
}: TypoProps & {
  onClickDetail: () => void;
  revenue: number;
}) {
  const onClickTypo = () => {
    onClickDetail();
  };

  return (
    <TDS.Typo
      as="span"
      className="underline-under"
      cursor="pointer"
      textDecorationLine="underline"
      onClick={onClickTypo}
      {...props}
    >
      {formatToKRW(revenue)}원
      <i className="i-t-detail text-6 ml-1 color-black" />
    </TDS.Typo>
  );
}
