import { Navigate } from 'react-router-dom';

import { useRole } from '@/hooks/use-role';

export type NavigateHomeByRoleProps = { replace?: boolean };

export function NavigateHomeByRole({ replace }: NavigateHomeByRoleProps) {
  const { isNormalProvider } = useRole();
  const home = isNormalProvider ? '/partners/settle' : '/partners';

  return <Navigate to={home} replace={replace} />;
}
