import { ComponentProps } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS, useDisclosure } from '@/components/ui';
import { AccountManager } from '@/libs/auth';
import { cn } from '@/utils/cn';
import { z, zodResolver } from '@/utils/validator';

import { EmailField } from './email-field';
import { PasswordField } from './password-field';
import { ResetPasswordModal } from './reset-password-modal';

const EMAIL_FORMAT_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const signInSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'email은 최소 1글자 이상이 필요합니다.' })
    .regex(EMAIL_FORMAT_REGEX, {
      message: '이메일 주소의 형식이 잘못되었습니다.',
    }),
  password: z.string().min(1, { message: '비밀번호를 입력해 주세요.' }),
});

type EmailSignInViewProps = Omit<ComponentProps<'div'>, 'onError'> & {
  onClose: () => void;
  onError?: (err: Error) => void;
  onBeforeSignIn?: () => void;
  onAfterSignIn?: (isSuccess: boolean) => void;
  errorMessage?: string;
};
export function EmailSignInView({
  onClose,
  onError,
  onBeforeSignIn,
  onAfterSignIn,
  errorMessage,
  className,
  ...props
}: EmailSignInViewProps) {
  const form = useForm<z.infer<typeof signInSchema>>({
    resolver: zodResolver(signInSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { t } = useTranslation('signInPage');

  const {
    onOpen: openResetPasswordModal,
    onClose: closeResetPasswordModal,
    isOpen: isResetPasswordModalOpen,
  } = useDisclosure();

  const handleSignIn: SubmitHandler<
    z.infer<typeof signInSchema>
  > = async form => {
    let isSuccess = true;
    try {
      onBeforeSignIn && onBeforeSignIn();
      await AccountManager.signInEmail(form);
    } catch (err) {
      onError && onError(err as Error);
      isSuccess = false;
    } finally {
      onAfterSignIn && onAfterSignIn(isSuccess);
    }
  };

  return (
    <>
      <div className={cn('flex flex-col justify-center', className)} {...props}>
        <button className="absolute top-4 left-4 size-12" onClick={onClose}>
          <i className="i-t-long-left color-grey-900 text-6" />
        </button>
        <TDS.Typo
          as="h2"
          size="2xl"
          weight="bold"
          className="text-center mb-10"
        >
          {t('sign_in_email.title')}
        </TDS.Typo>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleSignIn)}
            className="flex flex-col"
          >
            <EmailField className="mb-4" label={t('sign_in_email.address')} />
            <PasswordField
              className="mb-8"
              label={t('sign_in_email.password')}
            />
            {errorMessage && (
              <TDS.Typo
                as="p"
                color="error.500"
                textAlign={'center'}
                size="md"
                weight="medium"
                aria-errormessage={errorMessage}
              >
                {errorMessage}
              </TDS.Typo>
            )}
            <button
              className="self-end cursor-pointer mb-2"
              type="button"
              onClick={openResetPasswordModal}
            >
              <TDS.Typo as="span" size="sm" weight="bold">
                {t('sign_in_email.find-password')}
              </TDS.Typo>
            </button>
            <TDS.Button type="submit" colorScheme="primary" size="md">
              {t('sign_in_email.sign-in')}
            </TDS.Button>
          </form>
        </FormProvider>
      </div>
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onClose={closeResetPasswordModal}
      />
    </>
  );
}
