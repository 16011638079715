import * as typecastAPI from '@/api/typecast';
import * as voicehubAPI from '@/api/voice-hub';
import { CONTENT_OPTIONS, MOOD_OPTIONS } from '@/constants/character';
import { isFile, isValidFileType } from '@/utils/file';
import { isEmptyString, isFailed, isNil, isSuccess } from '@/utils/guard';
import { getAudioDurationFromFile } from '@/utils/media';
import { z } from '@/utils/validator';

const MIN_AUDIO_DURATION = 5 * 60;
export const ACCEPTED_AUDIO_TYPES = [
  'audio/wav',
  'audio/x-wav', // NOTE: 사파리에선 wav가 아니라 x-wav로 읽히는 문제가 있어 추가함
  'audio/mpeg', // mp3
  'audio/x-m4a', // m4a
  'audio/ogg',
];
export const ACCEPTED_CASTING_IMAGE_TYPES = ['image/png'];
export const ACCEPTED_PROFILE_IMAGE_TYPES = ['image/png'];
export const ACCEPTED_VIDEO_IMAGE_TYPES = ['image/png'];
export const GENDER_OPTIONS = ['man', 'woman'] as const;
export const LANGUAGE_OPTIONS = ['ko'] as const;
export const AGE_GROUP_OPTIONS = [
  'child',
  'teenage',
  'youth',
  'middle_aged',
  'mature',
] as const;
export const TONE_OPTIONS = ['low', 'mid', 'high'] as const;

export const voiceSchema = z
  .custom<File[]>()
  .superRefine(async (files, ctx) => {
    const file = files && files[0];
    if (!isFile(file)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '음성 파일을 입력해 주세요',
      });
      return;
    }

    if (!isValidFileType(ACCEPTED_AUDIO_TYPES, file)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '잘못된 파일을 업로드 하였습니다. 다른 파일로 시도해 주세요.',
      });
      return;
    }

    const duration = await getAudioDurationFromFile(file);
    if (duration < MIN_AUDIO_DURATION) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '음성 길이는 최소 5분 이상이어야 합니다.',
      });
    }
  });

export const genderSchema = z.enum(GENDER_OPTIONS);
export const languageSchema = z.enum(LANGUAGE_OPTIONS);

const NAMEABLE_CHARACTER_REGEX = /^[가-힣a-zA-Z0-9 ]+$/;
export const characterKoreanNameSchema = z
  .string()
  .superRefine(async (name, ctx) => {
    if (isNil(name) || isEmptyString(name)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '한글 이름은 1글자 이상 필요합니다.',
      });
      return;
    }

    if (!NAMEABLE_CHARACTER_REGEX.test(name)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '이름은 한/영/숫자 및 공백 외의 문자를 입력할 수 없습니다.',
      });
      return;
    }

    const duplicateRequests = await Promise.all([
      voicehubAPI.requestGetIsDuplicatedCharacterName({ nameKo: name }),
      typecastAPI.requestGetIsDuplicatedCharacterName({ nameKo: name }),
    ]);

    if (duplicateRequests.some(isFailed)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '요청에 실패했습니다. 다시 시도해 주세요.',
      });
      return;
    }

    const isDuplicated = duplicateRequests.some(res => {
      if (isSuccess(res)) {
        return res.data;
      }
      return true;
    });

    if (isDuplicated) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '이미 존재하는 이름입니다. 다른 이름을 입력해 주세요.',
      });
      return;
    }
  });

export const characterEnglishNameSchema = z
  .string()
  .superRefine(async (name, ctx) => {
    if (isNil(name) || isEmptyString(name)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '영어 이름은 1글자 이상 필요합니다.',
      });
      return;
    }

    if (!NAMEABLE_CHARACTER_REGEX.test(name)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '이름은 한/영/숫자 및 공백 외의 문자를 입력할 수 없습니다.',
      });
      return;
    }

    const duplicateRequests = await Promise.all([
      voicehubAPI.requestGetIsDuplicatedCharacterName({ nameEn: name }),
      typecastAPI.requestGetIsDuplicatedCharacterName({ nameEn: name }),
    ]);

    if (duplicateRequests.some(isFailed)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '요청에 실패했습니다. 다시 시도해 주세요.',
      });
      return;
    }

    const isDuplicated = duplicateRequests.some(res => {
      if (isSuccess(res)) {
        return res.data;
      }
      return true;
    });

    if (isDuplicated) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '이미 존재하는 이름입니다. 다른 이름을 입력해 주세요.',
      });
      return;
    }
  });

export const characterDescriptionSchema = z
  .string()
  .min(10, {
    message: '캐릭터 설명은 최소 10자 이상이어야 합니다.',
  })
  .max(500, {
    message: '캐릭터 설명은 최대 500자입니다.',
  });

export const characterAgeGroupSchema = z.enum(AGE_GROUP_OPTIONS, {
  message: '연령층을 입력해 주세요.',
});
export const characterToneGroupSchema = z.enum(TONE_OPTIONS, {
  message: '톤을 입력해 주세요.',
});
export const characterMoodSchema = z
  .array(z.enum(MOOD_OPTIONS))
  .min(1, { message: '분위기는 1개 이상 입력해 주세요.' })
  .max(4, { message: '분위기는 최대 4개 입력 가능합니다.' });
export const characterContentSchema = z
  .array(z.enum(CONTENT_OPTIONS))
  .min(1, { message: '콘텐츠를 1개 지정해 주세요.' })
  .max(1, { message: '콘텐츠를 1개만 지정해 주세요.' });

export const characterCastingImageSchema = z.custom<File[]>().optional();
// .superRefine((files, ctx) => {
//   const file = files && files[0];

//   if (!isFile(file)) {
//     ctx.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: '이미지 파일을 입력해 주세요.',
//     });
//     return;
//   }

//   if (!isValidFileType(ACCEPTED_CASTING_IMAGE_TYPES, file)) {
//     ctx.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: '잘못된 파일을 업로드 하였습니다. 다른 파일로 시도해 주세요.',
//     });
//     return;
//   }
// });

export const characterProfileImageSchema = z.custom<File[]>().optional();
// .superRefine((files, ctx) => {
//   const file = files && files[0];

//   if (!isFile(file)) {
//     ctx.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: '이미지 파일을 입력해 주세요.',
//     });
//     return;
//   }

//   if (!isValidFileType(ACCEPTED_PROFILE_IMAGE_TYPES, file)) {
//     ctx.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: '잘못된 파일을 업로드 하였습니다. 다른 파일로 시도해 주세요.',
//     });
//     return;
//   }
// });

export const characterVideoImageSchema = z.custom<File[]>().optional();
// .superRefine((files, ctx) => {
//   const file = files && files[0];

//   if (!isFile(file)) {
//     ctx.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: '이미지 파일을 입력해 주세요.',
//     });
//     return;
//   }

//   if (!isValidFileType(ACCEPTED_VIDEO_IMAGE_TYPES, file)) {
//     ctx.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: '잘못된 파일을 업로드 하였습니다. 다른 파일로 시도해 주세요.',
//     });
//     return;
//   }
// });

const termsSchema = z
  .object({
    term1: z.boolean(),
    term2: z.boolean(),
    term3: z.boolean(),
    term4: z.boolean(),
  })
  .refine(data => Object.values(data).every(value => value === true));

export const formSchema = z.object({
  voice: voiceSchema,
  gender: genderSchema,
  language: languageSchema,
  characterKoreanName: characterKoreanNameSchema,
  characterEnglishName: characterEnglishNameSchema,
  characterDescription: characterDescriptionSchema,
  characterAgeGroup: characterAgeGroupSchema,
  characterToneGroup: characterToneGroupSchema,
  characterMood: characterMoodSchema,
  characterContent: characterContentSchema,
  characterCastingImage: characterCastingImageSchema,
  characterProfileImage: characterCastingImageSchema,
  characterVideoImage: characterCastingImageSchema,
  terms: termsSchema,
});
