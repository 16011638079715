import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  ReactNode,
} from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TDS,
} from '@/components/ui';

export type TextAreaFormProps = Omit<
  FormControlProps,
  'onChange' | 'onBlur'
> & {
  id?: string;
  label?: ReactNode;
  errorMessage?: ReactNode;
  placeholder?: string;
  name?: string;
  value?: string;
  onChange?: (text: string) => void;
  onBlur?: (text: string) => void;
};
export const TextAreaForm = forwardRef(function (
  {
    id,
    label,
    isDisabled,
    onChange,
    onBlur,
    placeholder,
    errorMessage,
    name,
    value,
    ...props
  }: TextAreaFormProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
    const text = e.currentTarget.value;
    onChange && onChange(text);
  };
  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = e => {
    const text = e.currentTarget.value;
    onBlur && onBlur(text);
  };

  return (
    <FormControl {...props}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <div className="flex flex-row gap-2.5">
        <Textarea
          id={id}
          rounded={'lg'}
          bg="white"
          name={name}
          ref={ref}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          isDisabled={isDisabled}
          className="resize-none overflow-scroll"
          placeholder={placeholder}
          h="120px"
          px={3}
          py={2}
          _invalid={{
            bg: 'red.50',
            border: '1px solid',
            borderColor: 'red.500',
          }}
        />
      </div>
      <FormErrorMessage mt={1}>
        <TDS.Typo size="xs" weight="medium" color="error.500">
          {errorMessage}
        </TDS.Typo>
      </FormErrorMessage>
    </FormControl>
  );
});
