import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type LoadingSlice = {
  isLoading: boolean;
  loaderCount: number;
  showLoading: () => void;
  hideLoading: () => void;
};

export const useLoadingStore = create<LoadingSlice>()(
  devtools(
    set => ({
      loaderCount: 0,
      isLoading: false,
      showLoading: () => {
        set(state => ({
          loaderCount: state.loaderCount + 1,
          isLoading: true,
        }));
      },
      hideLoading: () => {
        set(state => ({
          loaderCount: Math.max(state.loaderCount - 1, 0),
          isLoading: Math.max(state.loaderCount - 1, 0) > 0,
        }));
      },
    }),
    { name: 'LoadingSlice' },
  ),
);
