import { Progress, ProgressProps } from '@/components/ui';

type AudioPlayerProgressBarProps = ProgressProps & {
  isPlaying: boolean;
  time: number;
  duration: number;
  disabled?: boolean;
};

export function AudioPlayerProgressBar({
  isPlaying,
  time,
  duration = 100,
  disabled = false,
  ...props
}: AudioPlayerProgressBarProps) {
  return (
    <Progress
      defaultValue={0}
      value={isPlaying ? time : 0}
      min={0}
      max={disabled ? 1 : duration}
      size="sm"
      rounded="full"
      bg="grey.100"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      colorScheme="primary"
      {...props}
    ></Progress>
  );
}
