import * as Sentry from '@sentry/react';

import type { KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { TDS, useToast } from '@/components/ui';
import { INQUIRY_EMAIL } from '@/constants/help';
import { useMount } from '@/hooks';
import { copyTextToClipboard } from '@/utils/clipboard';

type FallbackProps = Parameters<Sentry.FallbackRender>[0];

const TRANSLATE_ERROR_MESSAGE =
  "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.";

export function Fallback({ error, resetError }: FallbackProps) {
  const err = error as Error;
  const toast = useToast();
  const { t } = useTranslation('fallbackPage');

  useMount(() => {
    Sentry.withScope(scope => {
      scope.setTag('CRUSH', 'react broken');
      if (err.message === TRANSLATE_ERROR_MESSAGE) {
        scope.setTag('TRANSLATE', 'browser translate error');
      }
      Sentry.captureException(err);
    });
  });

  const handleClickInquiry = () => {
    copyTextToClipboard(INQUIRY_EMAIL, () => {
      toast({
        position: 'top',
        render: () => (
          <div className="flex cursor-pointer justify-center gap-3 rounded-xl bg-grey-900 px-4 py-3 ">
            <i className="i-t-alert text-6 text-white" />
            <TDS.Typo color="white" size="md">
              이메일 주소가 클립보드에 복사되었습니다.
            </TDS.Typo>
          </div>
        ),
      });
    });
  };

  const handleKeydownInquiry: KeyboardEventHandler<HTMLSpanElement> = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleClickInquiry();
    }
  };

  const handleClickGoBack = () => {
    resetError();
  };

  return (
    <div>
      <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center">
        <TDS.Typo as="h1" className="text-center" size="3xl" weight="bold">
          {t('wrong')}
        </TDS.Typo>
        <TDS.Typo as="p" className="text-center mb-6" size="md">
          {t('try-again')}
        </TDS.Typo>
        <TDS.Button variant="outline" size="md" onClick={handleClickGoBack}>
          {t('previous')}
        </TDS.Button>
      </div>
      <div className="absolute bottom-6 left-1/2 -translate-x-1/2">
        <TDS.Typo as="p" size="sm" className="text-center">
          {t('help-center')}
        </TDS.Typo>
        <p>
          <TDS.Typo
            as="span"
            tabIndex={0}
            className="underline underline-offset-2.5 color-grey-600 cursor-pointer text-center"
            onClick={handleClickInquiry}
            onKeyDown={handleKeydownInquiry}
            size="xs"
          >
            {INQUIRY_EMAIL}
          </TDS.Typo>
          <TDS.Typo as="span" size="xs" className="color-grey-600">
            {t('business-time')}
          </TDS.Typo>
        </p>
      </div>
    </div>
  );
}
