import { Flex } from '@chakra-ui/react';

import { TDS } from '@/libs/chakra';
import { cn } from '@/utils';

import { paginationRange } from '../utils/pagination-array';

export const MAX_INDEX = 10;

export function SettlementPagination({
  index,
  size,
  total = 0,
  onClickPrev,
  onClickNext,
  onClickPage,
}: {
  index: number;
  size: number;
  total?: number;
  onClickPrev: (index: number) => void;
  onClickNext: (index: number, quotient: number) => void;
  onClickPage: (num: number) => void;
}) {
  const totalPageNumber = Math.ceil(total / size);
  const quotient = Math.floor(totalPageNumber / MAX_INDEX) * MAX_INDEX;

  return (
    <Flex w="full" justifyContent="center" gap={1} textAlign="center">
      <button
        className={
          'inline-flex w-6 h-6 justify-center items-center ' +
          `${index < MAX_INDEX ? 'cursor-not-allowed' : 'cursor-pointer'}`
        }
        onClick={() => onClickPrev(index)}
      >
        <i
          className={cn(
            'i-t-chevron-right text-4 scale-x--100 scale-y-100 bg-grey-900',
            `${index < MAX_INDEX && 'opacity-40'}`,
          )}
        />
      </button>
      {paginationRange(index, quotient, totalPageNumber).map(num => (
        <button
          className={
            'w-6 h-6 rounded-1.5 border-1 inline-flex justify-center items-center ' +
            `${index + 1 === num ? 'color-grey-800 border-grey-200' : 'border-transparent color-grey-400'}`
          }
          key={`settlement_table_pagination_${num}`}
          onClick={() => onClickPage(num)}
        >
          <TDS.Typo as="p" size="xs" weight="semibold">
            {num}
          </TDS.Typo>
        </button>
      ))}

      <button
        className={
          'inline-flex w-6 h-6 justify-center items-center ' +
          `${quotient <= index ? 'cursor-not-allowed' : 'cursor-pointer'}`
        }
        onClick={() => onClickNext(index, quotient)}
      >
        <i
          className={cn(
            'i-t-chevron-right text-4 bg-grey-900',
            `${quotient <= index && 'opacity-40'}`,
          )}
        />
      </button>
    </Flex>
  );
}
