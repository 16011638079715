import { useController, useFormContext } from 'react-hook-form';

import { EmailForm } from '@/components/form/email-form';

type EmailFieldProps = {
  disabled?: boolean;
  className?: string;
  label?: string;
};

export function EmailField({
  className,
  disabled,
  label,
  ...props
}: EmailFieldProps) {
  const { control } = useFormContext();
  const {
    field: { name, value, onChange, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'email',
    rules: { required: true },
    disabled,
  });
  const emailFieldId = `${useId()}-email-field`;

  return (
    <EmailForm
      id={emailFieldId}
      label={label}
      isInvalid={invalid}
      isDisabled={disabled}
      name={name}
      placeholder="customer@example.com"
      value={value}
      onChange={onChange}
      ref={ref}
      errorMessage={error?.message}
      className={className}
      {...props}
    />
  );
}
