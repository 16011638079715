import { initializeApp } from 'firebase/app';
import {
  User,
  getAuth,
  getIdToken,
  onIdTokenChanged,
  signInWithCustomToken as _signInWithCustomToken,
} from 'firebase/auth';

import { requestGetProviders, requestGetCustomToken } from '@/api/typecast';
import { delay, type Endpoint } from '@/libs/http-client';
import { noop } from '@/utils/function';

import { PROVIDER } from '../constants';
import { TypecastAuthError } from '../errors/TypecastAuthError';
import { EmailSignIn, FacebookSignIn, GoogleSignIn } from '../providers';
import type {
  EmailSignInParams,
  OAuthProvider,
  OAuthSignInParams,
} from '../types';
import { isCustomToken, isFacebookProvider, isGoogleProvider } from '../utils';
import { ErrorManager } from './error';

export const MAX_RETRY_COUNT = 30;

export class AccountManager {
  static onAccountUpdate: (user?: User) => void = noop;
  static async init({
    onAccountUpdate,
  }: {
    onAccountUpdate?: (user?: User) => void;
  }) {
    if (onAccountUpdate) {
      AccountManager.onAccountUpdate = onAccountUpdate;
    }
    initializeApp(AccountManager.getFirebaseConfig());
    onIdTokenChanged(getAuth(), AccountManager.onTokenChanged);
    FacebookSignIn.init();
  }

  static getFirebaseConfig() {
    return {
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    };
  }

  static async getAccessToken(
    endpoint?: Endpoint,
    retryCount: number = 0,
  ): Promise<string> {
    const currentUser = getAuth().currentUser;

    if (!currentUser) {
      if (retryCount >= MAX_RETRY_COUNT) {
        if (endpoint) {
          // TODO: Sentry integration for error logging
          throw new Error('User is not signed in');
        }
      }
      await delay(500);
      return AccountManager.getAccessToken(endpoint, retryCount + 1);
    }

    const idToken = await getIdToken(currentUser);
    return isCustomToken(idToken) ? idToken : '';
  }

  static async onTokenChanged(user: User | null) {
    if (!user) {
      AccountManager.onAccountUpdate();

      return;
    }
    try {
      const customToken = await AccountManager.getAccessToken();
      if (customToken) {
        // TODO: Fetch user data
        AccountManager.onAccountUpdate(user);
      }
    } catch (err) {
      ErrorManager.logError(err);
    }
  }

  static async getIdTokenFromCurrentUser(forceRefresh?: boolean) {
    const user = getAuth().currentUser;
    if (!user) {
      throw new TypecastAuthError('auth/user-is-not-signed-in');
    }
    return getIdToken(user, forceRefresh);
  }

  static async getCustomToken(forceRefresh?: boolean) {
    const idToken =
      await AccountManager.getIdTokenFromCurrentUser(forceRefresh);
    const res = await requestGetCustomToken({
      token: idToken,
    });

    if (res.isFailed) {
      throw res.error;
    }

    const customToken = res.data;

    if (customToken.status !== 'auth/done') {
      throw new TypecastAuthError(customToken.status, {
        customToken,
      });
    }

    return customToken;
  }

  static async signInWithCustomToken(token: string) {
    try {
      const res = await requestGetCustomToken({ token });
      if (res.isFailed) {
        throw res.error;
      }

      const customToken = res.data;
      if (customToken.status !== 'auth/done' || !customToken.accessToken) {
        throw new TypecastAuthError(customToken.status, {
          customToken,
        });
      }

      await _signInWithCustomToken(getAuth(), customToken.accessToken);
    } catch (err) {
      const tcError = ErrorManager.convertToTypecastAuthError(err);
      if (tcError) {
        throw tcError;
      }

      ErrorManager.logError(err);
      throw err;
    }
  }

  static async signInOAuth(
    provider: OAuthProvider,
    { token }: OAuthSignInParams,
  ) {
    try {
      const instance = getOAuthSignInInstance(provider);
      if (!instance) {
        throw new TypecastAuthError('auth/not-exist-provider');
      }

      const email = await instance.getEmail(token);

      if (email) {
        const isExistUser = await instance.checkIsExistUser({ token });
        if (!isExistUser) {
          const res = await requestGetProviders({ email });
          if (res.isFailed) {
            throw res.error;
          }
          const providers = res.data;

          if (providers.length <= 0) {
            // case: 가입되어 있지 않은 유저
            throw new TypecastAuthError('auth/not-exist-social-user');
          }
          // FIXME: social 로그인으로 접근했는데, password로 가입한 유저
          if (!providers.includes(provider)) {
            // case: 다른 provider로 가입한 유저
            throw new TypecastAuthError(
              'auth/already-signup-different-provider',
              {
                providers: providers,
              },
            );
          }
        }
      }

      await instance.signIn(token);

      const customToken = await AccountManager.getCustomToken();
      await _signInWithCustomToken(getAuth(), customToken.accessToken);
    } catch (err) {
      const tcError = ErrorManager.convertToTypecastAuthError(err);
      if (tcError) {
        throw tcError;
      }

      ErrorManager.logError(err);
      throw err;
    }
  }

  static async signInEmail(params: EmailSignInParams) {
    try {
      const instance = getEmailSignInInstance();
      await instance.signIn({ email: params.email, password: params.password });
      const customToken = await AccountManager.getCustomToken();
      await _signInWithCustomToken(getAuth(), customToken.accessToken);
    } catch (err) {
      const tcError = ErrorManager.convertToTypecastAuthError(err);
      if (tcError) {
        throw tcError;
      }

      ErrorManager.logError(err);
      throw err;
    }
  }

  static async signInGoogle(credential: OAuthSignInParams) {
    return await AccountManager.signInOAuth(PROVIDER.GOOGLE, credential);
  }

  static async signInFacebook(credential: OAuthSignInParams) {
    return await AccountManager.signInOAuth(PROVIDER.FACEBOOK, credential);
  }

  static signOut() {
    return getAuth().signOut();
  }
}

const getEmailSignInInstance = () => {
  return new EmailSignIn();
};

const getOAuthSignInInstance = (provider: OAuthProvider) => {
  if (isGoogleProvider(provider)) {
    return new GoogleSignIn();
  }
  if (isFacebookProvider(provider)) {
    return new FacebookSignIn();
  }
};
