import { ComponentProps } from 'react';

import {
  Step,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  TDS,
  useSteps,
} from '@/components/ui';
import { cn } from '@/utils/cn';

type ReleaseProcessStepperProps = Omit<ComponentProps<'div'>, 'children'> & {
  index: number;
  steps: { title: string }[];
  statusMessage?: string;
  exited?: boolean;
};
export function ReleaseProcessStepper({
  statusMessage,
  index,
  steps = [],
  exited = false,
  className,
  ...props
}: ReleaseProcessStepperProps) {
  const { activeStep } = useSteps({
    index: index,
    count: steps.length,
  });

  return (
    <div
      className={cn(
        'rounded-2xl bg-grey-50 p-4 flex flex-col gap-4 place-items-center justify-center',
        className,
      )}
      {...props}
    >
      {statusMessage && (
        <TDS.Typo as="p" size="xl" weight="medium" className="text-center">
          {statusMessage}
        </TDS.Typo>
      )}

      <Stepper
        index={activeStep}
        w="full"
        maxW={816}
        colorScheme={exited ? 'grey' : 'blue'}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator
              w={8}
              h={8}
              display="flex"
              placeItems="center"
              css={{
                '&[data-status="incomplete"]': { borderColor: 'black' },
              }}
            >
              <StepStatus
                complete={
                  <i className="i-nc-check-circle-info size-8 color-white" />
                }
                incomplete={<StepNumber className="text-20px!" />}
                active={<StepNumber className="text-20px!" />}
              />
            </StepIndicator>
            <StepTitle>{step.title}</StepTitle>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
