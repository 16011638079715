/**
 * Scrolls to the specified position within the given element
 * @param {Element} el Element to change the scroll position
 * @param {number} scrollTop scroll position
 */
export const scrollTo = (
  el: Element = document.documentElement,
  scrollTop: number = 0,
) => {
  el.scrollTo({ top: scrollTop, behavior: 'smooth' });
};
