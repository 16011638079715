import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@/hooks/use-auth';

export type AuthenticationGuardProps = {
  children?: React.ReactElement;
};

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  ...props
}) => {
  const { isSigned, isAuthorized } = useAuth();

  if (!isSigned) {
    return <></>;
  }

  if (!isAuthorized) {
    return <Navigate to="/signin" replace />;
  }

  return props.children ?? <Outlet />;
};
