import {
  EmailAuthProvider,
  getAuth,
  signInWithCredential,
} from 'firebase/auth';

import type { EmailSignInParams } from '../types';
import type { EmailSignInInterface } from './base-sign-in';

export class EmailSignIn implements EmailSignInInterface {
  async signIn({ email, password }: EmailSignInParams) {
    const credential = this.getCredential({ email, password });
    return signInWithCredential(getAuth(), credential);
  }

  getCredential({ email, password }: EmailSignInParams) {
    return EmailAuthProvider.credential(email, password);
  }

  getProvider() {
    return new EmailAuthProvider();
  }

  getEmail() {
    return Promise.resolve('');
  }
}
