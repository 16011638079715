import {
  Avatar,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  TDS,
  useDisclosure,
} from '@/components/ui';
import { AccountManager } from '@/libs/auth';
import { useUserStore } from '@/stores/user';

export const Profile = () => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { user } = useUserStore(state => ({ user: state.user }));
  const handleClickLogout = () => {
    AccountManager.signOut();
  };
  const onClickUserProfile = () => {
    onToggle();
  };
  return (
    <TDS.Popover
      placement="top-start"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
    >
      <PopoverTrigger>
        <div className="w-0">
          <TDS.Button
            onClick={onClickUserProfile}
            textAlign="left"
            justifyContent="flex-start"
            color="grey.800"
            colorScheme="grey"
            variant="ghost"
            alignItems="center"
            display={'flex'}
            minW={0}
            p={0}
            w={208}
            gap={2}
          >
            <Avatar size="sm" src={user?.profile}></Avatar>
            <TDS.Typo
              overflowX="hidden"
              whiteSpace="noWrap"
              size="sm"
              display="block"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {user?.email}
            </TDS.Typo>
          </TDS.Button>
        </div>
      </PopoverTrigger>
      <PopoverContent maxWidth="9rem" p={2} ml={1} borderColor="grey.150">
        <PopoverArrow />
        <PopoverBody py={0} px={0}>
          <TDS.Button
            size="sm"
            colorScheme="grey"
            variant="ghost"
            width="100%"
            justifyContent="left"
            onClick={handleClickLogout}
            gap={2}
            color="grey.800"
            alignItems="center"
          >
            <i className="i-t-logout text-6" />
            <TDS.Typo size="md">Sign out</TDS.Typo>
          </TDS.Button>
        </PopoverBody>
      </PopoverContent>
    </TDS.Popover>
  );
};
