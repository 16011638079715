import {
  GroupBase,
  Select,
  SelectInstance,
  SingleValue,
} from 'chakra-react-select';

import { LegacyRef, ReactNode } from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  TDS,
} from '@/components/ui';
import { isNil } from '@/utils/guard';

export type SelectItem = {
  label: string;
  value: string;
};

export type SelectFormProps = Omit<FormControlProps, 'onSelect'> & {
  label?: ReactNode;
  errorMessage?: ReactNode;
  placeholder?: string;
  options: SelectItem[];
  onSelect?: (item: string) => void;
  name: string;
  value: string;
  defaultValue?: string;
};
export const SelectForm = forwardRef(function (
  {
    label,
    name,
    id,
    errorMessage,
    value,
    defaultValue,
    options = [],
    placeholder,
    isInvalid,
    isDisabled,
    isReadOnly,
    onSelect,
    ...props
  }: SelectFormProps,
  ref:
    | LegacyRef<SelectInstance<SelectItem, false, GroupBase<SelectItem>>>
    | undefined,
) {
  const handleSelect = (selected: SingleValue<SelectItem>) => {
    if (isNil(selected)) {
      return;
    }

    const item = options.find(s => s.value === selected.value);
    if (item) {
      onSelect && onSelect(item.value);
    }
  };

  return (
    <FormControl translate="no" isInvalid={isInvalid} {...props}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <Select<SelectItem>
        name={name}
        ref={ref}
        options={options}
        value={options.find(opt => opt.value === value)}
        defaultValue={options.find(opt => opt.value === defaultValue)}
        onChange={handleSelect}
        menuPortalTarget={document.body}
        closeMenuOnScroll={true}
        isInvalid={isInvalid}
        styles={{
          menuPortal: provided => ({
            ...provided,
            zIndex: 1500,
          }),
        }}
        chakraStyles={{
          placeholder: provided => ({
            ...provided,
            color: 'grey.400',
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: 10,
            borderRadius: 10,
            border: '1px',
            background: state.isFocused
              ? 'white'
              : isInvalid
                ? 'error.50'
                : isReadOnly
                  ? 'grey.100'
                  : 'white',
            opacity: isDisabled ? 0.5 : 1,
            color: 'grey.700',
            _focus: {
              color: 'grey.900',
            },
            _invalid: {
              boxShadow: 'none',
              borderColor: 'error.500',
            },
            pointerEvents: isReadOnly ? 'none' : 'auto',
          }),
          menuList: provided => ({
            ...provided,
            borderRadius: 10,
          }),
        }}
        focusBorderColor="primary.500"
        selectedOptionStyle="check"
        menuPlacement="auto"
        useBasicStyles
        isSearchable={false}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
      <FormErrorMessage mt={1}>
        <TDS.Typo size="xs" weight="medium" color="error.500">
          {errorMessage}
        </TDS.Typo>
      </FormErrorMessage>
    </FormControl>
  );
});
