import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextForm } from '@/components/form/text-form';
import { TDS } from '@/components/ui';
import { z } from '@/utils/validator';

import { characterEnglishNameSchema } from '../schema';

type CharacterEnglishNameFieldProps = {
  disabled?: boolean;
  originName?: string;
};

export function CharacterEnglishNameField({
  disabled,
  originName,
  ...props
}: CharacterEnglishNameFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setError, clearErrors, setValue } = useFormContext();
  const characterEnglishNameFieldId = `${useId()}-character-english-name-field`;
  const {
    field: { name, ref, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'characterEnglishName',
    rules: { required: true },
    disabled,
  });

  const [fieldValue, setFieldValue] = useState<string | undefined>(value);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const handleChange = (value?: string) => {
    setFieldValue(value);
    if (error) {
      clearErrors('characterEnglishName');
    }
  };
  const handleBlur = async () => {
    setIsValidating(true);
    setValue(
      'characterEnglishName',
      fieldValue as z.infer<typeof characterEnglishNameSchema>,
      { shouldDirty: true },
    );

    if (originName === fieldValue) {
      clearErrors('characterEnglishName');
      setIsValidating(false);
      return;
    }

    const result = await characterEnglishNameSchema.safeParseAsync(fieldValue);
    setIsValidating(false);

    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('characterEnglishName', {
        message: error[0].message,
      });
    } else {
      clearErrors('characterEnglishName');
    }
  };

  return (
    <TextForm
      id={characterEnglishNameFieldId}
      label={t('components.character-english-name-field.label')}
      isInvalid={invalid}
      isDisabled={disabled}
      name={name}
      placeholder={t('components.character-english-name-field.placeholder')}
      value={fieldValue}
      helperText={
        isValidating ? (
          <p className="flex flex-row items-center">
            <TDS.Typo as="span" color="grey.900" size="xs" weight="medium">
              사용 가능 여부를 확인 중입니다...{' '}
            </TDS.Typo>
            <i className="i-t-spinner animate-spin transform-origin-center text-4" />
          </p>
        ) : null
      }
      onChange={handleChange}
      onBlur={handleBlur}
      ref={ref}
      errorMessage={error?.message}
      {...props}
    />
  );
}
