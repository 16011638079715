export class TypecastAuthError extends Error {
  name: string;
  data?: Record<string, unknown>;
  code: string;

  constructor(message: string, data?: Record<string, unknown>) {
    super(message);
    this.name = 'Typecast Auth Error';
    this.code = message;
    this.data = data;
  }
}
