import {
  Popover as _Popover,
  useTheme,
  type PopoverProps as _PopoverProps,
} from '@chakra-ui/react';

import type { ComponentProps } from 'react';

export type PopoverProps = ComponentProps<typeof Popover>;
export const Popover = ({
  children,
  ...props
}: Omit<_PopoverProps, 'arrowSize' | 'arrowShadowColor'>) => {
  const { colors } = useTheme();

  return (
    <_Popover arrowSize={10.5} arrowShadowColor={colors.grey['200']} {...props}>
      {children}
    </_Popover>
  );
};
