import { createContext, ReactNode } from 'react';

import { requestGetPaydayRole, requestUpdatePaydayRole } from '@/api/payday';
import { AccountManager } from '@/libs/auth/managers/account';
import { TokenManager } from '@/libs/http-client/managers/token';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';
import { deleteCookie, getCookie } from '@/utils/cookie';
import { isSuccess } from '@/utils/guard';
import { isForbidden } from '@/utils/http';

const ACCESS_TOKEN_KEY = 'tc_access_token';
const SIGNIN_TYPE = {
  ALREADY_SIGNUP: 'already-signup',
  COMPLETE_SIGNUP: 'complete-signup',
  SOCIAL_TYPE_SIGNIN: 'social-type-signin',
};

type AuthenticationContextProps = {
  isShowAutoSigninWarning: boolean;
  showAutoSigninWarning: () => void;
  hideAutoSigninWarning: () => void;
};
const AuthenticationContext = createContext<AuthenticationContextProps>({
  isShowAutoSigninWarning: false,
  showAutoSigninWarning: () => {},
  hideAutoSigninWarning: () => {},
});

const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const params = new URLSearchParams(window.location.search);
  const signinType = params.get('signin-type');
  const loadingStore = useLoadingStore(state => state);
  const userStore = useUserStore(state => ({
    setUser: state.setUser,
    clearUser: state.clearUser,
    setRoles: state.setRoles,
    setIsSigned: state.setIsSigned,
    isSigned: state.isSigned,
  }));

  const [isShowAutoSigninWarning, setIsShowAutoSigninWarning] =
    useState<boolean>(false);
  const showAutoSigninWarning = () => {
    setIsShowAutoSigninWarning(() => true);
  };
  const hideAutoSigninWarning = () => {
    setIsShowAutoSigninWarning(() => false);
  };
  const autoSignin = async () => {
    try {
      loadingStore.showLoading();
      const accessTokenInCookie = getCookie(ACCESS_TOKEN_KEY) ?? '';
      deleteCookie(ACCESS_TOKEN_KEY);
      if (accessTokenInCookie) {
        await AccountManager.signInWithCustomToken(accessTokenInCookie);
      }
    } catch (error) {
      // TODO: Sentry integration for error logging
    } finally {
      loadingStore.hideLoading();
    }
  };

  async function updateRole() {
    const result = await requestGetPaydayRole();
    if (isSuccess(result)) {
      userStore.setRoles(result.data);
      if (signinType === SIGNIN_TYPE.ALREADY_SIGNUP) {
        showAutoSigninWarning();
      }
      return;
    }

    if (isForbidden(result.error)) {
      const registryResult = await requestUpdatePaydayRole();
      if (isSuccess(registryResult)) {
        userStore.setRoles(registryResult.data);
        if (signinType === SIGNIN_TYPE.ALREADY_SIGNUP) {
          showAutoSigninWarning();
        }
        return;
      }
    }
    AccountManager.signOut();
  }

  // TODO: 피라미드 지옥제거

  useEffect(() => {
    AccountManager.init({
      onAccountUpdate: user => {
        if (!user) {
          userStore.clearUser();
          userStore.setIsSigned(true);
          return;
        }

        userStore.setIsSigned(false);
        loadingStore.showLoading();
        userStore.setUser({
          profile: user.photoURL ?? undefined,
          email: user.email ?? undefined,
        });
        updateRole().then(() => {
          userStore.setIsSigned(true);
          loadingStore.hideLoading();
        });
      },
    });
    TokenManager.init(AccountManager.getAccessToken);

    if (signinType === SIGNIN_TYPE.ALREADY_SIGNUP) {
      autoSignin();
    }
    if (signinType === SIGNIN_TYPE.SOCIAL_TYPE_SIGNIN) {
      autoSignin();
    }
    if (signinType === SIGNIN_TYPE.COMPLETE_SIGNUP) {
      autoSignin();
    }
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        isShowAutoSigninWarning,
        showAutoSigninWarning,
        hideAutoSigninWarning,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationProvider };
