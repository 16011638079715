import { type ComponentProps, type MouseEvent } from 'react';

import { cn } from '@/utils';

import { AudioPlayerProgressBar } from './audio-player-progress-bar';
import { useAudioPlayer } from './use-audio-player';

export type AudioProps = ComponentProps<'div'> & {
  src: string;
  showProgressBar?: boolean;
  disabled?: boolean;
};

export function AudioPlayerButton({
  src,
  showProgressBar,
  className,
  disabled,
  ...props
}: AudioProps) {
  const {
    isLoading,
    isPlaying,
    isStopped,
    play,
    stop,
    timeReset,
    time,
    duration,
  } = useAudioPlayer({
    onStop: () => {
      timeReset();
    },
  });
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    isPlaying ? stop() : play(src);
  };

  useEffect(() => {
    return () => {
      stop();
    };
  }, []);
  disabled = disabled ?? !src;
  if (disabled) {
    //TODO toast error
  }

  return (
    <div
      className={cn('flex flex-row gap-2 items-center', className)}
      {...props}
    >
      <button
        aria-label="audio-play-button"
        onClick={handleClick}
        disabled={disabled}
        className="size-8 flex-none disabled:cursor-not-allowed rounded-full"
      >
        {isPlaying && (
          <i
            className={cn(
              'text-8',
              'i-nc-stop-circle-orange',
              disabled ? 'opacity-50' : '',
            )}
          />
        )}
        {isLoading && (
          <div className="bg-grey-200 rounded-full size-8 cursor-wait">
            <i
              className={cn(
                'text-8',
                'i-t-spinner color-white animate-spin',
                disabled ? 'opacity-50' : '',
              )}
            />
          </div>
        )}
        {isStopped && (
          <i
            className={cn(
              'text-8',
              'i-nc-play-circle-black-bg',
              disabled ? 'opacity-50' : '',
            )}
          />
        )}
      </button>
      {showProgressBar && (
        <AudioPlayerProgressBar
          isPlaying={isPlaying}
          time={time}
          duration={duration}
          disabled={disabled}
          flex={1}
        />
      )}
    </div>
  );
}
