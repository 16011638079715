import type { ComponentProps } from 'react';

import { cn } from '@/utils';

import { BaseLayout } from './base-layout';

type SimpleLayoutProps = ComponentProps<'main'>;

export function SimpleLayout({
  className,
  children,
  ...props
}: SimpleLayoutProps) {
  return (
    <BaseLayout>
      <main
        className={cn(
          'h-dvh font-sans font-normal leading-normal flex flex-col gap-6 justify-center place-items-center bg-background',
          'w-full',
          className,
        )}
        {...props}
      >
        {children}
      </main>
    </BaseLayout>
  );
}
