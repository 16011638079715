import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ChipGroupForm,
  type ChipGroupFormProps,
} from '@/components/form/chip-group-form';
import { CONTENT_OPTIONS } from '@/constants/character';
import { VoiceHubCharacter } from '@/types/character';
import { z } from '@/utils/validator';

import { formSchema, characterContentSchema } from '../schema';

type CharacterContentFieldProps = {
  disabled?: boolean;
  max?: number;
};

export function CharacterContentField({
  disabled,
  max,
  ...props
}: CharacterContentFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setValue, setError, clearErrors } =
    useFormContext<z.infer<typeof formSchema>>();

  const {
    field: { value, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'characterContent',
    rules: { required: true },
    disabled,
  });

  const selectedItemSet = new Set(value);
  const options = CONTENT_OPTIONS.map(opt => ({
    label: t(`components.character-content-field.content.${opt}`),
    value: opt,
    selected: selectedItemSet.has(opt),
  }));

  const handleToggleItem = (item: ChipGroupFormProps['options'][number]) => {
    const newContents = item.selected
      ? value.filter(v => v !== item.value)
      : ([item.value, ...value] as VoiceHubCharacter['content']);

    setValue('characterContent', newContents, { shouldDirty: true });

    const result = characterContentSchema.safeParse(newContents);

    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('characterContent', { message: error[0].message });
    } else {
      clearErrors('characterContent');
    }
  };
  return (
    <ChipGroupForm
      label={t('components.character-content-field.label')}
      errorMessage={error?.message}
      options={options}
      onClickItem={handleToggleItem}
      isInvalid={invalid}
      isDisabled={disabled}
      max={max}
      ref={ref}
      {...props}
    />
  );
}
