import { Card, type CardProps } from '@/components/ui';

export type PlusCardProps = CardProps;
export function PlusCard({ ...props }: PlusCardProps) {
  return (
    <Card
      variant="outline"
      className="flex flex-col items-stretch justify-center w-310px h-208px"
      borderColor="grey.200"
      rounded={10}
      {...props}
    >
      <i className="i-t-plus text-6 self-center"></i>
    </Card>
  );
}
