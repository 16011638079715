import { ReactNode, useEffect } from 'react';

import { useMediaQuery } from '@/components/ui';
import { THEME } from '@/constants/theme';
import { useScreenStore } from '@/stores/screen';

const breakpoints = [
  `(max-width: ${THEME.breakpoints.md})`,
  `(min-width: ${THEME.breakpoints.md}) and (max-width: ${THEME.breakpoints.xl})`,
  `(min-width: ${THEME.breakpoints.xl})`,
];

const updateScreen = useScreenStore.getState().updateScreen;

export const ScreenProvider = ({ children }: { children: ReactNode }) => {
  const screen = useMediaQuery(breakpoints, {
    ssr: typeof window === 'undefined',
  });

  useEffect(() => {
    if (screen.some(value => value)) {
      updateScreen(screen);
    }
  }, [screen]);

  return <>{children}</>;
};
