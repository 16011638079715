export const FONT_FAMILY = {
  sans: [
    'Pretendard',
    'Spoqa Han Sans',
    'Avenir',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
};

export const FONT_SIZE = {
  '6xl': '3.75rem', // 60px
  '5xl': '3rem', // 48px
  '4xl': '2.25rem', // 36px
  '3xl': '1.875rem', // 30px
  '2xl': '1.5rem', // 24px
  xl: '1.25rem', // 20px
  lg: '1.125rem', // 18px
  md: '1rem', // 16px
  sm: '0.875rem', // 14px
  xs: '0.75rem', // 12px
};

export const FONT_WEIGHT = {
  extrabold: '800',
  bold: '700',
  semibold: '600',
  medium: '500',
  normal: '400',
};
