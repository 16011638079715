export function parseAddress(address: string) {
  const index = address.indexOf('+');
  if (index === -1) {
    return {
      address: address.trim(),
      detail: '',
    };
  }

  return {
    address: address.slice(0, index).trim(),
    detail: address.slice(index + 1).trim(),
  };
}
