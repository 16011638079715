import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaForm } from '@/components/form/text-area-form';
import { z } from '@/utils/validator';

import { characterDescriptionSchema } from '../schema';

type CharacterDescriptionFieldProps = {
  disabled?: boolean;
};

export function CharacterDescriptionField({
  disabled,
  ...props
}: CharacterDescriptionFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setError, clearErrors, setValue } = useFormContext();
  const characterDescriptionFieldId = `${useId()}-character-description-field`;
  const {
    field: { name, ref, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'characterDescription',
    rules: { required: true },
    disabled,
  });

  const handleChangeDescription = (description: string) => {
    setValue(
      'characterDescription',
      description as z.infer<typeof characterDescriptionSchema>,
      { shouldDirty: true },
    );

    const result = characterDescriptionSchema.safeParse(description);

    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('characterDescription', { message: error[0].message });
    } else {
      clearErrors('characterDescription');
    }
  };

  return (
    <TextAreaForm
      isInvalid={invalid}
      isDisabled={disabled}
      label={t('components.character-description-field.label')}
      id={characterDescriptionFieldId}
      errorMessage={error?.message}
      ref={ref}
      name={name}
      value={value}
      onChange={handleChangeDescription}
      placeholder={t('components.character-description-field.placeholder')}
      {...props}
    />
  );
}
