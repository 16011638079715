import {
  ComponentProps,
  ElementRef,
  ForwardedRef,
  MouseEventHandler,
  ReactNode,
} from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  TDS,
} from '@/components/ui';
import { Chip } from '@/components/ui/chip';
import { isNil } from '@/utils/guard';

export type ChipItem = {
  label: ComponentProps<typeof Chip>['label'];
  value: string;
  selected: boolean;
};

export type ChipGroupFormProps = FormControlProps & {
  label?: ReactNode;
  errorMessage?: ReactNode;
  options: ChipItem[];
  max?: number;
  onClickItem?: (item: ChipItem) => void;
};
export const ChipGroupForm = forwardRef(function (
  {
    label,
    errorMessage,
    options = [],
    isDisabled,
    max = Infinity,
    onClickItem,
    ...props
  }: ChipGroupFormProps,
  ref: ForwardedRef<typeof FormControl>,
) {
  const handleToggle: MouseEventHandler<ElementRef<typeof Chip>> = e => {
    const value = e.currentTarget.dataset['value'];
    if (isNil(value)) {
      return;
    }

    const item = options.find(s => s.value === value);
    if (item) {
      onClickItem && onClickItem(item);
    }
  };

  const totalSelected = options.filter(opt => opt.selected).length;

  return (
    <FormControl ref={ref} {...props}>
      <FormLabel>{label}</FormLabel>
      <div className="flex flex-row gap-4 flex-wrap">
        {options.map(({ label, value, selected }, i) => (
          <Chip
            key={i}
            selected={selected}
            data-value={value}
            label={label}
            onClick={handleToggle}
            disabled={isDisabled || (totalSelected >= max && !selected)}
          />
        ))}
      </div>
      <FormErrorMessage mt={1}>
        <TDS.Typo size="xs" weight="medium" color="error.500">
          {errorMessage}
        </TDS.Typo>
      </FormErrorMessage>
    </FormControl>
  );
});
