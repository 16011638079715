import { isAxiosError } from 'axios';

import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { requestResetPassword } from '@/api/typecast';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@/components/ui';
import { TDS } from '@/libs/chakra';
import { z, zodResolver } from '@/utils/validator';

import { ResetPasswordField } from './reset-password-field';

const resetPasswordSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'email은 최소 1글자 이상이 필요합니다.' })
    .email({ message: '이메일 형식을 입력해 주세요.' }),
});

export const ResetPasswordModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const [isCompleteSubmit, setIsCompleteSubmit] = useState(false);
  const [sentEmail, setSentEmail] = useState('');
  const { t } = useTranslation('signInPage');

  const form = useForm<z.infer<typeof resetPasswordSchema>>({
    resolver: zodResolver(resetPasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const onCloseModal = () => {
    resetModalState();
    onClose();
  };

  const resetModalState = () => {
    setIsCompleteSubmit(false);
    setSentEmail('');
  };

  const handleResetPassword: SubmitHandler<
    z.infer<typeof resetPasswordSchema>
  > = async data => {
    try {
      await requestResetPassword({ email: data.email });
      setIsCompleteSubmit(true);
      setSentEmail(data.email);
      form.reset();
    } catch (error) {
      console.error(error);
      const { setError } = form;

      if (isAxiosError(error)) {
        const status = error.response?.status;
        const data = error.response?.data;
        if (status && status === 404) {
          setError('email', { message: t('reset_password_modal.error_user') });
          return;
        }
        const errCode = data.message.error_code;
        switch (errCode) {
          case 'ses/complaint-or-bounce-email':
            setError('email', {
              message: t('reset_password_modal.complaint_or_bounce_email'),
            });
            break;
          case 'ses/reset-password':
            setError('email', {
              message: t('reset_password_modal.reset_password'),
            });
            break;
          case 'firebase/too-many-attempts-try':
            setError('email', {
              message: t('reset_password_modal.too_many_attempts_try'),
            });
            break;
          case 'firebase/reset-password-link':
            setError('email', {
              message: t('reset_password_modal.common'),
            });
            break;
          default:
            setError('email', {
              message: t('reset_password_modal.common'),
            });
            break;
        }
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        {!isCompleteSubmit ? (
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleResetPassword)}>
              <ResetPasswordForm
                onClose={onCloseModal}
                isValid={form.formState.isValid}
              />
            </form>
          </FormProvider>
        ) : (
          <ResetPasswordComplete onClose={onCloseModal} email={sentEmail} />
        )}
      </ModalContent>
    </Modal>
  );
};

const ResetPasswordForm: FC<{
  onClose: () => void;
  isValid: boolean;
}> = ({ onClose, isValid }) => {
  const { t } = useTranslation('signInPage');
  return (
    <>
      <ModalHeader>
        <ModalCloseButton />
        <TDS.Typo as="span" size="xl" weight="bold" color="grey.800">
          {t('reset_password_modal.title')}
        </TDS.Typo>
      </ModalHeader>

      <ModalBody display="flex" flexDirection="column" gap={4}>
        <TDS.Typo as="p" color="grey.800" className="whitespace-pre-line">
          {t('reset_password_modal.description')}
        </TDS.Typo>

        <ResetPasswordField label={t('reset_password_modal.email')} />
      </ModalBody>

      <ModalFooter gap={2}>
        <TDS.Button size="md" colorScheme="grey" onClick={onClose}>
          {t('reset_password_modal.cancel')}
        </TDS.Button>
        <TDS.Button size="md" type="submit" isDisabled={!isValid}>
          {t(`reset_password_modal.send`)}
        </TDS.Button>
      </ModalFooter>
    </>
  );
};

const ResetPasswordComplete: FC<{ email: string; onClose: () => void }> = ({
  email,
  onClose,
}) => {
  const { t } = useTranslation('signInPage');
  return (
    <>
      <ModalHeader>
        <ModalCloseButton />
        <TDS.Typo as="span" size="xl" weight="bold" color="grey.800">
          {t('reset-password-complete.title')}
        </TDS.Typo>
      </ModalHeader>

      <ModalBody display="flex" flexDirection="column" gap={4}>
        <TDS.Typo as="p" color="grey.800">
          {t('reset-password-complete.description')}
          <br />
          <TDS.Typo as="span" color="grey.800" weight="bold">
            {email}
          </TDS.Typo>
        </TDS.Typo>
      </ModalBody>

      <ModalFooter gap={2}>
        <TDS.Button size="md" colorScheme="primary" onClick={onClose}>
          {t('reset-password-complete.close')}
        </TDS.Button>
      </ModalFooter>
    </>
  );
};
