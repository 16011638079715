import { VoiceHubCharacter } from '@/types/character';
import { Review } from '@/types/review';
import { isFailedCharacter, isRejectedCharacter } from '@/utils/character';

export const isVoiceChangableCharacter = (
  character: VoiceHubCharacter,
  review?: Review,
) => {
  if (isFailedCharacter(character)) {
    return true;
  }

  if (
    isRejectedCharacter(character) &&
    review &&
    review.reject.audio.length > 0
  ) {
    return true;
  }

  return false;
};

export const isInfoChangableCharacter = (
  character: VoiceHubCharacter,
  review?: Review,
) => {
  if (isFailedCharacter(character)) {
    return true;
  }
  if (isRejectedCharacter(character) && review) {
    if (review.reject.info.length > 0 || review.reject.image.length > 0) {
      return true;
    }
  }

  return false;
};

export const isDeletableCharacter = (character: VoiceHubCharacter) => {
  return isFailedCharacter(character) || isRejectedCharacter(character);
};

export const getStepIndex = (character: VoiceHubCharacter) => {
  if (character.status === 'learning') {
    return 1;
  }
  if (character.status === 'failed') {
    return 1;
  }
  if (character.status === 'judging') {
    return 2;
  }
  if (character.status === 'rejected') {
    return 2;
  }
  return 4;
};
