import { ComponentProps } from 'react';

import { TDS } from '@/libs/chakra';
import { cn } from '@/utils';

export type SubTitleProps = ComponentProps<'h3'>;
export function SubTitle({ className, ...props }: SubTitleProps) {
  return (
    <TDS.Typo as="h3" size="xl" className={cn('mb-4', className)} {...props} />
  );
}
