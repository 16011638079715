import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';

export function SettlementIsKoreanResidentField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();

  const {
    field: { name, value, onChange, ref },
  } = useController({
    control,
    name: 'isKoreanResident',
    rules: { required: true },
  });

  return (
    <TDS.Checkbox
      name={name}
      defaultChecked={value ?? false}
      onChange={e => onChange(e.target.checked)}
      ref={ref}
    >
      <TDS.Typo as="p" size="sm">
        {t('input-modal.is-korean-resident-field.label')}
      </TDS.Typo>
    </TDS.Checkbox>
  );
}
