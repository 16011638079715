import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectForm } from '@/components/form/select-form';
import { z } from '@/utils/validator';

import { LANGUAGE_OPTIONS, languageSchema } from '../schema';

type LanguageOption = {
  label: string;
  value: (typeof LANGUAGE_OPTIONS)[number];
};

type OriginalVoiceGenderFieldProps = {
  disabled?: boolean;
  readonly?: boolean;
};

export function OriginalVoiceLanguageField({
  disabled,
  readonly,
  ...props
}: OriginalVoiceGenderFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setError, clearErrors, setValue } = useFormContext();
  const voiceLanguageId = `${useId()}-language-field`;
  const {
    field: { name, value, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'language',
    rules: { required: true },
    defaultValue: LANGUAGE_OPTIONS[0],
  });

  const languageOptions = LANGUAGE_OPTIONS.map(
    lang =>
      ({
        label: t('components.original-voice-language-field.language.ko'),
        value: lang,
      }) satisfies LanguageOption,
  );

  const handleSelect = (item: string) => {
    setValue('language', item as z.infer<typeof languageSchema>, {
      shouldDirty: true,
    });
    const result = languageSchema.safeParse(item);

    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('language', {
        message: error[0].message,
      });
    } else {
      clearErrors('language');
    }
  };

  return (
    <SelectForm
      label={t('components.original-voice-language-field.label')}
      id={voiceLanguageId}
      placeholder={t('components.original-voice-language-field.placeholder')}
      options={languageOptions}
      isInvalid={invalid}
      isDisabled={disabled}
      isReadOnly={readonly}
      name={name}
      ref={ref}
      value={value}
      defaultValue={languageOptions[0].value}
      onSelect={handleSelect}
      errorMessage={error?.message}
      {...props}
    />
  );
}
