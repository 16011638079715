import { TDS, type TDSButtonProps } from '@/components/ui';

type EmailSignInButtonProps = TDSButtonProps;

export function EmailSignInButton({ ...props }: EmailSignInButtonProps) {
  return (
    <TDS.Button
      variant="outline"
      className="w-full rounded-3xl!"
      colorScheme="grey"
      size="md"
      {...props}
    >
      <i className="i-nc-email-logo size-4.5" />
      <TDS.Typo size="sm" weight="medium" className="flex-1">
        Continue with E-mail
      </TDS.Typo>
    </TDS.Button>
  );
}
