// TODO: character 관련 필드 schema에서 가져오기
export const MOOD_OPTIONS = [
  'powerful',
  'energetic',
  'cute',
  'playful',
  'classy',
  'warm',
  'calm',
  'trustful',
  'cold',
  'emotional',
  'etc',
] as const;

export const CONTENT_OPTIONS = [
  'documentary',
  'audiobook',
  'announcer',
  'radio',
  'ad',
  'education',
  'guide',
  'game',
  'music',
] as const;
