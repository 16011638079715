import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';
import { VoiceHubCharacter } from '@/types/character';
import { Review } from '@/types/review';

export type ErrorAlertProps = {
  review?: Review;
  status: VoiceHubCharacter['status'];
};

export function ErrorAlert({ review, status }: ErrorAlertProps) {
  return (
    <div className="bg-error rounded-2xl py-4 px-6 my-8 color-white">
      {status === 'failed' && <FailedAlert />}
      {status === 'rejected' && review && <RejectedAlert review={review} />}
    </div>
  );
}

function FailedAlert() {
  const { t } = useTranslation('characterFormPage');

  return (
    <>
      <div className="flex flex-row items-center justify-between mb-2">
        <TDS.Typo as="h5" weight="bold" size="md">
          {t(`components.error-alert.reject-title.failed`)}
        </TDS.Typo>
      </div>
      <div className="flex flex-row items-start">
        <div className="bg-white color-error rounded-xl p-3 flex-1">
          <TDS.Typo as="h6" weight="bold" className="flex flex-row">
            <div className="flex flex-row">
              <i className="i-t-warning text-6 mr-0.5" aria-label="warning" />
              {t(`components.error-alert.reject.audio`)}
            </div>
          </TDS.Typo>
          <ul className="ms-1.5 text-grey-700 mt-1">
            <li className="flex flex-row items-center gap-1.5">
              <span className="text-3.5 leading-100% select-none" aria-hidden>
                •
              </span>
              <TDS.Typo size="sm" color="grey.800" weight="medium">
                {t(
                  `components.error-alert.reject-detail.wrong_character_voice`,
                )}
              </TDS.Typo>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

function RejectedAlert({ review }: { review: Review }) {
  const { t } = useTranslation('characterFormPage');

  return (
    <>
      <div className="flex flex-row items-center justify-between mb-2">
        <TDS.Typo as="h5" weight="bold" size="md">
          {t(`components.error-alert.reject-title.rejected`)}
        </TDS.Typo>
      </div>
      <div className="flex flex-row items-start gap-4">
        {review.reject.audio.length > 0 && (
          <div className="bg-white color-error rounded-xl p-3 flex-1">
            <TDS.Typo as="h6" weight="bold" className="flex flex-row">
              <div className="flex flex-row">
                <i className="i-t-warning text-6 mr-0.5" aria-label="warning" />
                {t(`components.error-alert.reject.audio`)}
              </div>
            </TDS.Typo>
            <ul className="ms-1.5 text-grey-700 mt-1">
              {review.reject.audio.map(reason => (
                <li
                  className="flex flex-row items-center gap-1.5"
                  key={reason.message}
                >
                  <span
                    className="text-3.5 leading-100% select-none"
                    aria-hidden
                  >
                    •
                  </span>
                  <TDS.Typo size="sm" color="grey.800" weight="medium">
                    {t(
                      `components.error-alert.reject-detail.${reason.message.toLowerCase()}`,
                    )}
                    {reason.detail
                      ? ' (' +
                        reason.detail.map(d => t(`mood.${d}`)).join(',') +
                        ')'
                      : null}
                  </TDS.Typo>
                </li>
              ))}
            </ul>
          </div>
        )}
        {review.reject.image.length > 0 && (
          <div className="bg-white color-error rounded-xl p-3 flex-1">
            <TDS.Typo as="h6" weight="bold" className="flex flex-row">
              <div className="flex flex-row">
                <i className="i-t-warning text-6 mr-0.5" aria-label="warning" />
                {t(`components.error-alert.reject.image`)}
              </div>
            </TDS.Typo>
            <ul className="ms-1.5 text-grey-700 mt-1">
              {review.reject.image.map(reason => (
                <li
                  className="flex flex-row items-center gap-1.5"
                  key={reason.message}
                >
                  <span
                    className="text-3.5 leading-100% select-none"
                    aria-hidden
                  >
                    •
                  </span>
                  <TDS.Typo size="sm" color="grey.800" weight="medium">
                    {t(
                      `components.error-alert.reject-detail.${reason.message.toLowerCase()}`,
                    )}
                    {reason.detail
                      ? ' (' +
                        reason.detail.map(d => t(`mood.${d}`)).join(',') +
                        ')'
                      : null}
                  </TDS.Typo>
                </li>
              ))}
            </ul>
          </div>
        )}
        {review.reject.info.length > 0 && (
          <div className="bg-white color-error rounded-xl p-3 flex-1">
            <TDS.Typo as="h6" weight="bold" className="flex flex-row">
              <div className="flex flex-row">
                <i className="i-t-warning text-6 mr-0.5" aria-label="warning" />
                {t(`components.error-alert.reject.info`)}
              </div>
            </TDS.Typo>
            <ul className="ms-1.5 text-grey-700 mt-1">
              {review.reject.info.map(reason => (
                <li
                  className="flex flex-row items-center gap-1.5"
                  key={reason.message}
                >
                  <span
                    className="text-3.5 leading-100% select-none"
                    aria-hidden
                  >
                    •
                  </span>
                  <TDS.Typo size="sm" color="grey.800" weight="medium">
                    {t(
                      `components.error-alert.reject-detail.${reason.message.toLowerCase()}`,
                    )}
                    {reason.detail
                      ? ' (' +
                        reason.detail.map(d => t(`mood.${d}`)).join(',') +
                        ')'
                      : null}
                  </TDS.Typo>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
