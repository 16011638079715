import { HttpClient } from '@/libs/http-client';
import { Endpoint } from '@/libs/http-client/types';
import {
  Age,
  Content,
  Gender,
  VoiceHubCharacter,
  Mood,
  OriginalCharacter,
  Tone,
} from '@/types/character';
import { ISOLanguageCode2Digit, ISOLanguageCode3Digit } from '@/types/language';
import { Failed, Success } from '@/types/result';
import type {
  AudioReject,
  ImageReject,
  InfoReject,
  Review,
} from '@/types/review';
import { getExtFromFile } from '@/utils/file';
import { isNotNil } from '@/utils/guard';
import {
  toISOLanguageCode2Digit,
  toISOLanguageCode3Digit,
} from '@/utils/language';

import {
  PollingTestResponseDTO,
  PollingTestRequestDTO,
  CreateCharacterRequestDTO,
  CreateCharacterResponseDTO,
  UploadCharacterVoiceRequestDTO,
  UploadCharacterImageRequestDTO,
  UpdateCharacterDetailRequestDTO,
  StartTrainingRequestDTO,
  GetCharacterListResponseDTO,
  GetCharacterResponseDTO,
  GetCharacterReviewsResponseDTO,
  GetCharacterRequestDTO,
  RestartReviewRequestDTO,
  GetOriginalCharacterListResponseDTO,
  GetOriginalCharacterResponseDTO,
  GetIsDuplicatedCharacterNameRequestDTO,
  GetIsDuplicatedCharacterNameResponseDTO,
} from './types/voice-hub';

const baseURL = import.meta.env.VITE_VOICE_HUB_API_URL;
const httpClient = new HttpClient({ baseURL });

/**
 * marketplace API
 *
 * @see https://api.market-place.d.z2.neosapience.xyz/docs#
 */

/**
 * 캐릭터 조회
 */
export async function requestGetCharacterList() {
  try {
    const res = await httpClient.request<GetCharacterListResponseDTO>({
      url: '/api/v1/voicehub/characters',
      method: 'get',
    });

    return {
      isFailed: false,
      data: res.data?.map(toVoiceHubCharacter) ?? [],
    } as Success<VoiceHubCharacter[]>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}
export async function requestGetCharacter(payload: { id: string }) {
  try {
    const res = await httpClient.request<GetCharacterResponseDTO>(
      {
        url: `/api/v1/voicehub/characters/${payload.id}`,
        method: 'get',
      },
      {
        maker_character_id: payload.id,
      } satisfies GetCharacterRequestDTO,
    );

    return {
      isFailed: false,
      data: toVoiceHubCharacter(res.data),
    } as Success<VoiceHubCharacter>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

const toVoiceHubCharacter = (rawData: GetCharacterResponseDTO) => {
  const gender = rawData.gender === 'M' ? 'man' : 'woman';
  const language = toISOLanguageCode2Digit(
    rawData.language[0].toLowerCase() as ISOLanguageCode3Digit,
  );
  return {
    id: rawData._id,
    name: {
      ko: rawData.name,
      en: rawData.name_en ?? '',
    },
    language,
    gender,
    image: {
      casting: rawData.download_image,
      profile: rawData.thumbnail_image,
      video: rawData.video_image,
    },
    voice: {
      ext: rawData.character_data[0].ext,
      filename: rawData.character_data[0].filename,
    },
    description: rawData.styletag,
    age: convertToAge(rawData.age) as Age,
    tone: convertToTone(rawData.tag_v2.tone ?? '') as Tone,
    mood: (rawData.tag_v2.mood?.map(m => m.title) ?? []).map(
      convertToMood,
    ) as Mood[],
    content: (rawData.tag_v2.content ?? []).map(convertToContent) as Content[],
    sampleAudio: rawData.sample_audio ?? undefined,
    endDate: rawData.end_at ? new Date(rawData.end_at) : undefined,
    status: convertToStatus(rawData.status, rawData.release_status),
  } satisfies VoiceHubCharacter;
};

const convertToAge = (age: string): Age | undefined => {
  if (age === '아동') {
    return 'child';
  }
  if (age === '청소년') {
    return 'teenage';
  }
  if (age === '청년') {
    return 'youth';
  }
  if (age === '중년') {
    return 'middle_aged';
  }
  if (age === '장년') {
    return 'mature';
  }
};

const convertToTone = (tone: string): Tone | undefined => {
  if (tone === '저음') {
    return 'low';
  }
  if (tone === '중음') {
    return 'mid';
  }
  if (tone === '고음') {
    return 'high';
  }
};

const convertToMood = (mood: string): Mood | undefined => {
  if (mood === '힘 있는') {
    return 'powerful';
  }
  if (mood === '밝은') {
    return 'energetic';
  }
  if (mood === '귀여운') {
    return 'cute';
  }
  if (mood === '가벼운') {
    return 'playful';
  }
  if (mood === '세련된') {
    return 'classy';
  }
  if (mood === '따뜻한') {
    return 'warm';
  }
  if (mood === '차분한') {
    return 'calm';
  }
  if (mood === '신뢰감있는') {
    return 'trustful';
  }
  if (mood === '차가운') {
    return 'cold';
  }
  if (mood === '감성적인') {
    return 'emotional';
  }
  if (mood === '기타') {
    return 'etc';
  }
};

const convertToContent = (content: string): Content | undefined => {
  if (content === '다큐/리뷰') {
    return 'documentary';
  }
  if (content === '낭독/오디오북') {
    return 'audiobook';
  }
  if (content === '기자/아나운서') {
    return 'announcer';
  }
  if (content === '라디오/팟캐스트') {
    return 'radio';
  }
  if (content === '광고/이벤트') {
    return 'ad';
  }
  if (content === '교육/강의') {
    return 'education';
  }
  if (content === '안내음성/ARS') {
    return 'guide';
  }
  if (content === '게임/애니') {
    return 'game';
  }
  if (content === '음악/엔터테인먼트') {
    return 'music';
  }
};
// TODO: 로직 개선하기
const convertToStatus = (
  status: GetCharacterResponseDTO['status'],
  releaseStatus: GetCharacterResponseDTO['release_status'],
): VoiceHubCharacter['status'] => {
  if (status === 'INIT' || status === 'TRAINING') {
    return 'learning';
  }
  if (status === 'TRAINING_FAILED') {
    return 'failed';
  }
  if (status === 'TRAINING_COMPLETED' || status === 'DEPLOYING') {
    return 'judging';
  }
  if (
    releaseStatus === 'RELEASE_REQ_AVAILABLE' ||
    releaseStatus === 'REVIEW' ||
    releaseStatus === 'APPROVED'
  ) {
    return 'judging';
  }
  if (releaseStatus === 'RELEASED') {
    return 'complete';
  }
  if (releaseStatus === 'REJECTED') {
    return 'rejected';
  }
  if (releaseStatus === 'DEPRECATING') {
    return 'exiting';
  }
  if (releaseStatus === 'DEPRECATED') {
    return 'exited';
  }
  return 'exited';
};
/**
 * 리뷰 조회
 */
export async function requestGetCharacterReview(payload: { id: string }) {
  try {
    const res = await httpClient.request<GetCharacterReviewsResponseDTO>({
      url: `/api/v1/voicehub/characters/${payload.id}/reviews`,
      method: 'get',
    });

    return {
      isFailed: false,
      data: toReview(res.data),
    } as Success<Review | null>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}
const toReview = (rawData: GetCharacterReviewsResponseDTO) => {
  if (rawData.length < 1) {
    return null;
  }

  const recent = rawData[0];
  const status =
    recent.status === 'REJECTED'
      ? 'rejected'
      : recent.status === 'RELEASED'
        ? 'complete'
        : 'judging';

  return {
    id: recent._id,
    characterId: recent.character_id,
    name: {
      ko: recent.name,
      en: recent.name_en,
    },
    userName: recent.user_name,
    userEmail: recent.user_email,
    status: status,
    reject: recent.reject_reason.reduce(
      (reject, reason) => {
        if (reason.type === 'audio') {
          reject.audio.push({
            type: 'audio',
            message: reason.message,
            detail: reason.detail,
          } as AudioReject);
        }

        if (reason.type === 'info') {
          reject.info.push({
            type: 'info',
            message: reason.message,
            detail: reason.detail?.map(convertToMood),
          } as InfoReject);
        }

        if (reason.type === 'image') {
          reject.image.push({
            type: 'image',
            message: reason.message,
            detail: reason.detail,
          } as ImageReject);
        }

        return reject;
      },
      {
        audio: [] as AudioReject[],
        info: [] as InfoReject[],
        image: [] as ImageReject[],
      },
    ),
  } satisfies Review;
};
/**
 * 캐릭터 생성
 */
export async function requestCreateCharacter(payload: {
  name: {
    ko: string;
    en: string;
  };
  gender: 'man' | 'woman';
  language: ISOLanguageCode2Digit;
  description: string;
}) {
  try {
    const res = await httpClient.request<CreateCharacterResponseDTO>(
      {
        url: '/api/v1/voicehub/characters',
        method: 'post',
      },
      {
        name: payload.name.ko,
        name_en: payload.name.en,
        gender: payload.gender === 'man' ? 'M' : 'F',
        language: payload.language === 'ko' ? 'KOR' : 'ENG',
        styletag: payload.description,
        enhance: false,
        model_type: 'ssfm',
        platform: 'voicehub',
      } satisfies CreateCharacterRequestDTO,
    );

    return {
      isFailed: false,
      data: {
        id: res.data._id,
      },
    } as Success<{ id: string }>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

/**
 * 캐릭터 보이스 정보 업로드
 */
export async function requestUploadCharacterVoice(
  payload: UploadCharacterVoiceRequestDTO,
) {
  const { characterId, voice } = payload;
  const ext = getExtFromFile(voice)?.toLowerCase();
  try {
    const resPresignedUrl = await httpClient.request<{
      put_url: string;
      s3_key: string;
    }>(
      {
        url: `/api/v1/voicehub/characters/${characterId}/data/pre-signed-url`,
        method: 'get',
      },
      {
        maker_character_id: characterId,
        extension: ext,
      },
    );

    const { put_url, s3_key } = resPresignedUrl.data;

    await httpClient.request(
      {
        url: put_url,
        method: 'put',
      },
      voice,
    );

    await httpClient.request(
      {
        url: `/api/v1/voicehub/characters/${characterId}/data`,
        method: 'post',
      },
      {
        s3_key: s3_key,
        filename: voice.name,
      },
    );

    return {
      isFailed: false,
      data: undefined,
    } as Success<undefined>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}
/**
 * 캐릭터 이미지 정보 업로드
 */
export async function requestUploadImage(
  payload: UploadCharacterImageRequestDTO,
) {
  const { characterId, image, type } = payload;

  try {
    const resCastingImagePresignedUrl = await httpClient.request<{
      put_url: string;
      s3_key: string;
    }>(
      {
        url: `/api/v1/voicehub/characters/${characterId}/image/pre-signed-url`,
        method: 'get',
      },
      {
        maker_character_id: characterId,
        image_type: getExtFromFile(image),
        image_sort: type,
      },
    );

    await httpClient.request(
      {
        url: resCastingImagePresignedUrl.data.put_url,
        method: 'put',
      },
      image,
    );

    await httpClient.request(
      {
        url: `/api/v1/voicehub/characters/${characterId}/image`,
        method: 'post',
      },
      {
        s3_key: resCastingImagePresignedUrl.data.s3_key,
        image_sort: type,
      },
    );

    return {
      isFailed: false,
      data: undefined,
    } as Success<undefined>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

/**
 * 캐릭터 상세 정보 업데이트
 */
export async function requestUpdateCharacterDetail(payload: {
  characterId: string;
  name?: {
    ko?: string;
    en?: string;
  };
  gender?: Gender;
  language?: ISOLanguageCode2Digit;
  tone?: Tone;
  age?: Age;
  description?: string;
  content?: Content[];
  mood?: Mood[];
}) {
  const {
    characterId,
    name = {},
    gender,
    language,
    tone,
    mood = [],
    content = [],
    description,
    age,
  } = payload;

  try {
    await httpClient.request(
      {
        url: `/api/v1/voicehub/characters/${characterId}`,
        method: 'put',
      },
      {
        name: name.ko,
        name_en: name.en,
        styletag: description,
        gender: gender === 'man' ? 'M' : gender === 'woman' ? 'F' : undefined,
        language: language && toISOLanguageCode3Digit(language).toUpperCase(),
        tag_v2: {
          tone: toneToParams(tone),
          mood: mood
            .map(moodToParams)
            .filter(isNotNil)
            .map(m => ({ title: m, detail: [] })),
          content: content.map(contentToParams).filter(isNotNil),
        },
        age: ageToParams(age),
      } satisfies UpdateCharacterDetailRequestDTO,
    );

    return {
      isFailed: false,
      data: undefined,
    } as Success<undefined>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

const toneToParams = (tone?: Tone) => {
  if (tone === 'low') {
    return '저음';
  }
  if (tone === 'mid') {
    return '중음';
  }
  if (tone === 'high') {
    return '고음';
  }

  return undefined;
};

const moodToParams = (mood?: Mood) => {
  if (mood === 'powerful') {
    return '힘 있는';
  }
  if (mood === 'energetic') {
    return '밝은';
  }
  if (mood === 'cute') {
    return '귀여운';
  }
  if (mood === 'playful') {
    return '가벼운';
  }
  if (mood === 'classy') {
    return '세련된';
  }
  if (mood === 'warm') {
    return '따뜻한';
  }
  if (mood === 'calm') {
    return '차분한';
  }
  if (mood === 'trustful') {
    return '신뢰감있는';
  }
  if (mood === 'cold') {
    return '차가운';
  }
  if (mood === 'emotional') {
    return '감성적인';
  }
  if (mood === 'etc') {
    return '기타';
  }
  return undefined;
};

const contentToParams = (content?: Content) => {
  if (content === 'documentary') {
    return '다큐/리뷰';
  }
  if (content === 'audiobook') {
    return '낭독/오디오북';
  }
  if (content === 'announcer') {
    return '기자/아나운서';
  }
  if (content === 'radio') {
    return '라디오/팟캐스트';
  }
  if (content === 'ad') {
    return '광고/이벤트';
  }
  if (content === 'education') {
    return '교육/강의';
  }
  if (content === 'guide') {
    return '안내음성/ARS';
  }
  if (content === 'game') {
    return '게임/애니';
  }
  if (content === 'music') {
    return '음악/엔터테인먼트';
  }
  return undefined;
};

const ageToParams = (age?: Age) => {
  if (age === 'child') {
    return '아동';
  }
  if (age === 'teenage') {
    return '청소년';
  }
  if (age === 'youth') {
    return '청년';
  }
  if (age === 'middle_aged') {
    return '중년';
  }
  if (age === 'mature') {
    return '장년';
  }
  return undefined;
};
/**
 * 캐릭터 학습 시작
 */
export async function requestStartTraining(payload: StartTrainingRequestDTO) {
  const { characterId } = payload;
  try {
    await httpClient.request({
      url: `/api/v1/voicehub/characters/${characterId}/start`,
      method: 'get',
    });

    return {
      isFailed: false,
      data: undefined,
    } as Success<undefined>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

/**
 * 캐릭터 재심사 요청청
 */
export async function requestRestartReview(payload: {
  characterId: string;
  isAudioChange: boolean;
  isInfoChange: boolean;
  isImageChange: boolean;
}) {
  const {
    characterId,
    isAudioChange = false,
    isInfoChange = false,
    isImageChange = false,
  } = payload;

  const type: ('audio' | 'info' | 'image')[] = [];
  if (isAudioChange) {
    type.push('audio');
  }
  if (isInfoChange) {
    type.push('info');
  }
  if (isImageChange) {
    type.push('image');
  }
  if (type.length === 0) {
    type.push('info');
  }

  try {
    await httpClient.request(
      {
        url: `/api/v1/voicehub/characters/${characterId}/restart`,
        method: 'post',
      },
      {
        type,
      } satisfies RestartReviewRequestDTO,
    );
    return {
      isFailed: false,
      data: undefined,
    } as Success<undefined>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestPollingTest(
  payload: PollingTestRequestDTO,
  shouldStopPolling: (data: PollingTestResponseDTO) => boolean,
) {
  const endPoint: Endpoint = {
    url: '/user',
    method: 'get',
    noAuth: true,
  };

  return httpClient.pollingRequest<PollingTestResponseDTO>(
    endPoint,
    payload,
    1000,
    10,
    shouldStopPolling,
  );
}

export async function requestDeleteCharacter(payload: { id: string }) {
  const { id } = payload;
  try {
    await httpClient.request({
      url: `/api/v1/voicehub/characters/${id}`,
      method: 'delete',
    });

    return {
      isFailed: false,
      data: undefined,
    } as Success<undefined>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetOriginalCharacterList() {
  try {
    const res = await httpClient.request<GetOriginalCharacterListResponseDTO>({
      url: '/api/v1/voicehub/characters/original',
      method: 'get',
    });

    return {
      isFailed: false,
      data: res.data?.map(toOriginalCharacter) ?? [],
    } as Success<OriginalCharacter[]>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

const toOriginalCharacter = (
  rawData: GetOriginalCharacterResponseDTO,
): OriginalCharacter => {
  return {
    id: rawData._id,
    name: {
      ko: rawData.name,
      en: rawData.name_en,
    },
    language: rawData.language.split('-').shift() as ISOLanguageCode2Digit,
    content: (rawData.tag_v2.content ?? []) as Content[],
    image: {
      casting: rawData.img_url,
    },
    endAt: new Date(rawData.end_at),
    sampleUrl: rawData.sample_url,
    incentiveRate: rawData.incentive_rate,
  };
};

export async function requestGetIsDuplicatedCharacterName(payload: {
  nameKo?: string;
  nameEn?: string;
}) {
  try {
    const res =
      await httpClient.request<GetIsDuplicatedCharacterNameResponseDTO>(
        {
          url: '/api/v1/voicehub/characters/check-name',
          method: 'get',
        },
        {
          name: payload.nameKo,
          name_en: payload.nameEn,
        } satisfies GetIsDuplicatedCharacterNameRequestDTO,
      );

    return {
      isFailed: false,
      data: res.data.exists,
    } as Success<boolean>;
  } catch (err) {
    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}
