import { Flex } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';
import { useSettleStore } from '@/stores/settle';

type SettlementStatusProps = {
  isFormComplete: boolean;
  isLoading: boolean;
  onOpenSettlementInputModal: () => void;
};

export function SettlementStatus({
  isFormComplete: _isFormComplete,
  isLoading,
  onOpenSettlementInputModal,
}: SettlementStatusProps) {
  const { t } = useTranslation('settlePage');
  const { isFormComplete: storeIsFormComplete } = useSettleStore(
    useShallow(state => ({
      isFormComplete: state.isFormComplete,
    })),
  );

  const isFormComplete = storeIsFormComplete || _isFormComplete;

  return (
    <Flex
      w="full"
      p="16px 24px"
      borderRadius={10}
      justifyContent="space-between"
      alignItems="center"
      {...(isFormComplete
        ? { bgColor: 'grey.50', border: 'none' }
        : {
            bgColor: 'orange.50',
            border: '1px solid',
            borderColor: 'orange.300',
          })}
    >
      {isFormComplete ? (
        <TDS.Typo as="h5" size="lg" weight="medium" color="grey.900">
          {t('info-status.form-complete.header')}
        </TDS.Typo>
      ) : (
        <Flex flexDirection="column">
          <TDS.Typo as="h5" size="lg" weight="medium" color="grey.900">
            {t('info-status.form-ready.header')}
          </TDS.Typo>
          <TDS.Typo as="p" size="sm" weight="normal" color="grey.900">
            {t('info-status.form-ready.body')}
          </TDS.Typo>
        </Flex>
      )}
      <TDS.Button
        isLoading={isLoading}
        size="md"
        variant="solid"
        onClick={onOpenSettlementInputModal}
      >
        {isFormComplete ? t('modify') : t('enter')}
      </TDS.Button>
    </Flex>
  );
}
