import { jwtDecode } from 'jwt-decode';

import { PROVIDER } from './constants';
import type { CustomTokenType, OAuthProvider, Provider } from './types';

export const isGoogleProvider = (
  provider: Provider,
): provider is typeof PROVIDER.GOOGLE => {
  return provider === PROVIDER.GOOGLE;
};

export const isFacebookProvider = (
  provider: Provider,
): provider is typeof PROVIDER.FACEBOOK => {
  return provider === PROVIDER.FACEBOOK;
};

export const isEmailProvider = (
  provider: Provider,
): provider is typeof PROVIDER.EMAIL => {
  return provider === PROVIDER.EMAIL;
};

export const isOAuthProvider = (
  provider: Provider,
): provider is OAuthProvider => {
  return [PROVIDER.GOOGLE, PROVIDER.FACEBOOK].some(p => p === provider);
};

/**
 * ?THINK 타입제네릭하게 수정하는게 나을까?
 * @param token
 * @returns
 */
export const isCustomToken = (token: string): boolean => {
  const parsedToken = jwtDecode<CustomTokenType>(token);
  return !!parsedToken._id;
};
