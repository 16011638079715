import { Howl, Howler } from 'howler';

type AudioPlayerEvent =
  | 'play'
  | 'end'
  | 'pause'
  | 'stop'
  | 'mute'
  | 'volume'
  | 'rate'
  | 'seek'
  | 'fade'
  | 'unlock'
  | 'time'
  | 'error';
export type EventHandlers = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in AudioPlayerEvent]?: (...args: any[]) => void;
};

/**
 * testPlayForAudioAutoTriggerIssue
 * 해당 함수는 AudioPlayer의 auto trigger 이슈를 테스트하기 위한 함수입니다.
 * 기본적으로 브라우저는 사용자의 인터렉션 없이 play 하면 소리가 나지 않습니다.
 * 이 부분을 해결 하기 위해 mute와 volume을 설정하고 play를 한번 호출하여 이슈를 확인합니다.
 */
export const testPlayForAudioAutoTriggerIssue = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const testPlayer = new AudioPlayer();
  testPlayer.set('mute', true);
  testPlayer.set('volume', 0);
  testPlayer.on({ play: () => testPlayer.stop() });
  testPlayer.play('/void.mp3');
};

export class AudioPlayer {
  #url: string = '';
  #howl: Howl | undefined;
  #runningTimerId: ReturnType<typeof setTimeout> | undefined;
  #onTimerCallback: (time: number, duration: number) => void = () => {};
  #onErrorCallback: (errorMessage: string) => void = () => {};

  #init(audioUrl: string) {
    this.#url = audioUrl;
    this.#howl = this.#createHowlInstance();
  }

  #createHowlInstance() {
    return new Howl({
      src: [this.#url],
      html5: true,
      onplay: () => this.#timerStart(),
      onstop: () => this.#unload(),
      onend: () => this.#unload(),
      onloaderror: error =>
        this.handleError(`AudioPlayer Load Error: ${error}`),
      onplayerror: error =>
        this.handleError(`AudioPlayer Onplay Error: ${error}`),
    });
  }

  handleError(errorMessage: string) {
    this.#unload();
    this.#onErrorCallback(errorMessage);
  }

  #unload() {
    this.#howl?.unload();
    this.clearTimer();
  }

  play(audioUrl: string, eventHandlers: EventHandlers = {}) {
    this.stop();
    this.#init(audioUrl);
    this.on(eventHandlers);
    this.#howl?.play();
  }

  playSeek(sec: number = 0) {
    this.#howl?.seek(sec);
  }

  isPlaying() {
    return this.#howl?.playing() || false;
  }

  #timerStart() {
    this.#runningTimerId = setInterval(() => {
      const time = this.#howl?.seek() || 0;
      const duration = this.#howl?.duration() || 0;
      this.#onTimerCallback(time, duration);
    }, 100);
  }

  clearTimer() {
    this.#onTimerCallback(0, 0);
    this.#onTimerCallback = () => {};
    clearInterval(this.#runningTimerId);
    this.#runningTimerId = undefined;
  }

  stop() {
    Howler.stop();
    Howler.unload();
  }

  on(eventHandlers: EventHandlers) {
    if (!this.#howl || !eventHandlers) {
      return;
    }
    for (const event of Object.keys(eventHandlers) as (keyof EventHandlers)[]) {
      if (eventHandlers[event]) {
        if (event === 'time') {
          this.#onTimerCallback = eventHandlers[event]!;
        } else if (event === 'error') {
          this.#onErrorCallback = eventHandlers[event]!;
        } else {
          this.#howl.on(event, eventHandlers[event]!);
        }
      }
    }
  }

  set(event: 'mute' | 'volume', value: unknown) {
    // NOTE: howler의 설정을 변경하는 경우 사용
    if (!this.#howl) {
      return;
    }

    this.#howl[event](value as boolean);
  }
}
