import * as Sentry from '@sentry/react';
import '@unocss/reset/tailwind.css';
// eslint-disable-next-line import/no-unresolved
import 'unfonts.css';
// eslint-disable-next-line import/no-unresolved
import 'virtual:uno.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { isProductionMode } from '@/utils/env.ts';

import { AuthenticationProvider } from './contexts/providers/authentication-provider.tsx';
import { HotjarProvider } from './contexts/providers/hotjar.tsx';
import { ScreenProvider } from './contexts/providers/screen-provider.tsx';
import './i18n';
import { ThemeProvider, theme } from './libs/chakra';
import { testPlayForAudioAutoTriggerIssue } from './libs/player/audio.ts';
import './main.css';
import { routes } from './router.tsx';

Sentry.init({
  dsn: 'https://30de7753690d3dc67f4d6d0043b0cd7b@o354244.ingest.us.sentry.io/4507564790841344',
  environment: import.meta.env.MODE,
  release: import.meta.env.VITE_CIRCLE_TAG,
  enableTracing: false,
  tracesSampleRate: 0.05,
});

const router = createBrowserRouter(routes);
testPlayForAudioAutoTriggerIssue();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AuthenticationProvider>
    <ScreenProvider>
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <RouterProvider router={router} />
          {isProductionMode() && <HotjarProvider />}
        </React.StrictMode>
      </ThemeProvider>
    </ScreenProvider>
  </AuthenticationProvider>,
);
