import { Spacer } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import CHARACTER_BACKGROUND from '@/assets/images/character-background.svg';
import defaultCastingImage from '@/assets/images/default-casting-card.png';
import { TDS } from '@/libs/chakra';
import { VoiceHubCharacter } from '@/types/character';
import { dayjs } from '@/utils/time';

import {
  CharacterCard,
  CharacterCardBody,
  CharacterCardHeader,
  CharacterCardImage,
  CharacterCardInfo,
  type CharacterCardProps,
} from './character-card';

type VoiceHubCharacterCardProps = CharacterCardProps & {
  character: VoiceHubCharacter;
};
export function VoiceHubCharacterCard(props: VoiceHubCharacterCardProps) {
  const { character, ...rest } = props;
  const { t } = useTranslation('common');

  return (
    <CharacterCard {...rest}>
      <CharacterCardHeader status={getTheme(character.status)}>
        {character.status === 'learning' && <LearningHeaderContent />}
        {character.status === 'failed' && <FailedHeaderContent />}
        {character.status === 'judging' && <JudgingHeaderContent />}
        {character.status === 'rejected' && <RejectedHeaderContent />}
        {character.status === 'complete' && <CompleteHeaderContent />}
        {character.status === 'exiting' && character.endDate && (
          <ExitingHeaderContent endDate={character.endDate} />
        )}
        {character.status === 'exited' && <ExitedHeaderContent />}
      </CharacterCardHeader>
      <CharacterCardBody>
        <CharacterCardImage
          backgroundUrl={CHARACTER_BACKGROUND}
          characterUrl={character.image.casting ?? defaultCastingImage}
          characterAlt="default character casting image"
        ></CharacterCardImage>
        <CharacterCardInfo
          name={character.name.ko}
          language={character.language}
          content={t(`content.${character.content[0]}`)}
          sampleUrl={character.sampleAudio}
        ></CharacterCardInfo>
      </CharacterCardBody>
    </CharacterCard>
  );
}

const getTheme = (status: VoiceHubCharacter['status']) => {
  if (status === 'failed' || status === 'rejected') {
    return 'error';
  }
  if (status === 'judging' || status === 'learning') {
    return 'info';
  }
  if (status === 'complete' || status === 'exiting') {
    return 'grey';
  }
  return 'black';
};

function LearningHeaderContent() {
  return (
    <>
      <i className="i-nc-spinner-medium text-6 animate-spin mr-1" />
      <TDS.Typo size="sm" weight="bold">
        학습 중
      </TDS.Typo>
    </>
  );
}

function FailedHeaderContent() {
  return (
    <>
      <i className="i-t-warning text-6 mr-1" />
      <TDS.Typo size="sm" weight="bold">
        학습 실패
      </TDS.Typo>
      <Spacer></Spacer>
      <TDS.Button variant="outline" colorScheme="whiteAlpha" size="xs">
        사유확인
      </TDS.Button>
    </>
  );
}

function JudgingHeaderContent() {
  return (
    <>
      <i className="i-nc-spinner-medium text-6 animate-spin mr-1" />
      <TDS.Typo size="sm" weight="bold">
        출시 심사 중
      </TDS.Typo>
    </>
  );
}

function RejectedHeaderContent() {
  return (
    <>
      <i className="i-t-warning text-6 mr-1" />
      <TDS.Typo size="sm" weight="bold">
        출시 심사 반려
      </TDS.Typo>
      <Spacer></Spacer>
      <TDS.Button variant="outline" colorScheme="whiteAlpha" size="xs">
        사유확인
      </TDS.Button>
    </>
  );
}
function CompleteHeaderContent() {
  return (
    <TDS.Typo size="sm" weight="bold">
      출시 완료
    </TDS.Typo>
  );
}

function ExitingHeaderContent({ endDate }: { endDate: Date }) {
  return (
    <TDS.Typo size="sm" weight="bold">
      {dayjs(endDate).format('YYYY년 MM월 DD일')}에 출시 종료됩니다.
    </TDS.Typo>
  );
}

function ExitedHeaderContent() {
  return (
    <TDS.Typo size="sm" weight="bold">
      출시 종료
    </TDS.Typo>
  );
}
