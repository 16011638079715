import { Flex } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';
import { useSettleStore } from '@/stores/settle';

type SettlementFailureFeedbackProps = {
  isFormComplete: boolean;
  isLoading: boolean;
  feedback: string | null;
  onOpenSettlementInputModal: () => void;
};

export function SettlementFailureFeedback({
  isFormComplete: _isFormComplete,
  feedback,
  isLoading,
  onOpenSettlementInputModal,
}: SettlementFailureFeedbackProps) {
  const { t } = useTranslation('settlePage');
  const { isFormComplete: storeIsFormComplete } = useSettleStore(
    useShallow(state => ({
      isFormComplete: state.isFormComplete,
    })),
  );

  const isFormComplete = storeIsFormComplete || _isFormComplete;

  if (!feedback) return <></>;

  return (
    <Flex
      px={4}
      border="1px solid"
      borderColor="blue.500"
      borderRadius={10}
      alignItems="center"
    >
      <i className="i-t-info-small text-5 mr-1 bg-grey-900" />
      <TDS.Typo
        as="span"
        size="sm"
        color="black"
        mr={2}
        h={9}
        display="flex"
        alignItems="center"
      >
        {feedback}
      </TDS.Typo>
      <TDS.Button
        isLoading={isLoading}
        variant="link"
        size="sm"
        fontWeight="semibold"
        color="orange.500"
        cursor="pointer"
        h="min-content"
        padding={0}
        onClick={onOpenSettlementInputModal}
      >
        {isFormComplete ? t('modify') : t('enter')}
      </TDS.Button>
    </Flex>
  );
}
