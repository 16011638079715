import { HttpClient } from '..';
import { Endpoint, Payload } from '../types';
import { delay } from '../utils';

export class PollingManager<T> {
  #httpClient: HttpClient;
  #endpoint: Endpoint;
  #payload: Payload;
  #delayTime: number;
  #maxAttemptsCount: number;
  #shouldStopPolling: (data: T) => boolean;
  #attempts: number = 0;
  #stopPolling: boolean = false;

  constructor(
    httpClient: HttpClient,
    endpoint: Endpoint,
    payload: Payload,
    delayTime: number = 300,
    maxAttemptsCount: number = Infinity,
    shouldStopPolling: (data: T) => boolean = () => false,
  ) {
    this.#httpClient = httpClient;
    this.#endpoint = endpoint;
    this.#payload = payload;
    this.#delayTime = delayTime;
    this.#maxAttemptsCount = maxAttemptsCount;
    this.#shouldStopPolling = shouldStopPolling;
  }

  async #pollFunction(): Promise<T | undefined> {
    if (this.#attempts >= this.#maxAttemptsCount) {
      throw new Error('Max attempts count exceeded');
    }

    const response = await this.#httpClient.request<T>(
      this.#endpoint,
      this.#payload,
    );

    if (this.#shouldStopPolling(response.data)) {
      return response.data;
    }

    this.#attempts++;
    await delay(this.#delayTime);

    if (this.#stopPolling) {
      return undefined;
    }

    /**
     * NOTE: 재귀함수라서 "Maximum call stack size exceeded" 에러가 발생할 수 있지만 상단에
     * await 를 사용하면서 event queue 에 대기 하고 있다가
     * callstack이 비워졌을대 실행 되므로 에러가 발생하지 않습니다.
     */
    return this.#pollFunction();
  }

  async start(): Promise<T | undefined> {
    this.#stopPolling = false;
    this.#attempts = 0;

    return this.#pollFunction();
  }

  stop() {
    this.#stopPolling = true;
  }
}
