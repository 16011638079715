import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';
import { CharacterProfit } from '@/types/profits';
import { formatToKRW } from '@/utils/format-currency';

export function SettlementDetailModal({
  isOpen,
  onClose,
  detailValues: characterProfits,
  totalRevenue,
  period,
  title,
}: {
  isOpen: boolean;
  period: string;
  onClose: () => void;
  detailValues?: CharacterProfit[];
  title: string;
  totalRevenue: number;
}) {
  const { t } = useTranslation('settlePage');
  const initialRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        rounded="2xl"
        width="full"
        maxWidth={480}
        marginX={5}
        paddingX={6}
      >
        <ModalHeader fontSize="lg" fontWeight="bold" paddingY={4} paddingX={0}>
          {title}
        </ModalHeader>
        <ModalBody paddingY={2} paddingX={0}>
          <Flex flexDir="column" rowGap={2.5}>
            <TDS.Typo as="p" size="md" color="grey.700">
              {t('table.detail-modal.period')}: {period}
            </TDS.Typo>
            <SettledTable
              totalRevenue={totalRevenue}
              characterProfits={characterProfits}
            />
          </Flex>
        </ModalBody>
        <ModalFooter paddingY={4} paddingX={0}>
          <TDS.Button
            size="md"
            colorScheme="grey"
            onClick={onClose}
            ref={initialRef}
          >
            {t('close')}
          </TDS.Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function SettledTable({
  characterProfits,
  totalRevenue,
}: {
  characterProfits?: CharacterProfit[];
  totalRevenue: number;
}) {
  const { t } = useTranslation('settlePage');
  const theadKeys = [
    t('table.detail-modal.name'),
    t('table.detail-modal.revenue'),
  ] as const;

  return (
    <TableContainer borderRadius={10} border="1px solid" borderColor="grey.700">
      <Table>
        <Thead bgColor="grey.700">
          <Tr>
            {theadKeys.map((key, i) => (
              <Th isNumeric={i !== 0} key={key} p="11px 24px">
                <TDS.Typo size="xs" weight="bold" color="white">
                  {key}
                </TDS.Typo>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody bgColor="white">
          <TbodyContent characterProfits={characterProfits} />
        </Tbody>
        <Tfoot bgColor="grey.150">
          <Tr>
            <Th p="9.5px 24px" letterSpacing={0}>
              <TDS.Typo size="sm" weight="medium" color="black">
                {t('table.detail-modal.total')}
              </TDS.Typo>
            </Th>
            <Th p="9.5px 24px" letterSpacing={0} isNumeric>
              <TDS.Typo size="sm" weight="normal" color="black">
                {formatToKRW(totalRevenue)}원
              </TDS.Typo>
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}

function TbodyContent({
  characterProfits,
}: {
  characterProfits?: CharacterProfit[];
}) {
  if (!characterProfits)
    return (
      <>
        {Array.from({ length: 3 }).map((_, i, t) => (
          <Tr key={`SettlementDetailModal_Tbody_${i}`}>
            <Td
              p="9.5px 24px"
              borderColor={i === t.length - 1 ? 'grey.900' : 'grey.150'}
            >
              <Flex flexDirection="column">
                <Skeleton h={17} w={140} rounded={3} />
              </Flex>
            </Td>
            <Td
              p="9.5px 24px"
              borderColor={i === t.length - 1 ? 'grey.900' : 'grey.150'}
            >
              <Flex>
                <Spacer />
                <Skeleton h={17} w={58} rounded={3} />
              </Flex>
            </Td>
          </Tr>
        ))}
      </>
    );

  return (
    <>
      {characterProfits.map((profit, i) => (
        <Tr key={`SettlementDetailModal_Tbody_${i}`}>
          <Td
            p="9.5px 24px"
            borderColor={
              i === characterProfits.length - 1 ? 'grey.900' : 'grey.150'
            }
          >
            <Flex flexDirection="column">
              <TDS.Typo size="sm" weight="medium" color="black">
                {profit.name}
                {/* {tbodyValue.isOriginal && ' (오리지널 캐릭터)'} */}
              </TDS.Typo>
            </Flex>
          </Td>
          <Td
            p="9.5px 24px"
            isNumeric
            borderColor={
              i === characterProfits.length - 1 ? 'grey.900' : 'grey.150'
            }
          >
            <TDS.Typo size="sm" weight="normal" color="grey.700">
              {formatToKRW(profit.profit_amount_krw)}원
            </TDS.Typo>
          </Td>
        </Tr>
      ))}
    </>
  );
}
