import { Provider } from '@/libs/auth';
import { HttpClient } from '@/libs/http-client';
import type { Endpoint } from '@/libs/http-client/types';
import { Failed, Success } from '@/types/result';

import type {
  GetCustomTokenRequestDTO,
  GetCustomTokenResponseDTO,
  GetIsDuplicatedCharacterNameResponseDTO,
  GetIsDuplicatedCharacterNameRequestDTO,
  GetIsExistUserRequestDTO,
  GetIsExistUserResponseDTO,
  GetProvidersResponseDTO,
  GetProvidersResquestDTO,
  ResetPasswordRequestDTO,
} from './types/typecast';

const baseURL = import.meta.env.VITE_TYPECAST_API_URL;
const httpClient = new HttpClient({ baseURL });

/**
 * typecast API
 *
 * @see https://create-test.icepeak.ai/api/doc
 */
export async function requestGetIsExistUser(payload: {
  provider: Provider;
  providerUid?: string;
  idToken?: string;
}) {
  try {
    const res = await httpClient.request<GetIsExistUserResponseDTO>(
      {
        url: `/api/auth-fb/v2/exists`,
        method: 'get',
        noAuth: true,
      },
      {
        provider: payload.provider,
        provider_uid: payload.providerUid,
        id_token: payload.idToken,
      } satisfies GetIsExistUserRequestDTO,
    );

    return {
      isFailed: false,
      data: res.data.exists,
    } as Success<boolean>;
  } catch (err) {
    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetProviders(payload: { email: string }) {
  try {
    const res = await httpClient.request<GetProvidersResponseDTO>(
      {
        url: '/api/auth-fb/v2/provider',
        method: 'get',
        noAuth: true,
      },
      {
        email: encodeURIComponent(payload.email),
      } satisfies GetProvidersResquestDTO,
    );

    return {
      isFailed: false,
      data: res.data.providers,
    } as Success<string[]>;
  } catch (err) {
    console.error(err);

    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetCustomToken(payload: { token: string }) {
  try {
    const res = await httpClient.request<GetCustomTokenResponseDTO>(
      {
        url: '/api/auth-fb/custom-token',
        method: 'post',
        noAuth: true,
      },
      {
        token: payload.token,
      } satisfies GetCustomTokenRequestDTO,
    );

    return {
      isFailed: false,
      data: {
        accessToken: res.data.access_token,
        status: res.data.status,
        guardianEmail: res.data.guardian_email,
      },
    } as Success<{
      accessToken: string;
      status: string;
      guardianEmail: string;
    }>;
  } catch (err) {
    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

export async function requestGetActor() {
  const endPoint: Endpoint = {
    url: '/api/actor',
    method: 'get',
  };
  return await httpClient.request(endPoint, { role: 'prosody' });
}

export async function requestGetIsDuplicatedCharacterName(payload: {
  nameKo?: string;
  nameEn?: string;
}) {
  try {
    const endPoint: Endpoint = {
      url: '/api/actor/exists',
      method: 'get',
    };

    const res =
      await httpClient.request<GetIsDuplicatedCharacterNameResponseDTO>(
        endPoint,
        {
          'name-ko': payload.nameKo,
          'name-en': payload.nameEn,
        } satisfies GetIsDuplicatedCharacterNameRequestDTO,
      );

    return {
      isFailed: false,
      data: res.data.exists,
    } as Success<boolean>;
  } catch (err) {
    return {
      isFailed: true,
      error: err as Error,
    } as Failed<Error>;
  }
}

// TODO: try catch로 감싸기
export async function requestResetPassword(payload: ResetPasswordRequestDTO) {
  const endPoint: Endpoint = {
    url: `/api/auth-fb/email/reset-password`,
    method: 'post',
    noAuth: true,
  };

  return await httpClient.request(endPoint, { email: payload.email });
}
