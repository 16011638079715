import { useUserStore } from '@/stores/user';

export const useAuth = () => {
  const { isAuthorized, isSigned } = useUserStore(state => ({
    isAuthorized: state.isAuthorized,
    isSigned: state.isSigned,
  }));

  return {
    isAuthorized,
    isSigned,
  };
};
