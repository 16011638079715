import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';

import { inputChange } from '../utils/input-change';
import { inputPreventPaste } from '../utils/input-prevent-paste';

export function SettlementAccountNumberField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();

  const fieldId = `${useId()}-account-number-field`;
  const {
    field: { name, value, onChange, ref },
  } = useController({
    control,
    name: 'accountNumber',
    rules: { required: true },
  });

  const isValid = value.number === '';

  return (
    <FormControl isInvalid={isValid}>
      <FormLabel htmlFor={fieldId}>
        {t('input-modal.account-number-field.label')}
      </FormLabel>
      <Input
        id={fieldId}
        bg="white"
        rounded={'lg'}
        className="flex-1 leading-38px invalid:bg-red-50"
        placeholder={t('input-modal.account-number-field.placeholder')}
        _invalid={{
          bg: 'red.50',
          border: '1px solid',
          borderColor: 'red.500',
        }}
        name={name}
        value={value.number ?? ''}
        onPaste={inputPreventPaste}
        onChange={e =>
          inputChange(e, (number: string) => {
            onChange({
              ...value,
              ...{ number: number },
            });
          })
        }
        ref={ref}
      />
      <FormErrorMessage>
        <TDS.Typo color="error.500" size="xs" weight="medium">
          {/* 계좌번호가 서로 일치하지 않는 경우에 에러를 하나만 띄워서 따로 에러처리를 넣어주었습니다. */}
          {isValid && t('error.account-number-min')}
        </TDS.Typo>
      </FormErrorMessage>
    </FormControl>
  );
}
