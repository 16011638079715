import { useController, useFormContext } from 'react-hook-form';

import { PasswordForm } from '@/components/form/password-form';

type PasswordFieldProps = {
  disabled?: boolean;
  className?: string;
  label?: string;
};

export function PasswordField({
  className,
  disabled,
  label,
  ...props
}: PasswordFieldProps) {
  const { control } = useFormContext();
  const {
    field: { name, value, onChange, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'password',
    rules: { required: true },
    disabled,
  });
  const passwordFieldId = `${useId()}-password-field`;

  return (
    <PasswordForm
      id={passwordFieldId}
      label={label}
      isInvalid={invalid}
      isDisabled={disabled}
      placeholder="********"
      name={name}
      value={value}
      onChange={onChange}
      ref={ref}
      errorMessage={error?.message}
      className={className}
      {...props}
    />
  );
}
