import { useToast } from '@/components/ui';
import { AudioPlayer, EventHandlers } from '@/libs/player/audio';

type useAudioPlayerProps = {
  onPlay?: () => void;
  onStop?: () => void;
  onEnd?: () => void;
};

export const useAudioPlayer = (props?: useAudioPlayerProps) => {
  const { onPlay, onStop, onEnd } = props || {};
  const player = useRef<AudioPlayer>(new AudioPlayer());
  const onEvent = useRef<EventHandlers>();
  const [isPlaying, setPlaying] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isStopped, setStopped] = useState(true);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(1);

  const play = (url: string) => {
    setLoading(true);
    setStopped(false);
    player.current?.play(url, onEvent.current);
  };

  const stop = () => {
    player.current?.stop();
  };

  const playSeek = (sec: number) => {
    player.current?.playSeek(sec);
  };

  const timeReset = () => {
    setTime(0);
    setDuration(1);
  };

  //FIXME: errorToast 재 사용 및 외부에서 처리하게 ..
  const toast = useToast();
  const errorToast = (props: { description: string }) => {
    toast({
      title: 'Error',
      description: props.description,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    onEvent.current = {
      play: () => {
        setPlaying(true);
        setLoading(false);
        onPlay?.();
      },
      stop: () => {
        setPlaying(false);
        setStopped(true);
        onStop?.();
      },
      end: () => {
        setPlaying(false);
        setStopped(true);
        setTime(0);
        setDuration(1);
        onEnd?.();
      },
      time: (time, duration) => {
        setTime(time);
        setDuration(duration === 0 ? 1 : duration);
      },
      error: errorMessage => {
        setLoading(false);
        setPlaying(false);
        setStopped(true);
        //FIXME: onError 를 통해서 외부에서 처리 하게 해주는게 좋을지 생각해볼 필요가 있음
        errorToast({
          description: errorMessage,
        });
      },
    };
  }, []);

  return {
    isPlaying,
    isLoading,
    isStopped,
    time,
    duration,
    play,
    stop,
    playSeek,
    timeReset,
  };
};
