import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';

export function SettlementPhoneNumberField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();

  const fieldId = `${useId()}-phone-number-field`;
  const {
    field: { name, value, onChange: onChangeName, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'phoneNumber',
    rules: { required: true },
  });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel htmlFor={fieldId}>
        {t('input-modal.phone-number-field.label')}
      </FormLabel>
      <Input
        type="tel"
        id={fieldId}
        bg="white"
        rounded={'lg'}
        className="flex-1 leading-38px invalid:bg-red-50"
        placeholder={t('input-modal.phone-number-field.placeholder')}
        _invalid={{
          bg: 'red.50',
          border: '1px solid',
          borderColor: 'red.500',
        }}
        name={name}
        value={value ?? ''}
        onChange={onChangeName}
        ref={ref}
      />
      {error?.message && (
        <FormErrorMessage>
          <TDS.Typo color="error.500" size="xs" weight="medium">
            {error.message}
          </TDS.Typo>
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
