import { TDS } from '@/components/ui';
import { AccountManager, FacebookSignIn, type Token } from '@/libs/auth';

type FacebookSignInButtonProps = {
  onBeforeSignIn?: () => void;
  onSignInError?: (err: Error) => void;
  onAfterSignIn?: (isSuccess: boolean) => void;
};

export function FacebookSignInButton({
  onBeforeSignIn,
  onSignInError,
  onAfterSignIn,
}: FacebookSignInButtonProps) {
  const handleSignInFacebook = async () => {
    let isSuccess = true;
    try {
      onBeforeSignIn && onBeforeSignIn();
      const facebookCredential = await FacebookSignIn.facebookSignIn();
      const { accessToken } = facebookCredential.authResponse;

      await AccountManager.signInFacebook({
        token: accessToken as Token,
      });
    } catch (err) {
      isSuccess = false;
      onSignInError && onSignInError(err as Error);
    } finally {
      onAfterSignIn && onAfterSignIn(isSuccess);
    }
  };

  return (
    <TDS.Button
      variant="outline"
      className="w-full rounded-3xl!"
      colorScheme="grey"
      size="md"
      onClick={handleSignInFacebook}
    >
      <i className="i-nc-facebook-logo size-4.5" />
      <TDS.Typo size="sm" weight="medium" className="flex-1">
        Continue with Facebook
      </TDS.Typo>
    </TDS.Button>
  );
}
