import { TDS } from '@/components/ui';
import { OriginalCharacter } from '@/types/character';

import {
  CharacterCard,
  CharacterCardBody,
  CharacterCardHeader,
  CharacterCardImage,
  CharacterCardInfo,
  type CharacterCardProps,
} from './character-card';

type OriginalCharacterCardProps = CharacterCardProps & {
  character: OriginalCharacter;
};
export function OriginalCharacterCard(props: OriginalCharacterCardProps) {
  const { character, ...rest } = props;

  return (
    <CharacterCard {...rest}>
      <CharacterCardHeader status="grey">
        <div className="flex flex-row justify-between w-full">
          <TDS.Typo size="sm" weight="bold">
            출시 완료
          </TDS.Typo>
          <TDS.Typo size="sm" weight="bold">
            {`로열티: ${character.incentiveRate}%`}
          </TDS.Typo>
        </div>
      </CharacterCardHeader>
      <CharacterCardBody>
        <CharacterCardImage
          characterUrl={character.image.casting}
          characterAlt={character.name.ko}
        ></CharacterCardImage>
        <CharacterCardInfo
          name={character.name.ko}
          language={character.language}
          content={character.content[0]}
          sampleUrl={character.sampleUrl}
        ></CharacterCardInfo>
      </CharacterCardBody>
    </CharacterCard>
  );
}
