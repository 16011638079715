const BLACK = '#000000';
const WHITE = '#ffffff';
const WHITE_ALPHA = {
  50: '#ffffff0a',
  100: '#ffffff0f',
  200: '#ffffff14',
  300: '#ffffff29',
  400: '#ffffff3d',
  500: '#ffffff5c',
  600: '#ffffff7a',
  700: '#ffffffa3',
  800: '#ffffffcc',
  900: '#ffffffeb',
};
const BLACK_ALPHA = {
  50: '#0000000a',
  100: '#0000000f',
  200: '#00000014',
  300: '#00000029',
  400: '#0000003d',
  500: '#0000005c',
  600: '#0000007a',
  700: '#000000a3',
  800: '#000000cc',
  900: '#000000eb',
};
const GREY = {
  50: '#fafafa',
  100: '#f0f0f0',
  150: '#e5e5e5',
  200: '#d8d8d8',
  300: '#cccccc',
  400: '#b0b0b0',
  500: '#989898',
  600: '#838383',
  700: '#606060',
  800: '#404040',
  900: '#262626',
};
const RED = {
  50: '#fff5f5',
  100: '#fed7d7',
  200: '#feb2b2',
  300: '#fc8181',
  400: '#f56565',
  500: '#e53e3e',
  600: '#c53030',
  700: '#9b2c2c',
  800: '#822727',
  900: '#63171b',
};
const ORANGE = {
  50: '#fffaf0',
  100: '#fff2e4',
  200: '#ffe7d4',
  300: '#ffc98f',
  400: '#fea775',
  500: '#fe7e43',
  600: '#d97000',
  700: '#9c4221',
  800: '#7b341e',
  900: '#652b19',
};
const YELLOW = {
  50: '#fffff0',
  100: '#fefcbf',
  200: '#faf089',
  300: '#f6e05e',
  400: '#ecc94b',
  500: '#f7b500',
  600: '#b7791f',
  700: '#975a16',
  800: '#744210',
  900: '#5f370e',
};
const GREEN = {
  50: '#f0fff4',
  100: '#c6f6d5',
  200: '#9ae6b4',
  300: '#68d391',
  400: '#48bb78',
  500: '#38a169',
  600: '#25855a',
  700: '#276749',
  800: '#005246',
  900: '#1c4532',
};
const TEAL = {
  50: '#e6fffa',
  100: '#b2f5ea',
  200: '#81e6d9',
  300: '#4fd1c5',
  400: '#38b2ac',
  500: '#319795',
  600: '#2c7a7b',
  700: '#285e61',
  800: '#234e52',
  900: '#1d4044',
};
const BLUE = {
  50: '#ebf8ff',
  100: '#bee3f8',
  200: '#90cdf4',
  300: '#63b3ed',
  400: '#4299e1',
  500: '#0088ff',
  600: '#2b6cb0',
  700: '#2c5282',
  800: '#2a4365',
  900: '#09162d',
};

const PRIMARY_500_60 = '#fe7e4399';

export const COLORS = {
  primary: {
    ...ORANGE,
    DEFAULT: ORANGE[500],
  },
  success: GREEN[600],
  warning: YELLOW[500],
  error: {
    ...RED,
    DEFAULT: RED[600],
  },
  info: BLUE[500],
  background: GREY[150],
  foreground: BLACK,
  black: BLACK,
  white: WHITE,
  whiteAlpha: WHITE_ALPHA,
  blackAlpha: BLACK_ALPHA,
  grey: GREY,
  gray: GREY,
  red: RED,
  orange: ORANGE,
  yellow: YELLOW,
  green: GREEN,
  teal: TEAL,
  blue: BLUE,
  shadows: {
    outline: PRIMARY_500_60, // primary-500 0.6
  },
};
