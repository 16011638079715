import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';

import { inputChange } from '../utils/input-change';
import { inputPreventPaste } from '../utils/input-prevent-paste';

export function SettlementConfirmAccountNumberField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();

  const fieldId = `${useId()}-confirm-account-number-field`;
  const {
    field: { name, value, onChange, ref },
    fieldState: { error },
  } = useController({
    control,
    name: 'accountNumber',
    rules: { required: true },
  });

  return (
    <FormControl
      isInvalid={
        value.number !== value.confirmNumber &&
        value.confirmNumber !== undefined
      }
    >
      <FormLabel htmlFor={fieldId}>
        {t('input-modal.confirm-account-number-field.label')}
      </FormLabel>
      <Input
        id={fieldId}
        bg="white"
        rounded={'lg'}
        className="flex-1 leading-38px invalid:bg-red-50"
        placeholder={t('input-modal.confirm-account-number-field.placeholder')}
        _invalid={{
          bg: 'red.50',
          border: '1px solid',
          borderColor: 'red.500',
        }}
        name={name}
        value={value.confirmNumber ?? ''}
        onPaste={inputPreventPaste}
        onChange={e =>
          inputChange(e, (number: string) => {
            onChange({
              ...value,
              ...{ confirmNumber: number },
            });
          })
        }
        ref={ref}
      />
      {error?.message && (
        <FormErrorMessage>
          <TDS.Typo color="error.500" size="xs" weight="medium">
            {error.message}
          </TDS.Typo>
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
