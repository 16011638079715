import { ChangeEvent } from 'react';

export const inputChange = (
  e: ChangeEvent<HTMLInputElement>,
  onChange?: (text: string) => void,
) => {
  const newValue = e.target.value;
  if (/^\d*$/.test(newValue)) {
    onChange && onChange(newValue);
  }
};
