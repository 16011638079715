import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectForm } from '@/components/form/select-form';
import { z } from '@/utils/validator';

import {
  AGE_GROUP_OPTIONS,
  characterAgeGroupSchema,
  formSchema,
} from '../schema';

type CharacterAgeGroupFieldProps = {
  readonly?: boolean;
  disabled?: boolean;
};

type AgeGroupOption = {
  label: string;
  value: (typeof AGE_GROUP_OPTIONS)[number];
};

export function CharacterAgeGroupField({
  disabled,
  readonly = false,
  ...props
}: CharacterAgeGroupFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setError, setValue, clearErrors } =
    useFormContext<z.infer<typeof formSchema>>();

  const characterAgeGroupFieldId = `${useId()}-character-age-group-field`;
  const {
    field: { name, ref, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'characterAgeGroup',
    rules: { required: true },
    disabled,
  });

  const ageGroupOptions = AGE_GROUP_OPTIONS.map(
    group =>
      ({
        label: t(`components.character-age-group-field.age.${group}`),
        value: group,
      }) satisfies AgeGroupOption,
  );

  const handleChange = (value?: string) => {
    setValue(
      'characterAgeGroup',
      value as z.infer<typeof characterAgeGroupSchema>,
      { shouldDirty: true },
    );

    const result = characterAgeGroupSchema.safeParse(value);
    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('characterAgeGroup', { message: error[0].message });
    } else {
      clearErrors('characterAgeGroup');
    }
  };

  return (
    <SelectForm
      label={t('components.character-age-group-field.label')}
      id={characterAgeGroupFieldId}
      placeholder={t('components.character-age-group-field.placeholder')}
      options={ageGroupOptions}
      isInvalid={invalid}
      isDisabled={disabled}
      isReadOnly={readonly}
      name={name}
      ref={ref}
      value={value}
      onSelect={handleChange}
      errorMessage={error?.message}
      {...props}
    />
  );
}
