import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextForm } from '@/components/form/text-form';

export function SettlementAccountOwnerField({ ...props }) {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();

  const fieldId = `${useId()}-account-owner-field`;
  const {
    field: { name, value, onChange: onChangeName, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'accountOwner',
    rules: { required: true },
  });

  return (
    <TextForm
      id={fieldId}
      label={t('input-modal.account-owner-field.label')}
      isInvalid={invalid}
      name={name}
      placeholder={t('input-modal.account-owner-field.placeholder')}
      value={value}
      onChange={onChangeName}
      ref={ref}
      errorMessage={error?.message}
      {...props}
    />
  );
}
