import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ChipGroupForm,
  type ChipGroupFormProps,
} from '@/components/form/chip-group-form';
import { MOOD_OPTIONS } from '@/constants/character';
import { VoiceHubCharacter } from '@/types/character';
import { z } from '@/utils/validator';

import { formSchema, characterMoodSchema } from '../schema';

type CharacterMoodFormProps = {
  disabled?: boolean;
  max?: number;
};

export function CharacterMoodField({
  disabled,
  max,
  ...props
}: CharacterMoodFormProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setValue, setError, clearErrors } =
    useFormContext<z.infer<typeof formSchema>>();

  const {
    field: { value, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'characterMood',
    rules: { required: true },
    disabled,
  });

  const selectedItemSet = new Set(value);
  const options = MOOD_OPTIONS.map(opt => ({
    label: t(`mood.${opt}`),
    value: opt,
    selected: selectedItemSet.has(opt),
  }));

  const handleToggleItem = (item: ChipGroupFormProps['options'][number]) => {
    const newMoods = item.selected
      ? value.filter(v => v !== item.value)
      : ([item.value, ...value] as VoiceHubCharacter['mood']);

    setValue('characterMood', newMoods, { shouldDirty: true });

    const result = characterMoodSchema.safeParse(newMoods);

    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('characterMood', { message: error[0].message });
    } else {
      clearErrors('characterMood');
    }
  };

  return (
    <ChipGroupForm
      label={t('components.character-mood-field.label')}
      errorMessage={error?.message}
      options={options}
      onClickItem={handleToggleItem}
      isInvalid={invalid}
      isDisabled={disabled}
      max={max}
      ref={ref}
      {...props}
    />
  );
}
