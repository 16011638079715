// import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectForm } from '@/components/form/select-form';

// import { TDS } from '@/libs/chakra';
import { BANK_MAP } from '../constants/bank-option';

type BankOption = {
  label: string;
  value: string;
};
export function SettlementBankField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();
  const fieldId = `${useId()}-bank-field`;
  const {
    field: { name, value, onChange, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'bank',
    rules: { required: true },
  });
  const bankOptions: BankOption[] = Object.entries(BANK_MAP).map(
    ([value, label]) => ({
      label,
      value,
    }),
  );
  const handleChange = (value: string) => {
    onChange(value);
  };
  return (
    <SelectForm
      label={t('input-modal.bank-field.label')}
      id={fieldId}
      placeholder={t('input-modal.bank-field.placeholder')}
      options={bankOptions}
      isInvalid={invalid}
      name={name}
      ref={ref}
      value={value}
      onSelect={handleChange}
      errorMessage={error?.message}
    />
  );
}
