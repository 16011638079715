import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '@/components/ui';
import { TDS } from '@/libs/chakra';
import { formatToKRW } from '@/utils/format-currency';

import { SettlementRevenue } from './settlement-revenue';

type totalRevenueProps = {
  totalAccumulatedRevenue: number;
  totalSettledRevenue: number;
};

export function SettlementTotalRevenueBoxContainer({
  totalRevenue,
  onClickTotalRevenue,
}: {
  totalRevenue: totalRevenueProps;
  onClickTotalRevenue: () => void;
}) {
  const { t } = useTranslation('settlePage');

  return (
    <>
      <Flex w="full" gap={6}>
        <TotalRevenueBox title={t('table.total-accumulated-revenue')}>
          <SettlementRevenue
            revenue={totalRevenue.totalAccumulatedRevenue}
            onClickDetail={onClickTotalRevenue}
            weight="medium"
            size="2xl"
            color="black"
          />
        </TotalRevenueBox>
        <TotalRevenueBox title={t('table.total-settled-revenue')}>
          <TDS.Typo size="2xl" weight="medium" color="black">
            {formatToKRW(totalRevenue.totalSettledRevenue)}원
          </TDS.Typo>
        </TotalRevenueBox>
      </Flex>
    </>
  );
}

function TotalRevenueBox({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <Flex
      w="full"
      bgColor="white"
      p="10px 24px"
      borderRadius={10}
      justifyContent="space-between"
      alignItems="center"
    >
      <TDS.Typo as="h3" size="sm" weight="medium" color="grey.700">
        {title}
      </TDS.Typo>
      <Flex alignItems="center">{children}</Flex>
    </Flex>
  );
}
