import { useShallow } from 'zustand/react/shallow';

import { MouseEventHandler, Suspense } from 'react';
import { type SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Await, useNavigate, useParams } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';

import {
  requestCreateCharacter,
  requestDeleteCharacter,
  requestGetCharacter,
  requestGetCharacterReview,
  requestRestartReview,
  requestStartTraining,
  requestUpdateCharacterDetail,
  requestUploadCharacterVoice,
} from '@/api/voice-hub';
import {
  Card,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TDS,
  useDisclosure,
  useToast,
} from '@/components/ui';
import { useLoading } from '@/hooks/use-loading';
import { useRole } from '@/hooks/use-role';
import { useStartGlobalLoading } from '@/hooks/use-start-global-loading';
import { useLoadingStore } from '@/stores/loading';
import { VoiceHubCharacter } from '@/types/character';
import { ISOLanguageCode2Digit } from '@/types/language';
import { Review } from '@/types/review';
import {
  isExitedCharacter,
  isExitingCharacter,
  isFailedCharacter,
  isRejectedCharacter,
} from '@/utils/character';
import { isFailed, isNil, isNotNil, isSuccess, isTrue } from '@/utils/guard';
import { dayjs } from '@/utils/time';
import { z, zodResolver } from '@/utils/validator';

import NotFoundPage from '../not-found-page';
import { CharacterAgeGroupField } from './components/character-age-group-field';
import { CharacterContentField } from './components/character-content-field';
import { CharacterDescriptionField } from './components/character-description-field';
import { CharacterEnglishNameField } from './components/character-english-name-field';
import { CharacterKoreanNameField } from './components/character-korean-name-field';
import { CharacterMoodField } from './components/character-mood-field';
import { CharacterToneGroupField } from './components/character-tone-group-field';
import { ErrorAlert } from './components/error-alert';
import { OriginalVoiceField } from './components/original-voice-field';
import { OriginalVoiceGenderField } from './components/original-voice-gender-field';
import { OriginalVoiceLanguageField } from './components/original-voice-language-field';
import { ReleaseProcessStepper } from './components/release-process-stepper';
import { TermsField } from './components/terms-field';
import {
  GENDER_OPTIONS,
  LANGUAGE_OPTIONS,
  characterAgeGroupSchema,
  characterContentSchema,
  characterDescriptionSchema,
  characterEnglishNameSchema,
  characterKoreanNameSchema,
  characterMoodSchema,
  characterToneGroupSchema,
  formSchema,
  genderSchema,
  languageSchema,
  voiceSchema,
} from './schema';
import {
  isVoiceChangableCharacter,
  isInfoChangableCharacter,
  isDeletableCharacter,
  getStepIndex,
} from './utils/release-state';

const fetchFormData = ({ formId }: { formId?: string }) => {
  return {
    formData: formId
      ? requestGetCharacter({ id: formId }).then(res =>
          isSuccess(res) ? res.data : null,
        )
      : Promise.resolve([]),
    reviewData: formId
      ? requestGetCharacterReview({ id: formId }).then(res =>
          isSuccess(res) ? res.data : null,
        )
      : Promise.resolve([]),
  };
};

export default function CharacterFormPage() {
  const { formId } = useParams();
  const { isVoiceHubProvider } = useRole();
  const loader = fetchFormData({
    formId: isVoiceHubProvider ? formId : undefined,
  });
  const { t } = useTranslation('characterFormPage');

  const releaseSteps = [
    {
      title: t('step.release-request'),
    },
    {
      title: t('step.learning'),
    },
    {
      title: t('step.review'),
    },
    {
      title: t('step.complete'),
    },
  ];

  if (!isVoiceHubProvider) {
    return <NotFoundPage />;
  }

  if (isNil(formId)) {
    return (
      <>
        <ReleaseProcessStepper
          className="mb-8"
          steps={releaseSteps}
          index={0}
        />
        <CharacterForm />
      </>
    );
  }

  const getStepMessage = (character: VoiceHubCharacter) => {
    if (isExitingCharacter(character)) {
      return t('alert.will-deprecate', {
        date: dayjs(character.endDate).format('YYYY년 MM월 DD일'),
      });
    }
    if (isExitedCharacter(character)) {
      return t('alert.deprecated');
    }

    return undefined;
  };

  return (
    <Suspense fallback={<ShowLoading />}>
      <Await
        resolve={Promise.all([loader.formData, loader.reviewData])}
        errorElement={<div>{t('error.character-fetch-failed')} 😬</div>}
      >
        {([character, review]: [VoiceHubCharacter, Review]) => {
          const currentStepIndex = getStepIndex(character);
          const stepMessage = getStepMessage(character);

          return (
            <>
              <ReleaseProcessStepper
                className="mb-8"
                steps={releaseSteps}
                index={currentStepIndex}
                statusMessage={stepMessage}
                exited={isExitedCharacter(character)}
              />
              {isFailedCharacter(character) && (
                <ErrorAlert status={'failed'} review={review}></ErrorAlert>
              )}
              {isRejectedCharacter(character) && (
                <ErrorAlert status={'rejected'} review={review}></ErrorAlert>
              )}
              <CharacterForm character={character} review={review} />
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}

function ShowLoading() {
  const { showLoading, hideLoading } = useLoadingStore(
    useShallow(state => state),
  );

  useMount(() => {
    showLoading();
  });

  useUnmount(() => {
    hideLoading();
  });

  return <></>;
}

function CharacterForm({
  character,
  review,
}: {
  character?: VoiceHubCharacter;
  review?: Review;
}) {
  // TODO: 이미지 필드 tabable하게 수정
  const { t } = useTranslation('characterFormPage');
  const toast = useToast();
  const navigate = useNavigate();
  const submitButtonLoading = useLoading();
  const confirmFormHandler = useDisclosure();
  const confirmDeleteHandler = useDisclosure();
  const initialRef = useRef<HTMLButtonElement>(null);
  const finalRef = useRef<HTMLButtonElement>(null);
  const startGlobalLoading = useStartGlobalLoading();

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onSubmit',
    resolver: zodResolver(formSchema),
    defaultValues: {
      voice: [],
      gender: character?.gender ?? GENDER_OPTIONS[0],
      language:
        (character?.language as (typeof LANGUAGE_OPTIONS)[number]) ??
        LANGUAGE_OPTIONS[0],
      characterKoreanName: character?.name.ko ?? '',
      characterEnglishName: character?.name.en ?? '',
      characterDescription: character?.description ?? '',
      characterToneGroup: character?.tone ?? undefined,
      characterAgeGroup: character?.age ?? undefined,
      characterMood: character?.mood ?? [],
      characterContent: character?.content ?? [],
      characterCastingImage: [],
      characterProfileImage: [],
      characterVideoImage: [],
      terms: {
        term1: !!character,
        term2: !!character,
        term3: !!character,
        term4: !!character,
      },
    },
    shouldFocusError: true,
  });
  const needChangeVoice =
    isNil(character) ||
    (isNotNil(character) && isVoiceChangableCharacter(character, review));
  const needChangeInfo =
    isNil(character) ||
    (isNotNil(character) && isInfoChangableCharacter(character, review));
  const isEditable = isNotNil(character) && (needChangeVoice || needChangeInfo);
  const isTermChangable = isNil(character);

  const handleClickDeleteCharacter = () => {
    confirmDeleteHandler.onOpen();
  };
  const handleDeleteCharacter = () => {
    if (isNil(character)) {
      return;
    }

    startGlobalLoading(async () => {
      const result = await requestDeleteCharacter({ id: character.id });
      if (isSuccess(result)) {
        toast({
          position: 'top',
          status: 'info',
          title: t('alert.character-delete-complete.title'),
          description: t('alert.character-delete-complete.description'),
          duration: 9000,
          colorScheme: 'transparent',
          containerStyle: {
            backgroundColor: 'grey.900',
            borderRadius: '12px',
          },
        });
        navigate('/partners');
        return;
      }
      if (isFailed(result)) {
        toast({
          position: 'top',
          status: 'error',
          title: t('error.delete-request-failed'),
          description: result.error.message,
          duration: 9000,
        });
        return;
      }
    });
  };

  const handleResubmit: MouseEventHandler<HTMLButtonElement> = async () => {
    if (isNil(character)) {
      return;
    }

    submitButtonLoading.start();
    const isUpdateVoice = form.getFieldState('voice').isDirty;
    const isUpdateGender = form.getFieldState('gender').isDirty;
    const isUpdateLanguage = form.getFieldState('language').isDirty;
    const isUpdateCharacterKoreanName = form.getFieldState(
      'characterKoreanName',
    ).isDirty;
    const isUpdateCharacterEnglishName = form.getFieldState(
      'characterEnglishName',
    ).isDirty;
    const isUpdateCharacterDescription = form.getFieldState(
      'characterDescription',
    ).isDirty;
    const isUpdateCharacterToneGroup =
      form.getFieldState('characterToneGroup').isDirty;
    const isUpdateCharacterAgeGroup =
      form.getFieldState('characterAgeGroup').isDirty;
    const isUpdateCharacterContent =
      form.getFieldState('characterContent').isDirty;
    const isUpdateCharacterMood = form.getFieldState('characterMood').isDirty;

    const isUpdateInfo = [
      isUpdateGender,
      isUpdateLanguage,
      isUpdateCharacterKoreanName,
      isUpdateCharacterEnglishName,
      isUpdateCharacterDescription,
      isUpdateCharacterToneGroup,
      isUpdateCharacterAgeGroup,
      isUpdateCharacterContent,
      isUpdateCharacterMood,
    ].some(isTrue);

    if (isUpdateVoice) {
      const newVoices = form.getValues('voice');
      const voiceValidateResult = await voiceSchema.safeParseAsync(newVoices);

      if (!voiceValidateResult.success) {
        return;
      }
    }

    const params: Parameters<typeof requestUpdateCharacterDetail>[0] = {
      characterId: character.id,
    };
    if (isUpdateInfo) {
      const newGender = form.getValues('gender') ?? character.gender;
      const genderValidation = genderSchema.safeParse(newGender);

      if (!genderValidation.success) {
        return;
      }

      params.gender = newGender;

      const newLanguage = form.getValues('language') ?? character.language;
      const languageValidation = languageSchema.safeParse(newLanguage);
      if (!languageValidation.success) {
        return;
      }

      params.language = newLanguage;

      const newKoreanName =
        form.getValues('characterKoreanName') ?? character.name.ko;
      const koreanNameValidation =
        await characterKoreanNameSchema.safeParseAsync(newKoreanName);
      if (
        newKoreanName !== character.name.ko &&
        !koreanNameValidation.success
      ) {
        return;
      }

      if (isNil(params.name)) {
        params.name = {};
      }
      params.name.ko = newKoreanName;

      const newEnglishName =
        form.getValues('characterEnglishName') ?? character.name.en;
      const englishNameValidation =
        await characterEnglishNameSchema.safeParseAsync(newEnglishName);
      if (
        newEnglishName !== character.name.en &&
        !englishNameValidation.success
      ) {
        return;
      }

      if (isNil(params.name)) {
        params.name = {};
      }
      params.name.en = newEnglishName;

      const newDescription =
        form.getValues('characterDescription') ?? character.description;
      const descriptionValidation =
        characterDescriptionSchema.safeParse(newDescription);
      if (!descriptionValidation.success) {
        return;
      }

      params.description = newDescription;

      const newTone = form.getValues('characterToneGroup') ?? character.tone;
      const toneValidation = characterToneGroupSchema.safeParse(newTone);
      if (!toneValidation.success) {
        return;
      }

      params.tone = newTone;

      const newAge = form.getValues('characterAgeGroup') ?? character.age;
      const ageValidation = characterAgeGroupSchema.safeParse(newAge);
      if (!ageValidation.success) {
        return;
      }

      params.age = newAge;

      const newContent =
        form.getValues('characterContent') ?? character.content;
      const contentValidation = characterContentSchema.safeParse(newContent);
      if (!contentValidation.success) {
        return;
      }

      params.content = newContent;

      const newMood = form.getValues('characterMood') ?? character.mood;
      const moodValidation = characterMoodSchema.safeParse(newMood);
      if (!moodValidation.success) {
        return;
      }

      params.mood = newMood;
    }
    submitButtonLoading.end();
    startGlobalLoading(async () => {
      // Image ReUpload 감지

      // const isUpdateCastingImage = form.getFieldState(
      //   'characterCastingImage',
      // ).isDirty;
      // const isUpdateProfileImage = form.getFieldState(
      //   'characterProfileImage',
      // ).isDirty;
      // const isUpdateVideoImage = form.getFieldState(
      //   'characterVideoImage',
      // ).isDirty;

      // Image ReUpload

      // if (isUpdateCastingImage) {
      //   requests.push(
      //     requestUploadImage({
      //       characterId: character.id,
      //       image: form.getValues('characterCastingImage')[0],
      //       type: 'download',
      //     }),
      //   );
      // }

      // if (isUpdateProfileImage) {
      //   requests.push(
      //     requestUploadImage({
      //       characterId: character.id,
      //       image: form.getValues('characterProfileImage')[0],
      //       type: 'thumbnail',
      //     }),
      //   );
      // }

      // if (isUpdateVideoImage) {
      //   requests.push(
      //     requestUploadImage({
      //       characterId: character.id,
      //       image: form.getValues('characterVideoImage')[0],
      //       type: 'video',
      //     }),
      //   );
      // }

      const requests = [];
      if (isUpdateVoice) {
        requests.push(
          requestUploadCharacterVoice({
            characterId: character.id,
            voice: form.getValues('voice')[0],
          }),
        );
      }
      if (isUpdateInfo) {
        requests.push(requestUpdateCharacterDetail(params));
      }

      const updateMetadataResult = await Promise.all(requests);
      if (updateMetadataResult.some(isFailed)) {
        updateMetadataResult.forEach(result => {
          if (isFailed(result)) {
            toast({
              position: 'top',
              status: 'error',
              title: t('update-request-failed'),
              description: result.error.message,
              duration: 9000,
            });
          }
        });
        return;
      }

      await requestRestartReview({
        characterId: character.id,
        isAudioChange: isUpdateVoice,
        isImageChange: false,
        //   isUpdateCastingImage || isUpdateProfileImage || isUpdateVideoImage,
        isInfoChange: isUpdateInfo,
      });

      navigate(0);
    });
  };

  const handleClickSubmit = async () => {
    const isValid = await form.trigger(undefined, { shouldFocus: true });
    if (!isValid) {
      return;
    }
    confirmFormHandler.onOpen();
  };
  const handleSubmit: SubmitHandler<
    z.infer<typeof formSchema>
  > = async data => {
    await startGlobalLoading(async () => {
      const createResult = await requestCreateCharacter({
        name: {
          ko: data.characterKoreanName,
          en: data.characterEnglishName,
        },
        gender: data.gender,
        language: data.language as ISOLanguageCode2Digit,
        description: data.characterDescription,
      });

      if (isFailed(createResult)) {
        toast({
          position: 'top',
          status: 'error',
          title: t('release-request-failed'),
          description: createResult.error.message,
          duration: 9000,
        });
        return;
      }

      const { id: characterId } = createResult.data;

      const updateMetadataResult = await Promise.all([
        requestUploadCharacterVoice({ characterId, voice: data.voice[0] }),

        // Image Upload

        // requestUploadImage({
        //   characterId,
        //   image: data.characterCastingImage[0],
        //   type: 'download',
        // }),
        // requestUploadImage({
        //   characterId,
        //   image: data.characterProfileImage[0],
        //   type: 'thumbnail',
        // }),
        // requestUploadImage({
        //   characterId,
        //   image: data.characterVideoImage[0],
        //   type: 'video',
        // }),
        requestUpdateCharacterDetail({
          characterId,
          tone: data.characterToneGroup,
          age: data.characterAgeGroup,
          mood: data.characterMood,
          content: data.characterContent,
        }),
      ]);

      if (updateMetadataResult.some(isFailed)) {
        updateMetadataResult.forEach(result => {
          if (isFailed(result)) {
            toast({
              position: 'top',
              status: 'error',
              title: t('release-request-failed'),
              description: result.error.message,
              duration: 9000,
            });
          }
        });
        return;
      }

      await requestStartTraining({
        characterId,
      });

      navigate('/partners?release-request-complete=true');
    });
  };

  const terms = form.watch('terms');
  const isAllTermsChecked = Object.values(terms).every(isTrue);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        id="release-character"
        className="flex flex-row gap-6"
      >
        <div className="flex-1">
          <fieldset className="mb-8">
            <legend className="text-5 leading-6 font-bold mb-4">
              {t('field.legend.voice')}
            </legend>
            <Card
              variant="filled"
              rounded="2xl"
              bg="grey.50"
              p={4}
              display="flex"
              flexDir="column"
              gap={6}
            >
              <OriginalVoiceField
                preview={character?.voice.filename}
                disabled={!needChangeVoice}
              />
              <OriginalVoiceGenderField disabled={!needChangeVoice} />
              <OriginalVoiceLanguageField disabled={true} />
            </Card>
          </fieldset>
          <fieldset>
            <legend className="text-5 leading-6 font-bold mb-4">
              {t('field.legend.character')}
            </legend>
            <Card
              variant="filled"
              rounded="2xl"
              bg="grey.50"
              p={4}
              display="flex"
              flexDir="column"
              gap={6}
              mb={4}
            >
              <div className="flex flex-row gap-4 items-stretch">
                <CharacterKoreanNameField
                  disabled={!needChangeInfo}
                  originName={character?.name.ko}
                />
                <CharacterEnglishNameField
                  disabled={!needChangeInfo}
                  originName={character?.name.en}
                />
              </div>
              <CharacterDescriptionField disabled={!needChangeInfo} />
              <div className="flex flex-row gap-4 items-stretch">
                <CharacterAgeGroupField disabled={!needChangeInfo} />
                <CharacterToneGroupField disabled={!needChangeInfo} />
              </div>
              <CharacterMoodField disabled={!needChangeInfo} max={4} />
              <CharacterContentField disabled={!needChangeInfo} max={1} />
            </Card>
            {/* <Card
              variant="filled"
              rounded="2xl"
              bg="grey.50"
              p={4}
              display="flex"
              flexDir="column"
              gap={6}
            >
              <CharacterCastingImageField
                preview={character?.image.casting}
                disabled={!needChangeInfo}
              />
              <CharacterProfileImageField
                preview={character?.image.profile}
                disabled={!needChangeInfo}
              />
              <CharacterVideoImageField
                preview={character?.image.video}
                disabled={!needChangeInfo}
              />
            </Card> */}
          </fieldset>
        </div>
        <div className="w-320px flex flex-col items-stretch gap-4 position-sticky top-0 h-full">
          <TermsField disabled={!isTermChangable} />
          {isNil(character) && (
            <TDS.Button
              colorScheme="primary"
              type="button"
              ref={finalRef}
              isDisabled={!isAllTermsChecked}
              onClick={handleClickSubmit}
            >
              {t('button.release')}
            </TDS.Button>
          )}
          {isEditable && (
            <TDS.Button
              colorScheme="primary"
              type="button"
              onClick={handleResubmit}
              isLoading={submitButtonLoading.isLoading}
            >
              {t('button.retry')}
            </TDS.Button>
          )}
          {!!character && isDeletableCharacter(character) && (
            <TDS.Button
              colorScheme="grey"
              type="button"
              size="md"
              onClick={handleClickDeleteCharacter}
              isDisabled={submitButtonLoading.isLoading}
            >
              {t('button.delete')}
            </TDS.Button>
          )}
          <Modal
            isOpen={confirmFormHandler.isOpen}
            onClose={confirmFormHandler.onClose}
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isCentered
          >
            <ModalOverlay />
            <ModalContent
              rounded="2xl"
              width="full"
              maxWidth={448}
              marginX={5}
              paddingX={6}
            >
              <ModalHeader
                fontSize="lg"
                fontWeight="bold"
                paddingY={4}
                paddingX={0}
              >
                {t('release-modal.header')}
              </ModalHeader>
              <ModalBody paddingY={2} paddingX={0}>
                <TDS.Typo
                  as="p"
                  size="md"
                  color="grey.700"
                  className="whitespace-pre-wrap"
                >
                  {t('release-modal.body')}
                </TDS.Typo>
              </ModalBody>
              <ModalFooter paddingY={4} paddingX={0} gap={3}>
                <TDS.Button
                  size="md"
                  colorScheme="grey"
                  onClick={confirmFormHandler.onClose}
                >
                  {t('release-modal.footer.close')}
                </TDS.Button>
                <TDS.Button
                  size="md"
                  type="submit"
                  colorScheme="primary"
                  form="release-character"
                  ref={initialRef}
                >
                  {t('release-modal.footer.confirm')}
                </TDS.Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={confirmDeleteHandler.isOpen}
            onClose={confirmDeleteHandler.onClose}
            isCentered
          >
            <ModalOverlay />
            <ModalContent
              rounded="2xl"
              width="full"
              maxWidth={448}
              marginX={5}
              paddingX={6}
            >
              <ModalHeader
                fontSize="lg"
                fontWeight="bold"
                paddingY={4}
                paddingX={0}
              >
                {t('delete-modal.header')}
              </ModalHeader>
              <ModalBody paddingY={2} paddingX={0}>
                <TDS.Typo
                  as="p"
                  size="md"
                  color="grey.700"
                  className="whitespace-pre-wrap"
                >
                  {t('delete-modal.body')}
                </TDS.Typo>
              </ModalBody>
              <ModalFooter paddingY={4} paddingX={0} gap={3}>
                <TDS.Button
                  size="md"
                  colorScheme="grey"
                  onClick={confirmDeleteHandler.onClose}
                >
                  {t('delete-modal.footer.close')}
                </TDS.Button>
                <TDS.Button
                  size="md"
                  colorScheme="error"
                  onClick={handleDeleteCharacter}
                >
                  {t('delete-modal.footer.confirm')}
                </TDS.Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </form>
    </FormProvider>
  );
}
