/**
 * 비디오 URL로부터 비디오의 길이 정보를 추출합니다.
 *
 * @param url video url
 * @returns video length
 */
export const getVideoDurationFromUrl = async (url: string): Promise<number> =>
  (await getVideoMetadataFromUrl(url)).duration;

export const getVideoMetadataFromUrl = (
  url: string,
): Promise<{ width: number; height: number; duration: number }> =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.muted = true;
    video.playsInline = true;
    video.crossOrigin = 'anonymous';

    video.addEventListener('loadedmetadata', () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    });
    video.addEventListener('error', reject);

    video.setAttribute('src', url);
  });

/**
 * 오디오 파일의 메타데이터를 반환합니다.
 *
 * @param file
 * @returns 오디오 파일의 메타데이터
 */
export const getAudioMetadataFromFile = (
  file: File,
): Promise<{ duration: number }> => {
  return new Promise(resolve => {
    const audio = new Audio();
    audio.addEventListener('loadedmetadata', () => {
      resolve({ duration: audio.duration });
    });
    audio.src = URL.createObjectURL(file);
  });
};

/**
 * 오디오 파일의 길이를 반환합니다.
 *
 * @param file
 * @returns number 오디오 파일의 길이(초)
 */
export const getAudioDurationFromFile = async (file: File) => {
  const { duration } = await getAudioMetadataFromFile(file);
  return duration;
};

export const getImageMetadataFromFile = (
  file: File,
): Promise<{ width: number; height: number }> => {
  return new Promise(resolve => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve({
        width: Number(image.naturalWidth),
        height: Number(image.naturalHeight),
      });
    });
    image.src = URL.createObjectURL(file);
  });
};

export const getImageSizeFromFile = async (file: File) => {
  const { width, height } = await getImageMetadataFromFile(file);
  return [width, height];
};
