import {
  FacebookAuthProvider,
  getAuth,
  signInWithCredential,
} from 'firebase/auth';

import { requestGetIsExistUser } from '@/api/typecast';

import { PROVIDER } from '../constants';
import type { Provider, Token } from '../types';
import { OAuthSignInInterface } from './base-sign-in';

type LoginStatus = {
  provider: Provider;
  token?: string;
  providerUid: string;
  email?: string;
};

export class FacebookSignIn implements OAuthSignInInterface {
  static init() {
    window.fbAsyncInit = () => {
      if (window.FB) {
        window.FB.init({
          appId: import.meta.env.VITE_FACEBOOK_APP_ID,
          cookie: true, // Enable cookies to allow the server to access the session.
          xfbml: true, // Parse social plugins on this webpage.
          version: 'v12.0', // Use this Graph API version for this call.
        });
      }
    };

    // load facebook sdk script
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      // @ts-ignore
      const js: HTMLScriptElement = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  async signIn(token?: Token) {
    if (!token) {
      throw new Error('Facebook.signIn: token is required');
    }
    const credential = this.getCredential(token);
    return signInWithCredential(getAuth(), credential);
  }

  static async facebookSignIn(): Promise<fb.StatusResponse> {
    return new Promise((resolve, reject) => {
      window.FB.login(
        response => {
          if (response.status === 'connected') {
            resolve(response);
          } else {
            reject(response);
          }
        },
        { scope: 'email' },
      );
    });
  }

  static getLoginStatus(): Promise<LoginStatus> {
    return new Promise(resolve => {
      window.FB.getLoginStatus(response => {
        window.FB.api<{ fields: string }, { email?: string }>(
          '/me',
          { fields: 'email' },
          me => {
            resolve({
              provider: PROVIDER.FACEBOOK,
              token: response.authResponse.accessToken,
              providerUid: response.authResponse.userID,
              email: me?.email,
            });
          },
        );
      });
    });
  }

  getEmail(): Promise<string | undefined> {
    // TODO: FB객체가 없는 경우 처리
    return new Promise(resolve => {
      window.FB.api<{ fields: string }, { email?: string }>(
        '/me',
        { fields: 'email' },
        me => {
          resolve(me?.email);
        },
      );
    });
  }

  getCredential(token: Token) {
    return FacebookAuthProvider.credential(token);
  }

  getProvider() {
    return new FacebookAuthProvider();
  }

  async checkIsExistUser(): Promise<boolean> {
    const { providerUid } = await FacebookSignIn.getLoginStatus();
    const res = await requestGetIsExistUser({
      provider: PROVIDER.FACEBOOK,
      providerUid: providerUid,
    });

    if (res.isFailed) {
      throw res.error;
    }

    return res.data;
  }
}
