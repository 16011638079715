import {
  ChakraProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from '@chakra-ui/react';

import { TOKEN } from '../../../theme';
import {
  Button,
  type ButtonProps as TDSButtonProps,
} from './components/Button';
import {
  Checkbox,
  type CheckboxProps as TDSCheckboxProps,
} from './components/Checkbox';
import {
  Popover,
  type PopoverProps as TDSPopoverProps,
} from './components/Popover';
import {
  Select,
  type SingleValue as TDSSingleValue,
} from './components/Select/Select';
import {
  Spinner,
  type SpinnerProps as TDSSpinnerProps,
} from './components/Spinner';
import { Typo, type TypoProps as TDSTypoProps } from './components/Typo';

const theme = extendBaseTheme({
  shadows: TOKEN.shadows,
  breakpoints: TOKEN.breakpoints,
  components: {
    ...chakraTheme.components,
    Textarea: {
      ...chakraTheme.components.Textarea,
      defaultProps: {
        ...chakraTheme.components.Textarea.defaultProps,
        focusBorderColor: 'primary.500',
      },
    },
    Input: {
      ...chakraTheme.components.Input,
      defaultProps: {
        ...chakraTheme.components.Input.defaultProps,
        focusBorderColor: 'primary.500',
      },
    },
  },
  fonts: {
    heading: TOKEN.fontFamily.sans.join(', '),
    body: TOKEN.fontFamily.sans.join(', '),
  },
  fontSizes: TOKEN.fontSize,
  fontWeights: TOKEN.fontWeight,
  colors: TOKEN.colors,
});

const TDS = {
  Button,
  Popover,
  Spinner,
  Typo,
  Checkbox,
  Select,
};

export {
  ChakraProvider as ThemeProvider,
  theme,
  TDS,
  TDSButtonProps,
  TDSPopoverProps,
  TDSSpinnerProps,
  TDSTypoProps,
  TDSCheckboxProps,
  TDSSingleValue,
};
export * from '@chakra-ui/react';
