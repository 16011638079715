import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectForm } from '@/components/form/select-form';
import { z } from '@/utils/validator';

import { TONE_OPTIONS, characterToneGroupSchema, formSchema } from '../schema';

type CharacterToneGroupFieldProps = {
  disabled?: boolean;
  readonly?: boolean;
};

type ToneOption = {
  label: string;
  value: (typeof TONE_OPTIONS)[number];
};

export function CharacterToneGroupField({
  disabled,
  readonly = false,
  ...props
}: CharacterToneGroupFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setError, clearErrors, setValue } =
    useFormContext<z.infer<typeof formSchema>>();

  const characterToneGroupFieldId = `${useId()}-character-tone-group-field`;
  const {
    field: { name, ref, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'characterToneGroup',
    rules: { required: true },
    disabled,
  });

  const toneOptions = TONE_OPTIONS.map(
    tone =>
      ({
        label: t(`components.character-tone-group-field.tone.${tone}`),
        value: tone,
      }) satisfies ToneOption,
  );

  const handleChange = (value?: string) => {
    setValue(
      'characterToneGroup',
      value as z.infer<typeof characterToneGroupSchema>,
      { shouldDirty: true },
    );
    const result = characterToneGroupSchema.safeParse(value);
    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('characterToneGroup', { message: error[0].message });
    } else {
      clearErrors('characterToneGroup');
    }
  };

  return (
    <SelectForm
      label={t('components.character-tone-group-field.label')}
      id={characterToneGroupFieldId}
      placeholder={t('components.character-tone-group-field.placeholder')}
      options={toneOptions}
      isInvalid={invalid}
      isDisabled={disabled}
      isReadOnly={readonly}
      name={name}
      ref={ref}
      value={value}
      onSelect={handleChange}
      errorMessage={error?.message}
      {...props}
    />
  );
}
