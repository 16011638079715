import {
  Select as _Select,
  SingleValue,
  Props,
  GroupBase,
  SelectInstance,
} from 'chakra-react-select';

import { RefAttributes } from 'react';

export type { SingleValue };

const Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  options = [],
  value,
  placeholder,
  size = 'md',
  isInvalid = false,
  isDisabled = false,
  isReadonly = false,
  ...props
}: Props<Option, IsMulti, Group> &
  RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
    isReadonly?: boolean;
  }) => {
  return (
    <_Select
      options={options}
      value={value}
      placeholder={placeholder}
      chakraStyles={{
        control: (provided, state) => ({
          ...provided,
          minHeight: size === 'lg' ? 12 : size === 'md' ? 10 : 8,
          borderRadius: size === 'lg' ? 12 : size === 'md' ? 10 : 8,
          border: '1px',
          background: state.isFocused
            ? 'white'
            : isInvalid
              ? 'error.50'
              : isReadonly
                ? 'grey.100'
                : 'white',
          opacity: isDisabled ? 0.5 : 1,
          color: 'grey.700',
          _focus: {
            color: 'grey.900',
          },
          _invalid: {
            boxShadow: 'none',
            borderColor: 'error.500',
          },
          pointerEvents: isReadonly ? 'none' : 'auto',
        }),
        menuList: provided => ({
          ...provided,
          borderRadius: size === 'lg' ? 12 : size === 'md' ? 10 : 8,
        }),
      }}
      focusBorderColor="primary.500"
      selectedOptionStyle="check"
      menuPlacement="auto"
      useBasicStyles
      isSearchable={false}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      tabIndex={isReadonly ? -1 : 0}
      {...props}
    />
  );
};

export { Select };
