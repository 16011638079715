import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

import { PollingManager } from './managers/polling';
import { TokenManager } from './managers/token';
import { Endpoint, Payload } from './types';
import { isAwsS3OrSignedUrl } from './utils';

export * from '../../utils/env';
export * from './utils';
export * from './types';

type ApiConfig = {
  baseURL: string;
};

export class HttpClient {
  #instance: AxiosInstance;

  constructor(apiConfig: ApiConfig) {
    this.#instance = axios.create({
      baseURL: apiConfig.baseURL,
    });

    this.#instance.interceptors.response.use(response => {
      response.data = response.data.result || response.data;
      return response;
    }, this.#handleError);
  }

  #handleError = (error: AxiosError) => {
    if (error.response) {
      // TODO Sentry 연동
    }
    return Promise.reject(error);
  };

  #getAuthorizationHeader = async (endpoint: Endpoint) => {
    if (endpoint.noAuth || isAwsS3OrSignedUrl(endpoint?.url)) {
      return {};
    }

    const accessToken = await TokenManager.getAccessToken();
    if (!accessToken) {
      throw new Error('Access token is not available');
    }
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };

  #getPayloadConfig(isQueryParamMethod: boolean, payload?: Payload) {
    if (!payload) return {};

    return isQueryParamMethod ? { params: payload } : { data: payload };
  }

  async request<T>(
    endpoint: Endpoint,
    payload?: Payload,
  ): Promise<AxiosResponse<T>> {
    const { url, method, responseType } = endpoint;
    const headers = await this.#getAuthorizationHeader(endpoint);

    const isQueryParamMethod =
      method === 'get' || method.toLowerCase() === 'delete';

    return await this.#instance.request({
      url,
      method,
      responseType,
      ...this.#getPayloadConfig(isQueryParamMethod, payload),
      ...headers,
    });
  }

  pollingRequest<T>(
    endpoint: Endpoint,
    payload: Payload,
    delayTime: number,
    maxAttemptsCount: number,
    shouldStopPolling: (data: T) => boolean,
  ): PollingManager<T> {
    const pollingManager = new PollingManager<T>(
      this,
      endpoint,
      payload,
      delayTime,
      maxAttemptsCount,
      shouldStopPolling,
    );
    pollingManager.start();
    return pollingManager;
  }
}
