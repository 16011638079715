export const TYPECAST_HOME = 'https://typecast.ai/kr';
export const TYPECAST_APP = 'https://app.typecast.ai';
export const SETTLEMENT_GUIDE_LINK =
  'https://www.notion.so/neosapience/TBD-c900a703c7e44ed69146fedf911a64cb?pvs=4#a65d4445b74548d98213ede976e4839f' as const;
export const VOICEHUB_GUIDE_LINK =
  'https://www.notion.so/neosapience/TBD-c900a703c7e44ed69146fedf911a64cb?pvs=4#0f11e71a30eb43eb8d94ae10b5db280c' as const;
export const SERVICE_TERM =
  'https://static2.typecast.ai/license/Voice-Hub-Terms+of+use.html';
export const CHARACTER_TERM =
  'https://static2.typecast.ai/license/Voice-Hub-Character+contract.html';
