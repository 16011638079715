import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  ReactNode,
} from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
  Input,
  TDS,
} from '@/components/ui';

export type TextFormProps = Omit<FormControlProps, 'onChange' | 'onBlur'> & {
  id?: string;
  label?: ReactNode;
  errorMessage?: ReactNode;
  placeholder?: string;
  name?: string;
  value?: string;
  helperText?: ReactNode;
  onChange?: (text: string) => void;
  onBlur?: (text: string) => void;
};
export const TextForm = forwardRef(function (
  {
    id,
    label,
    onChange,
    onBlur,
    isDisabled,
    placeholder,
    errorMessage,
    name,
    value,
    helperText,
    ...props
  }: TextFormProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    const text = e.currentTarget.value;
    onChange && onChange(text);
  };
  const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
    const text = e.currentTarget.value;
    onBlur && onBlur(text);
  };

  return (
    <FormControl {...props}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <div className="flex flex-row gap-2.5">
        <Input
          type="text"
          id={id}
          bg="white"
          rounded={'lg'}
          isDisabled={isDisabled}
          className="flex-1 file:hidden leading-38px invalid:bg-red-50"
          placeholder={placeholder}
          _invalid={{
            bg: 'red.50',
            border: '1px solid',
            borderColor: 'red.500',
          }}
          name={name}
          value={value ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          ref={ref}
        />
      </div>
      {helperText && <FormHelperText mt={1}>{helperText}</FormHelperText>}
      <FormErrorMessage mt={1}>
        <TDS.Typo color="error.500" size="xs" weight="medium">
          {errorMessage}
        </TDS.Typo>
      </FormErrorMessage>
    </FormControl>
  );
});
