import type { Failed, Result, Success } from '@/types/result';

export const isNil = (value: unknown): value is undefined | null =>
  Object.is(value, undefined) || Object.is(value, null);

export function isNotNil<T>(value: T | null | undefined): value is T {
  return !isNil(value);
}
export const isEmptyString = (value: unknown): value is string =>
  Object.is(value, '');

export const isFailed = <T, E>(value: Result<T, E>): value is Failed<E> =>
  value.isFailed;

export const isSuccess = <T, E>(value: Result<T, E>): value is Success<T> =>
  !value.isFailed;

export const isString = (value: unknown): value is string =>
  typeof value === 'string';

export const isTrue = (value: unknown): value is true => Object.is(value, true);
export const isFalse = (value: unknown): value is false =>
  Object.is(value, false);
