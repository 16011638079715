export const ENV_MODE = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
} as const;

export type EnvMode = (typeof ENV_MODE)[keyof typeof ENV_MODE];

export const isLocalMode = () => import.meta.env.DEV;
export const isPreviewMode = () => import.meta.env.PROD;
export const isDevelopmentMode = () =>
  import.meta.env.MODE === ENV_MODE.DEVELOPMENT;
export const isStagingMode = () => import.meta.env.MODE === ENV_MODE.STAGING;
export const isProductionMode = () =>
  import.meta.env.MODE === ENV_MODE.PRODUCTION;

export const getEnvMode = (): EnvMode => {
  const mode = import.meta.env.MODE as EnvMode;
  switch (mode) {
    case ENV_MODE.DEVELOPMENT: {
      return ENV_MODE.DEVELOPMENT;
    }
    case ENV_MODE.STAGING: {
      return ENV_MODE.STAGING;
    }
    case ENV_MODE.PRODUCTION: {
      return ENV_MODE.PRODUCTION;
    }
    default: {
      throw new Error(`Unexpected env mode: ${mode}`);
    }
  }
};
