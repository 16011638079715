import { Text as _Typo, type TextProps as _TypoProps } from '@chakra-ui/react';

import { TOKEN } from '../../../../../theme';

export type FontSize = keyof (typeof TOKEN)['fontSize'];
export type FontWeight = keyof (typeof TOKEN)['fontWeight'];

export type TypoProps = {
  size?: FontSize;
  weight?: FontWeight;
} & Omit<_TypoProps, 'size' | 'fontSize' | 'fontWeight' | 'lineHeight'>;

export const Typo = ({
  as = 'span',
  size = 'md',
  weight = 'normal',
  children,
  ...props
}: TypoProps) => {
  return (
    <_Typo
      as={as}
      fontSize={size}
      fontWeight={weight}
      lineHeight={1.5}
      {...props}
    >
      {children}
    </_Typo>
  );
};
