import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '@/hooks/use-auth';
import { useRole } from '@/hooks/use-role';
import { isNil } from '@/utils/guard';

export type UnauthenticationGuardProps = {
  children?: React.ReactElement;
};

export const UnauthenticationGuard: React.FC<UnauthenticationGuardProps> = ({
  ...props
}) => {
  const location = useLocation();
  const { isSigned, isAuthorized } = useAuth();
  const { isNormalProvider } = useRole();

  if (!isSigned) {
    return <></>;
  }

  if (isAuthorized) {
    if (isNil(location.state)) {
      const home = isNormalProvider ? '/partners/settle' : '/partners';
      return <Navigate to={home} replace />;
    } else {
      return <Navigate to={location.state.from.pathname} replace />;
    }
  }

  return props.children ?? <Outlet />;
};
