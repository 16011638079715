import { Flex } from '@chakra-ui/react';

import { FormProvider, UseFormReturn } from 'react-hook-form';

import { z } from '@/utils/validator';

import { settleSchema } from '../schema';
import { SettlementAddressField } from './settlement-address-field';
import { SettlementEmailField } from './settlement-email-field';
import { SettlementIsKoreanResidentField } from './settlement-is-korean-resident-field';
import { SettlementIsUserResponsibleField } from './settlement-is-user-responsible-field';
import { SettlementNameField } from './settlement-name-field';
import { SettlementPhoneNumberField } from './settlement-phone-number-field';
import { SettlementResidenceCardField } from './settlement-residence-card-field';
import { SettlementSSNField } from './settlement-ssn-field';

const schema = settleSchema().taxFormSchema;
type SettlementTaxFilingFormProps = {
  form: UseFormReturn<z.infer<typeof schema>>;
};

export function SettlementTaxFilingForm({
  form,
}: SettlementTaxFilingFormProps) {
  return (
    <Flex flexDirection="column" rowGap={3}>
      <FormProvider {...form}>
        <SettlementNameField />
        <SettlementSSNField />
        <SettlementAddressField />
        <SettlementIsKoreanResidentField />
        <SettlementPhoneNumberField />
        <SettlementEmailField />
        <SettlementResidenceCardField />
        <SettlementIsUserResponsibleField />
      </FormProvider>
    </Flex>
  );
}
