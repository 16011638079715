import {
  ChangeEventHandler,
  ComponentProps,
  FocusEventHandler,
  ReactNode,
} from 'react';

import { TDS } from '@/components/ui';
import { cn } from '@/utils';

type RadioButtonProps = Omit<
  ComponentProps<'label'>,
  'children' | 'onChange' | 'onBlur'
> & {
  id?: string;
  label?: ReactNode;
  value?: string;
  name?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (value?: string) => void;
  onBlur?: (value?: string) => void;
};

// TODO: focus-visible 관련 unocss 설정으로 분리 필요
export const RadioButton = forwardRef<HTMLLabelElement, RadioButtonProps>(
  function (
    {
      className,
      id,
      value,
      name,
      defaultChecked,
      disabled,
      label,
      onChange,
      onBlur,
      ...props
    },
    ref,
  ) {
    const handleChange: ChangeEventHandler<HTMLInputElement> = () => {
      onChange && onChange(value);
    };
    const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
      onBlur && onBlur(value);
    };

    return (
      <label
        className={cn(
          'bg-white border border-grey-200 cursor-pointer color-grey-800 hover:bg-grey-50 active:bg-grey-100',
          'h-10 rounded-2.5 px-4 inline-flex flex-row items-center justify-center flex-1',
          'has-[:checked]:border-primary has-[:checked]:color-primary has-[:checked]:hover:bg-primary-50 has-[:checked]:active:bg-primary-100',
          'has-[:focus-visible]:transition-box-shadow has-[:focus-visible]:duration-200 has-[:focus-visible]:shadow-[0_0_0_3px_#fe7e4399]',
          'has-[:disabled]:opacity-40 has-[:disabled]:hover:bg-white has-[:disabled]:active:bg-white has-[:disabled]:cursor-not-allowed',
          className,
        )}
        ref={ref}
        htmlFor={id}
        {...props}
      >
        <input
          className="appearance-none"
          type="radio"
          value={value}
          name={name}
          id={id}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TDS.Typo size="md" weight="semibold">
          {label}
        </TDS.Typo>
      </label>
    );
  },
);
