import { Link } from 'react-router-dom';

import { TDS } from '@/components/ui';

export default function NotFoundPage() {
  return (
    <>
      <h1 className="text-4xl font-bold">Page not found</h1>
      <Link to="/" className="inline-flex mt-20" replace>
        <TDS.Button type="button">Home page</TDS.Button>
      </Link>
    </>
  );
}
