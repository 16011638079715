import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormLabel } from '@/components/ui';
import { RadioButton } from '@/components/ui/radio-button';
import { z } from '@/utils/validator';

import { GENDER_OPTIONS, formSchema, genderSchema } from '../schema';

type OriginalVoiceGenderFieldProps = {
  disabled?: boolean;
};

export function OriginalVoiceGenderField({
  disabled,
  ...props
}: OriginalVoiceGenderFieldProps) {
  const { t } = useTranslation('characterFormPage');
  const { control, setError, setValue, clearErrors } =
    useFormContext<z.infer<typeof formSchema>>();
  const voiceManId = `${useId()}-man-field`;
  const voiceWomanId = `${useId()}-woman-field`;
  const {
    field: { name, ref, value },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'gender',
    rules: { required: true },
    disabled,
  });

  const handleChange = (value?: string) => {
    setValue('gender', value as z.infer<typeof genderSchema>, {
      shouldDirty: true,
    });
    const result = genderSchema.safeParse(value);
    if (!result.success) {
      const error = JSON.parse(result.error.message);
      setError('gender', { message: error[0].message });
    } else {
      clearErrors('gender');
    }
  };
  return (
    <FormControl isInvalid={invalid} {...props}>
      <FormLabel>{t('components.original-voice-gender-field.label')}</FormLabel>
      <div className="flex flex-row gap-4">
        <RadioButton
          id={voiceManId}
          defaultChecked={value === GENDER_OPTIONS[0]}
          disabled={disabled}
          name={name}
          value={GENDER_OPTIONS[0]}
          label={t('components.original-voice-gender-field.gender.man')}
          ref={ref}
          onChange={handleChange}
        />
        <RadioButton
          id={voiceWomanId}
          defaultChecked={value === GENDER_OPTIONS[1]}
          disabled={disabled}
          name={name}
          value={GENDER_OPTIONS[1]}
          label={t('components.original-voice-gender-field.gender.woman')}
          ref={ref}
          onChange={handleChange}
        />
      </div>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
