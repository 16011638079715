import { useShallow } from 'zustand/react/shallow';

import { useLoadingStore } from '@/stores/loading';

/**
 * TODO: global loading -> screen loading으로 명칭 변경할 것
 */
export type UseStartGlobalLoading = ReturnType<typeof useStartGlobalLoading>;

export const useStartGlobalLoading = () => {
  const { showLoading, hideLoading } = useLoadingStore(
    useShallow(state => state),
  );

  return useCallback(
    async (cb: () => Promise<void>) => {
      try {
        showLoading();
        await cb();
      } finally {
        hideLoading();
      }
    },
    [showLoading, hideLoading],
  );
};
