import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import LogoUrl from '@/assets/images/voicehub-logo.svg';
import { cn } from '@/utils/cn';
import { scrollTo } from '@/utils/scroll';

export type LogoProps = ComponentProps<'a'>;

export const Logo = ({ className, ...props }: LogoProps) => {
  const scrollToTop = () => {
    scrollTo(document.getElementsByTagName('main')[0], 0);
  };

  return (
    <Link
      to="/partners"
      onClick={scrollToTop}
      className={cn('rounded-lg flex flex-row justify-center', className)}
      {...props}
    >
      <img src={LogoUrl} className={'cursor-pointer'} alt="voicehub logo" />
    </Link>
  );
};
