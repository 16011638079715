import { useShallow } from 'zustand/react/shallow';

import { ROLE } from '@/constants/role';
import { useUserStore } from '@/stores/user';
import { isFalse } from '@/utils/guard';

export type UseRole = ReturnType<typeof useRole>;

export const useRole = () => {
  const { roles } = useUserStore(useShallow(state => ({ roles: state.roles })));

  // isVoiceHubProvider: 보이스허브를 이용가능한 유저 (캐릭터 출시 가능)
  const isVoiceHubProvider = roles.includes(ROLE.HUB_PROVIDER);
  // isOldProvider: 성우, original character 조회 및 settle 이용 가능
  const isOldProvider = roles.includes(ROLE.ORIGINAL_CHARACTER);
  // isNormalProvider: 그 외의 유저 (일반 유저)
  const isNormalProvider =
    isFalse(isVoiceHubProvider) && isFalse(isOldProvider);

  return {
    isVoiceHubProvider,
    isOldProvider,
    isNormalProvider,
  };
};
