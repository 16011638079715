import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type SettleFormData = {
  accountOwner: string;
  bank: string;
  accountNumber: {
    number?: string;
    confirmNumber?: string;
  };
  bankBook: File[];
  name?: string;
  ssn: {
    front: string;
    back: string;
  };
  address: string;
  detailAddress: string;
  isKoreanResident: boolean;
  phoneNumber: string;
  email: string;
  isUserResponsible?: true;
  residenceCard: File[];
};

export type SettleSlice = {
  formData: SettleFormData;
  setSettle: (formData: Partial<SettleFormData>) => void;
  isFormComplete: boolean;
  setIsFormComplete: (isComplete: boolean) => void;
};

export const useSettleStore = create<SettleSlice>()(
  devtools(
    (set, get) => ({
      formData: {
        accountOwner: '',
        bank: '',
        accountNumber: {
          number: undefined,
          confirmNumber: undefined,
        },
        bankBook: [],
        name: undefined,
        ssn: {
          front: '',
          back: '',
        },
        address: '',
        detailAddress: '',
        isKoreanResident: false,
        phoneNumber: '',
        email: '',
        isUserResponsible: undefined,
        residenceCard: [],
      },
      setSettle: (formData: Partial<SettleFormData>) => {
        set({ formData: { ...get().formData, ...formData } });
      },
      isFormComplete: false,
      setIsFormComplete: (isComplete: boolean) => {
        set({ isFormComplete: isComplete });
      },
    }),
    { name: 'SettleStore' },
  ),
);
