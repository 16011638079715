import { ConfigType, default as _dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';

_dayjs.extend(duration);

export const dayjs = _dayjs;
export type DayjsType = ConfigType;

function getPreviousMonthStartAndEnd({
  date,
  language,
  format,
  separator = ' ',
}: {
  date: Date | _dayjs.Dayjs;
  language: string;
  format?: {
    year?: boolean;
    month?: boolean;
    day?: boolean;
  };
  separator: string;
}) {
  const d = dayjs(date);
  const startDateOfPreviousMonth = d.subtract(1, 'month').startOf('month');
  const endDateOfPreviousMonth = d.subtract(1, 'month').endOf('month');

  function formatDateToKorean(_date: _dayjs.Dayjs) {
    const ko = language === 'ko';
    const parts: string[] = [];

    if (ko) if (format?.year ?? true) parts.push(ko ? 'YYYY년' : 'YYYY');
    if (format?.month ?? true) parts.push(ko ? 'MM월' : 'MM');
    if (format?.day ?? true) parts.push(ko ? 'DD일' : 'DD');
    if (!ko) if (format?.year ?? true) parts.push(ko ? 'YYYY년' : 'YYYY');

    return dayjs(_date).format(parts.join(separator));
  }

  return {
    startDate: formatDateToKorean(startDateOfPreviousMonth),
    endDate: formatDateToKorean(endDateOfPreviousMonth),
  };
}

export { getPreviousMonthStartAndEnd };
