import {
  GoogleAuthProvider,
  getAuth,
  signInWithCredential,
} from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';

import { requestGetIsExistUser } from '@/api/typecast';

import { PROVIDER } from '../constants';
import type { Token } from '../types';
import { OAuthSignInInterface } from './base-sign-in';

type GoogleJwtDecodeResult = {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  email: string;
  email_verified: boolean;
  at_hash: string;
  iat: number;
  exp: number;
};

export class GoogleSignIn implements OAuthSignInInterface {
  async signIn(token?: Token) {
    if (!token) {
      throw new Error('GoogleSignIn.signIn: token is required');
    }

    const credential = this.getCredential(token);
    return signInWithCredential(getAuth(), credential);
  }

  getEmail(token: string): Promise<string | undefined> {
    const decodeToken = jwtDecode<GoogleJwtDecodeResult>(token);
    return Promise.resolve(decodeToken.email);
  }

  getCredential(token: Token) {
    return GoogleAuthProvider.credential(token);
  }

  getProvider() {
    return new GoogleAuthProvider();
  }

  async checkIsExistUser({ token }: { token: Token }): Promise<boolean> {
    const res = await requestGetIsExistUser({
      provider: PROVIDER.GOOGLE,
      idToken: token,
    });

    if (res.isFailed) {
      throw res.error;
    }

    return res.data;
  }

  static async renderButton(
    element: HTMLDivElement,
    onSignIn: (token: string) => void,
  ) {
    const googleAccounts = await getGoogleAccounts();
    googleAccounts.id.initialize({
      client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      callback: async ({ credential: token }) => {
        onSignIn(token);
      },
    });

    const { width } = element.getClientRects()[0];
    googleAccounts.id.renderButton(element, {
      size: 'large',
      type: 'standard',
      theme: 'outline',
      text: 'continue_with',
      shape: 'circle',
      locale: 'en',
      width: Math.floor(width),
    });
  }
}

const GSI_TAG_ID = 'gsi';

const getGoogleAccounts = () => {
  return new Promise<typeof google.accounts>((resolve, reject) => {
    const { google } = window;
    if (google) {
      resolve(google.accounts);
    }

    const scriptTag = document.getElementById(GSI_TAG_ID)!;
    scriptTag.addEventListener('load', () => {
      const { google } = window;
      if (!google) {
        return reject();
      }

      resolve(google.accounts);
    });
    scriptTag.addEventListener('error', () =>
      reject('GSI_SCRIPT.FAILED_TO_LOADING'),
    );
  });
};
