import { ComponentProps } from 'react';

import { TDS } from '@/libs/chakra';
import { cn } from '@/utils';

export type TitleProps = ComponentProps<'h2'>;
export function Title({ className, ...props }: TitleProps) {
  return (
    <TDS.Typo
      as="h2"
      size="3xl"
      weight="medium"
      className={cn('mb-6', className)}
      {...props}
    />
  );
}
