import { cva, type VariantProps } from 'class-variance-authority';

import { ComponentPropsWithoutRef } from 'react';

import { cn } from '@/utils';

import { TDS } from '../ui';

export const Chip = forwardRef<
  HTMLButtonElement,
  Omit<ComponentPropsWithoutRef<'button'>, 'children'> &
    VariantProps<typeof chipVariants> & {
      label: string;
    }
>(function (
  { label, selected = false, size = 'md', className, ...props },
  ref,
) {
  return (
    <button
      className={cn(chipVariants({ selected, size }), className)}
      aria-pressed={selected ?? false}
      type="button"
      ref={ref}
      {...props}
    >
      <TDS.Typo size={size ?? 'md'} weight="medium">
        {label}
      </TDS.Typo>
    </button>
  );
});

const chipVariants = cva(
  [
    'inline-flex flex-row items-center justify-center overflow-hidden border rounded-full color-grey-800 bg-grey-50 hover:bg-grey-100 active:bg-grey-150',
    'disabled:cursor-not-allowed disabled:hover:bg-transparent active:bg-transparent disabled:opacity-40',
  ],
  {
    variants: {
      selected: {
        true: 'border-primary',
        false: 'border-grey-100',
      },
      size: {
        md: 'h-8 px-4',
        sm: 'h-7 px-3',
      },
    },
    defaultVariants: {
      selected: false,
      size: 'md',
    },
  },
);
