import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';
import { useSettleStore } from '@/stores/settle';

import { UploadImageFile } from './upload-image-file';

export function SettlementBankBookField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();
  const {
    formData: { bankBook },
  } = useSettleStore(useShallow(state => ({ formData: state.formData })));

  const {
    field: { onChange },
    fieldState: { invalid, error },
  } = useController({
    control,
    name: 'bankBook',
    rules: { required: true },
  });

  return (
    <FormControl isInvalid={invalid}>
      <UploadImageFile
        onChange={onChange}
        file={bankBook[0]}
        label={t('input-modal.bank-book-field.label')}
      />
      {error?.message && (
        <FormErrorMessage>
          <TDS.Typo color="error.500" size="xs" weight="medium">
            {error.message}
          </TDS.Typo>
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
