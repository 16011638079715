import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

import { FieldError, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TDS } from '@/libs/chakra';

export function SettlementSSNField() {
  const { t } = useTranslation('settlePage');
  const { control } = useFormContext();

  const ssnFrontFieldId = `${useId()}-ssn-front-field`;
  const ssnBackFieldId = `${useId()}-ssn-back-field`;

  const {
    field: { name, value, onChange, ref },
    fieldState: { invalid, error: _error },
  } = useController({
    control,
    name: 'ssn',
    rules: { required: true },
  });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel htmlFor={ssnFrontFieldId}>
        {t('input-modal.ssn-field.label')}
      </FormLabel>
      <Flex alignItems="center" gap={2}>
        <Input
          id={ssnFrontFieldId}
          bg="white"
          rounded={'lg'}
          className="flex-1 leading-38px invalid:bg-red-50"
          placeholder={t('input-modal.ssn-field.front.placeholder')}
          _invalid={{
            bg: 'red.50',
            border: '1px solid',
            borderColor: 'red.500',
          }}
          name={name}
          value={value.front ?? ''}
          onChange={e =>
            onChange({
              ...value,
              ...{ front: e.target.value },
            })
          }
          w="full"
          ref={ref}
        />
        <TDS.Typo as="p" size="md">
          -
        </TDS.Typo>
        <Input
          type="password"
          id={ssnBackFieldId}
          bg="white"
          rounded={'lg'}
          className="flex-1 leading-38px invalid:bg-red-50"
          placeholder={t('input-modal.ssn-field.back.placeholder')}
          _invalid={{
            bg: 'red.50',
            border: '1px solid',
            borderColor: 'red.500',
          }}
          name={name}
          value={value.back ?? ''}
          onChange={e =>
            onChange({
              ...value,
              ...{ back: e.target.value },
            })
          }
          w="full"
          ref={ref}
        />
      </Flex>
      <FormErrorMessage>
        <TDS.Typo color="error.500" size="xs" weight="medium">
          {(() => {
            const error = _error as unknown as
              | {
                  front: FieldError;
                  back: FieldError;
                }
              | undefined;

            if (error?.front) {
              return <>{error?.front.message}</>;
            }

            if (error?.back) {
              return <>{error?.back.message}</>;
            }

            return <>{_error?.message}</>;
          })()}
        </TDS.Typo>
      </FormErrorMessage>
    </FormControl>
  );
}
